import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import transform from 'lodash/transform';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import zipObject from 'lodash/zipObject';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import memoize from 'memoizee';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { Modal, Paper, Tabs, Tab, CircularProgress } from '@material-ui/core';
import dayJS from 'dayjs';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { withEntryComponent } from '../../Core/hocs/withEntryComponent/withEntryComponent';
import { SymptomsTrackingGraph } from './SymptomsTrackingGraph/SymptomsTrackingGraph';
import { OverallSymptomsTrackingGraph } from './OverallSymptomsTrackingGraph/OverallSymptomsTrackingGraph';
import { withTranslation } from '../../Core/hocs/withTranslation/withTranslation';
import { AddableContent } from './AddableContent/AddableContent';
import { ZoneGraph } from './ZoneGraph/ZoneGraph';
import {
  FNS_DATE_FORMATS,
  maxPercentageValue,
  minPercentageValue,
  DAY_JS_DATE_FORMATS,
} from '../../utils/constants';
import { AmplitudeAndNoiseGraph } from './AmplitudeAndNoiseGraph/AmplitudeAndNoiseGraph';
import { InDepthSessionAnalysisGraph } from './InDepthSessionAnalysisGraph/InDepthSessionAnalysisGraph';
import styles from './SessionToSessionReportGenerator.scss';
import { AverageAndMaxStreakGraph } from './AverageAndMaxStreakGraph/AverageAndMaxStreakGraph';
import { SessionFilters } from './SessionFilters/SessionFilters';
import {
  MMSStoSeconds,
  reduceToAverageOverKeys,
  isCharADigit,
  formatSecondsToMMSS,
  floatSecondsToFloatMinutes,
  capitalizeFirstLetter,
  downloadURIContent,
  setFixedDecimalsIfNeeded,
  reduceToMedianOverKeys,
} from '../../utils/utils';
import { UserCard } from './UserCard/UserCard';
import { SessionsTable } from './SessionsTable/SessionsTable';
import {
  defaultMinDuration,
  defaultMaxDuration,
  defaultMaxAlertsNumber,
  defaultMinAlertsNumber,
  userCardReportCardID,
  protocolsUsedInTrainingReportCardID,
  userAddedReportCardsSectionTitleId,
  protocolsUsedInTrainingSectionTitleId,
  progressReportTitleId,
  sessionComparisonGraphType,
  symptomsTrackingGraphType,
  overallSymptomsTrackingGraphType,
  zoneGraphType,
  frequenciesSessionComparisonGraphType,
  streakSessionComparisonGraphType,
  amplitudeAndNoiseGraphType,
  inDepthSessionAnalysisGraphType,
  averageAndMaxStreakGraphType,
  frequenciesColors,
  averageNoiseColor,
  shadesOfGreyColors,
  assessmentPerformanceGraphType,
  assessmentBrainMapsGraphType,
  assessmentNoiseGraphType,
  assessmentAmplitudePerFrequencyGraphType,
  sessionToSessionReportGeneratorTabs,
  assessmentSwingleChecksGraphType,
  assessmentCPTPerformanceGraphType,
  freeTextCardType,
  textEditorCardType,
  assessmentQuestionnairesResultGraphType,
  insightGraphType,
  frequenciesSessionComparisonAggregationMethods,
  rapidSessionTypes,
} from './constants';
import { ClientReport } from './ClientReport/ClientReport';
import { AddToReportModal } from './AddToReportModal/AddToReportModal';
import { PreviewReportModalContent } from './PreviewReportModalContent/PreviewReportModalContent';
import { mapReportCardsDataToReportCards } from './mapReportCardsDataToReportCards';
import { PercentageSessionComparisonGraph } from './SessionComparisonGraph/PercentageSessionComparisonGraph';
import { UVSessionComparisonGraph } from './SessionComparisonGraph/UVSessionComparisonGraph';
import { SecondsSessionComparisonGraph } from './SessionComparisonGraph/SecondsSessionComparisonGraph';
import { DiscardReportDialog } from './DiscardReportDialog/DiscardReportDialog';
import { ShareReportDialog } from './ShareReportDialog/ShareReportDialog';
import { GeneratedReportDialog } from './GeneratedReportDialog/GeneratedReportDialog';
import { xsMediaMaxWidth } from '../../cssInJs/constants';
import { FeatureNotAllowedDialog } from '../FeatureNotAllowedDialog/FeatureNotAllowedDialog';
import { generateColors } from './utils';
import {
  getSwingleCategoriesScores,
  getSwingleCatagoriesFromChecks,
} from '../../models/swingle-checks/swingle-checks';
import { AssessmentPerformanceComparisonGraph } from './AssessmentPerformanceComparisonGraph/AssessmentPerformanceComparisonGraph';
import {
  getFrequencyAssessmentBrainMaps,
  getFrequencyAmplitudesAssessment,
  amplitudesPerFrequencyValueTypes,
  channelsRelevantToAmplitudesDistrubutionGraph,
  channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment,
  CPTCategoriesFormattedNames,
  sortedFrequencies,
  amplitudesStates,
  amplitudesStatesFormattedNames,
  // frequenciesToImagesFullNames,
  getFrequenciesToImagesFullNames,
} from '../../models/assessments/assessments';
import { AssessmentBrainMapsComparisonGraph } from './AssessmentBrainMapsComparisonGraph/AssessmentBrainMapsComparisonGraph';
import { AssessmentNoiseComparisonGraph } from './AssessmentNoiseComparisonGraph/AssessmentNoiseComparisonGraph';
import { AssessmentAmplitudePerFrequencyComparisonGraph } from './AssessmentAmplitudePerFrequencyComparisonGraph/AssessmentAmplitudePerFrequencyComparisonGraph';
import { AssessmentSwingleChecks } from './AssessmentSwingleChecks/AssessmentSwingleChecks';
import { AssessmentCPTPerformanceComparisonGraph } from './AssessmentCPTPerformanceComparisonGraph/AssessmentCPTPerformanceComparisonGraph';
import {
  CHANNEL_IDS,
  CHANNEL_IDS_TO_NAMES,
} from '../../models/channels/channels';
import {
  aggregateSymptomsTrackingAnswersDataSortedByTimestamp,
  turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems,
  getQuestionsFromSymptomTrackingAnswers,
  getQuestionnairesFromQuestions,
  getQuestionnaireIdFromQuestion,
  getQuestionnairesScorePercentage,
} from '../../models/symptom-tracker/symptom-tracker';
import { FreeTextCard } from './FreeTextCard/FreeTextCard';
import { TextEditorCard } from '../TextEditorCard/TextEditorCard';
import { VerticalMoreMenu } from './VerticalMoreMenu/VerticalMoreMenu';
import { NoDataIndicator } from '../NoDataIndicator/NoDataIndicator';
import { AssessmentQuestionnaireResults } from './AssessmentQuestionnaireResults/AssessmentQuestionnaireResults';
import { getDateNameSuffix } from '../../models/customer/customer';
import { ConfirmGenerateReportDialog } from './ConfirmGenerateReportDialog/ConfirmGenerateReportDialog';
import { getInsightItemDetails } from '../../models/insights/insights';
import { InsightsBarsGraph } from '../InsightsGraphs/InsightsBarsGraph/InsightsBarsGraph';

/* eslint-disable react/sort-comp */

const defaultComparisonSlice = 10;
const sessionToSessionComparisonSlicesOptions = [
  { text: '', value: defaultComparisonSlice },
  { text: '', value: 15 },
  { text: '', value: 25 },
  { text: '', value: 50 },
].map(option => ({
  ...option,
  text: `First ${option.value}% vs last ${option.value}%`,
}));

const sessionComparisonParametersNames = {
  zonePercentage: 'Zone',
  deepZonePercentage: 'Deep Zone',
  averageNoise: 'Noise',
};

const streakSessionComparisonParametersNames = {
  maxStreak: 'Max streak',
  medianStreak: 'Average Streak',
};

const frequencyAmplitudesAssessmentValueTypesOptions = [
  {
    text: 'Relative Values',
    value: amplitudesPerFrequencyValueTypes.relative,
  },
  {
    text: 'Absolute Values',
    value: amplitudesPerFrequencyValueTypes.absolute,
  },
];

const minimumNumberOfPointsForPerformanceGraph = 3;

const getNumberOfSessionsInComparison = ({
  sessions,
  sessionComparisonSlice,
}) => Math.floor((sessionComparisonSlice / 100) * sessions.length) || 1;

const getDataAndNumberOfSessionsInComparison = ({
  mapSessionComparisonSlicesToData,
  sessionComparisonSlice,
  sessions,
}) => {
  const numberOfSessionsInComparison = getNumberOfSessionsInComparison({
    sessions,
    sessionComparisonSlice,
  });

  const sessionComparisonSessionsSlices = [
    sessions.slice(0, numberOfSessionsInComparison),
    sessions.slice(
      sessions.length - numberOfSessionsInComparison,
      sessions.length
    ),
  ];

  const data = mapSessionComparisonSlicesToData(
    sessionComparisonSessionsSlices
  );

  return {
    data,
    numberOfSessionsInComparison,
  };
};

const getAddToReportModalDefaultState = () => ({
  isModalOpen: false,
  addableContentHeader: null,
  addableContentSubHeader: null,
  addableContentType: null,
  renderAddableContent: () => null,
});

const mediaQueryListExtraSmall = window.matchMedia(
  `(max-width: ${xsMediaMaxWidth}px)`
);

const reportGeneratorEventLogger = new MyEventWrapper(
  'session_to_session_report_generator'
);

const minimumNumberOfSessionsForComparison = 2;

class SessionToSessionReportGeneratorBase extends Component {
  constructor(props) {
    super(props);

    const reportCardsHolderState = {
      reportCardsHolderMarginTop: 0,
      isPreviewReportModalOpen: false,
      reportCardsHolderHeight: 'initial',
      reportCardsData: [],
    };

    const addToReportModalState = getAddToReportModalDefaultState();

    const graphState = {
      filteredSessionsAnalysis: [],
      amplitudeAndNoiseGraphLegendData: {},
      areAmplitudeAndNoiseGraphTrendLinesEnabled: false,
      sessionComparisonSlice: defaultComparisonSlice,
      sessionComparisonParameters: mapValues(
        sessionComparisonParametersNames,
        () => true
      ),
      frequenciesSessionComparisonSlice: defaultComparisonSlice,
      frequenciesSessionComparisonParameters: {},
      frequenciesSessionComparisonAggregationMethod:
        frequenciesSessionComparisonAggregationMethods.median,
      streakSessionComparisonSlice: defaultComparisonSlice,
      streakSessionComparisonParameters: mapValues(
        streakSessionComparisonParametersNames,
        () => true
      ),
      zoneGraphLegendItems: {
        zone: true,
        deepZone: true,
        trend: true,
      },
      averageAndMaxStreakGraphLegendItems: {
        averageStreak: true,
        maxStreak: true,
      },
      addToReportModalState,
    };

    const dateFilterState = {
      filterFocusedDateInput: 'startDate',
      filterStartDate: null,
      filterEndDate: null,
      filterSavedStartDate: null,
      filterSavedEndDate: null,
      filterDefaultStartDate: null,
      filterDefaultEndDate: null,
    };

    const timeFilterState = {
      filterFocusedTimeInput: 'startTime',
      filterStartTime: null,
      filterEndTime: null,
      filterSavedStartTime: null,
      filterSavedEndTime: null,
      filterDefaultStartTime: null,
      filterDefaultEndTime: null,
    };

    const protocolsFilterState = {
      allFilterProtocols: [],
      selectedFilterProtocols: [],
      savedSelectedFilterProtocols: [],
      shouldProtocolsToggleSelectAll: false,
    };

    const programsFilterState = {
      allFilterPrograms: [],
      selectedFilterPrograms: [],
      savedSelectedFilterPrograms: [],
      shouldProgramsToggleSelectAll: false,
    };

    const sessionTypesFilterState = {
      allFilterSessionTypes: [],
      selectedFilterSessionTypes: [],
      savedSelectedFilterSessionTypes: [],
      shouldSessionTypesToggleSelectAll: false,
    };

    const trainingSiteFilterState = {
      allFilterTrainingSite: [],
      selectedFilterTrainingSite: [],
      savedSelectedFilterTrainingSite: [],
      shouldTrainingSiteToggleSelectAll: false,
    };

    const durationFilterState = {
      minDuration: defaultMinDuration,
      maxDuration: defaultMaxDuration,
      minDurationValue: defaultMinDuration,
      maxDurationValue: defaultMaxDuration,
      minDurationSavedValue: defaultMinDuration,
      maxDurationSavedValue: defaultMaxDuration,
    };

    const positiveFeedbackFilterState = {
      minPositiveFeedback: minPercentageValue,
      maxPositiveFeedback: maxPercentageValue,
      minPositiveFeedbackValue: minPercentageValue,
      maxPositiveFeedbackValue: maxPercentageValue,
      minPositiveFeedbackSavedValue: minPercentageValue,
      maxPositiveFeedbackSavedValue: maxPercentageValue,
    };

    const alertsNumberFilterState = {
      minAlertsNumber: defaultMinAlertsNumber,
      maxAlertsNumber: defaultMaxAlertsNumber,
      minAlertsNumberValue: defaultMinAlertsNumber,
      maxAlertsNumberValue: defaultMaxAlertsNumber,
      minAlertsNumberSavedValue: defaultMinAlertsNumber,
      maxAlertsNumberSavedValue: defaultMaxAlertsNumber,
    };

    const noiseFilterState = {
      minNoise: minPercentageValue,
      maxNoise: maxPercentageValue,
      minNoiseValue: minPercentageValue,
      maxNoiseValue: maxPercentageValue,
      minNoiseSavedValue: minPercentageValue,
      maxNoiseSavedValue: maxPercentageValue,
    };

    const filtersState = {
      ...dateFilterState,
      ...timeFilterState,
      ...protocolsFilterState,
      ...programsFilterState,
      ...sessionTypesFilterState,
      ...trainingSiteFilterState,
      ...durationFilterState,
      ...positiveFeedbackFilterState,
      ...alertsNumberFilterState,
      ...noiseFilterState,
    };

    const shareReportDialogState = {
      isShareReportDialogOpen: false,
      changeablePatientEmail: props.patientEmail,
    };

    const assessmentGraphsState = {
      assessmentResults: {},
      performanceGraphSelectedAssessmentIds: [],
      brainMapsGraphSelectedAssessmentIds: [],
      amplitudePerFrequencyGraphSelectedAssessmentIds: [],
      frequencyAssessmentBrainMaps: {},
      frequencyAssessmentBrainMapsSelectedFrequencies: {},
      frequencyAmplitudesAssessment: {},
      frequencyAmplitudesAssessmentLegendItems: {},
      frequencyAmplitudesAssessmentValuesType:
        amplitudesPerFrequencyValueTypes.relative,
      amplitudePerFrequencyGraphSelectedChannels: {},
      swingleChecksGraphSelectedAssessmentIds: [],
      swingleChecksGraphCatagories: {},
      CPTPerformanceGraphSelectedAssessmentIds: [],
      questionnaireResultsSelectedAssessmentIds: [],
      questionnaireResultsSelectedQuestionnaires: [],
      allSymptomTrackingQuestionnairesWithoutSubScales: {},
      symptomsTrackingLegendItems: [],
      symptomsTrackingGraphData: [],
      selectedSymptomsTrackingQuestionnaires: [],
      allSymptomTrackingQuestionnaires: {},
    };

    const insightsGraphsState = {
      selectedInsightItemId: null,
    };

    const overallSymptomTrackerGraphState = {
      filteredOverallQuestionnaires: [],
      allOverallQuestionnaires: [],
      overallSymptomTrackerGraphData: [],
    };

    const inDepthSessionAnalysisGraphState = {
      selectedInDepthSession: null,
      isLoadingCurrentSesion: false,
      currentSessionData: null,
      toggleFrequencyAmplitudes: true,
      toggleThresholds: true,
    };

    const overallSymptomTrackerGraphDataAsCSVState = {
      onDownloadSymptomsTrackingDataAsCSVDataFetched: false,
      onDownloadSymptomsTrackingDataAsCSV: null,
      onDownloadOverallSymptomsTrackingDataAsCSVDataFetched: false,
      onDownloadOverallSymptomsTrackingDataAsCSV: null,
    };

    this.state = {
      ...graphState,
      ...filtersState,
      ...reportCardsHolderState,
      ...shareReportDialogState,
      ...assessmentGraphsState,
      ...insightsGraphsState,
      ...overallSymptomTrackerGraphState,
      ...inDepthSessionAnalysisGraphState,
      ...overallSymptomTrackerGraphDataAsCSVState,
      freeTextCardContent: '',
      textEditorCardContent: EditorState.createEmpty(),
      reportLink: null,
      isDiscardReportDialogOpen: false,
      isGeneratedReportDialogOpen: false,
      isConfirmGenerateReportDialogOpen: false,
      isFeatureNotAllowedDialogOpen: false,
      isComponentReadyToRender: false,
      selectedGraphsTab: sessionToSessionReportGeneratorTabs.sessionGraphs,
    };
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('beforeunload', this.onWindowBeforeUnLoad);

    const {
      customerIdAsProp,
      getOnDownloadSymptomsTrackingDataAsCSV,
      getOnDownloadOverallSymptomsTrackingDataAsCSV,
    } = this.props;

    const onDownloadSymptomsTrackingDataAsCSV = await getOnDownloadSymptomsTrackingDataAsCSV(
      { customerIdAsProp }
    );

    const onDownloadOverallSymptomsTrackingDataAsCSV = await getOnDownloadOverallSymptomsTrackingDataAsCSV(
      { customerIdAsProp }
    );

    this.setState({
      onDownloadSymptomsTrackingDataAsCSVDataFetched: true,
      onDownloadSymptomsTrackingDataAsCSV,
      onDownloadOverallSymptomsTrackingDataAsCSVDataFetched: true,
      onDownloadOverallSymptomsTrackingDataAsCSV,
    });
  }

  async componentDidUpdate(prvProps) {
    if (!prvProps.isDataLoaded && this.props.isDataLoaded) {
      await this.setSymptomTrackerGraphsInitialState();
      this.setSessionsGraphsInitialState();
      await this.setAssessmentGraphsInitialState();
      this.setOverallSymptomGraphInitalState();
      await this.setInDepthSessionAnalysisGraphInitalState();

      // eslint-disable-next-line no-undef
      hideLoading();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isComponentReadyToRender: true });
      reportGeneratorEventLogger.log('page_loaded', {
        loadingTime: Date.now() - this.props.startedLoadingTime,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
    window.removeEventListener('beforeunload', this.onWindowBeforeUnLoad);
  }

  setOverallSymptomGraphInitalState() {
    const {
      symptomsTrackingAnswers,
      allQuestionnaires,
      assessmentResultsWithDeletedAssessments,
    } = this.props;
    const questionnairesToDisplay = [];
    const symptomsTrackingAnswersFiltered = JSON.parse(
      JSON.stringify(symptomsTrackingAnswers)
    );
    Object.keys(symptomsTrackingAnswers).forEach(answerId => {
      if (
        assessmentResultsWithDeletedAssessments[
          symptomsTrackingAnswers[answerId].assessmentId
        ] &&
        assessmentResultsWithDeletedAssessments[
          symptomsTrackingAnswers[answerId].assessmentId
        ].isDeleted
      ) {
        delete symptomsTrackingAnswersFiltered[answerId];
      }
    });
    const graphDataArr = Object.keys(symptomsTrackingAnswersFiltered).map(
      answerId => {
        if (symptomsTrackingAnswersFiltered[answerId].totalScores) {
          Object.keys(
            symptomsTrackingAnswersFiltered[answerId].totalScores
          ).forEach(questionnireId => {
            questionnairesToDisplay.push(questionnireId);
          });
          return {
            timestamp: +symptomsTrackingAnswersFiltered[answerId].timestamp,
            ...symptomsTrackingAnswersFiltered[answerId].totalScores,
          };
        }
        return {};
      }
    );
    // setGraphData(graphDataArr);
    this.setState({ overallSymptomTrackerGraphData: graphDataArr });
    const questionnairesToDisplaySet = [
      ...new Set(questionnairesToDisplay),
    ].filter(questionnaireId => !allQuestionnaires[questionnaireId].hideResult);
    // setFilteredQUestionnaries(questionnairesToDisplaySet);
    this.setState({
      filteredOverallQuestionnaires: questionnairesToDisplaySet,
    });
    const allQuestionnariesFilteredData = {};
    questionnairesToDisplaySet.forEach(questionnaireId => {
      allQuestionnariesFilteredData[questionnaireId] =
        allQuestionnaires[questionnaireId];
      allQuestionnariesFilteredData[questionnaireId].id = questionnaireId;
    });
    // setAllQUestionnaries(allQuestionnariesFilteredData);
    this.setState({ allOverallQuestionnaires: allQuestionnariesFilteredData });
  }

  async setInDepthSessionAnalysisGraphInitalState() {
    const firstSessionKey =
      this.props.sessionsAnalysis &&
      this.props.sessionsAnalysis[0] &&
      this.props.sessionsAnalysis[0].sessionKey;
    if (firstSessionKey) {
      this.setState({
        selectedInDepthSession: firstSessionKey,
        isLoadingCurrentSesion: true,
      });
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('sessionInfo', {
          sessionId: firstSessionKey,
        });
        this.setState({
          currentSessionData: data,
          isLoadingCurrentSesion: false,
        });
      } catch (e) {
        this.setState({
          currentSessionData: null,
          isLoadingCurrentSesion: false,
        });
      }
    }
    this.setState({
      toggleFrequencyAmplitudes: true,
      toggleThresholds: true,
    });
  }

  setSessionsGraphsInitialState() {
    const { sessionsAnalysis } = this.props;

    const filteredSessionsAnalysis = sessionsAnalysis
      .map(session => ({
        ...session,
        selected: true,
      }))
      .filter(session => !session.isDeleted);

    this.setState(
      {
        filteredSessionsAnalysis,
        selectedSessions: filteredSessionsAnalysis,
      },
      () => {
        this.updateSessionsGraphsState();
        this.setSessionGraphsFiltersInitialState();
        this.setReportCardsDataInitialState();
      }
    );
  }

  setAssessmentGraphsInitialState() {
    const {
      swingleChecks,
      assessmentResultsWithDeletedAssessments,
      headsetChecks,
    } = this.props;

    const symptomTrackingAnswersFromAssessments = this.getAssessmentSymptomTrackingAnswers();
    const lastTwoAssessmentIdsForSymptomTracking = Object.values(
      symptomTrackingAnswersFromAssessments
    )
      .slice(-2)
      .map(answer => answer.assessmentId);
    this.setState({
      questionnaireResultsSelectedAssessmentIds: lastTwoAssessmentIdsForSymptomTracking,
    });

    const assessmentResults = pickBy(
      assessmentResultsWithDeletedAssessments,
      assessment => !assessment.isDeleted && assessment.isReady
    );

    const lastTwoAssessmentIds = Object.keys(assessmentResults).slice(-2);

    const cptAssessmentResults = this.getCPTAssessmentResults({
      assessmentResults,
    });
    const cptLastTwoAssessmentIds = Object.keys(cptAssessmentResults).slice(-2);

    const eegAssessmentResults = this.getEEGAssessmentResults({
      assessmentResults,
    });

    const eegLastTwoAssessmentIds = Object.keys(eegAssessmentResults).slice(-2);

    const brainMapsAssessmentResults = this.getBrainMapsAssessmentResults({
      assessmentResults,
    });

    const brainMapsLastTwoAssessmentIds = Object.keys(
      brainMapsAssessmentResults
    ).slice(-2);

    const swingleAssessmentResults = this.getSwingleAssessmentResults({
      assessmentResults,
    });
    const swingleLastTwoAssessmentIds = Object.keys(
      swingleAssessmentResults
    ).slice(-2);

    const swingleResults = mapValues(
      swingleAssessmentResults,
      assessment => assessment.swingle
    );
    const CPTResults = mapValues(
      cptAssessmentResults,
      assessment => assessment.cpt
    );

    if (lastTwoAssessmentIds.length > 1) {
      this.setState({
        assessmentResults,
      });
    }

    if (cptLastTwoAssessmentIds.length > 1) {
      this.setState({
        CPTResults,
        CPTPerformanceGraphSelectedAssessmentIds: cptLastTwoAssessmentIds,
      });
    }

    if (swingleLastTwoAssessmentIds.length > 1) {
      this.setState({
        swingleResults,
        performanceGraphSelectedAssessmentIds: swingleLastTwoAssessmentIds,
        swingleChecksGraphSelectedAssessmentIds: swingleLastTwoAssessmentIds,
      });

      const swingleChecksWithIds = mapValues(swingleChecks, (value, key) => ({
        ...value,
        id: key,
      }));

      const headsetChecksWithIds = mapValues(headsetChecks, (value, key) => ({
        ...value,
        id: key,
      }));

      const swingleCatagories = getSwingleCatagoriesFromChecks({
        checks: swingleChecksWithIds,
      });

      const swingleChecksGraphCatagories = swingleCatagories.reduce(
        (acc, cur) => ({ ...acc, [cur]: true }),
        {}
      );

      const headsetCatagories = getSwingleCatagoriesFromChecks({
        checks: headsetChecksWithIds,
      });

      const headsetChecksGraphCatagories = headsetCatagories.reduce(
        (acc, cur) => ({ ...acc, [cur]: true }),
        {}
      );

      this.setState({
        swingleChecks: swingleChecksWithIds,
        headsetChecks: headsetChecksWithIds,
        swingleChecksGraphCatagories,
        headsetChecksGraphCatagories,
      });
    }

    if (brainMapsLastTwoAssessmentIds.length > 1) {
      this.setState({
        brainMapsGraphSelectedAssessmentIds: brainMapsLastTwoAssessmentIds,
      });

      const promisedFrequencyAssessmentBrainMaps = mapValues(
        brainMapsAssessmentResults,
        (assessmentResult, assessmentId) =>
          getFrequencyAssessmentBrainMaps({
            customerId: sessionStorage.customerId,
            assessmentId,
          })
      );

      Promise.all(Object.values(promisedFrequencyAssessmentBrainMaps)).then(
        frequencyAssessmentBrainMapsArr => {
          const frequencyAssessmentBrainMaps = zipObject(
            Object.keys(brainMapsAssessmentResults),
            frequencyAssessmentBrainMapsArr
          );

          const frequencyAssessmentBrainMapsSelectedFrequencies = mapValues(
            frequencyAssessmentBrainMapsArr[0],
            () => true
          );

          // frequencyAssessmentBrainMapsSelectedFrequencies.Noise = true;
          this.setState({
            frequencyAssessmentBrainMaps,
            frequencyAssessmentBrainMapsSelectedFrequencies,
          });
        }
      );
    }

    if (eegLastTwoAssessmentIds.length > 1) {
      this.setState({
        amplitudePerFrequencyGraphSelectedAssessmentIds: eegLastTwoAssessmentIds,
      });

      const promisedFrequencyAmplitudesAssessment = mapValues(
        eegAssessmentResults,
        (assessmentResult, assessmentId) =>
          getFrequencyAmplitudesAssessment({
            customerId: sessionStorage.customerId,
            assessmentId,
          })
      );

      Promise.all(Object.values(promisedFrequencyAmplitudesAssessment)).then(
        frequencyAmplitudesAssessmentArr => {
          const frequencyAmplitudesAssessment = zipObject(
            Object.keys(eegAssessmentResults),
            frequencyAmplitudesAssessmentArr
          );
          const shouldTakeHeadsetAssessment =
            eegAssessmentResults[eegLastTwoAssessmentIds[1]].stagesType ===
            'headsetStages';
          // start with maximum possible selected channels but only relevant channels will be shown when rendering the graph
          const amplitudePerFrequencyGraphSelectedChannels = (shouldTakeHeadsetAssessment
            ? channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment
            : channelsRelevantToAmplitudesDistrubutionGraph
          ).reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

          const baseLegendItems = {
            preEyesClosed: true,
            postEyesClosed: true,
            preEyesOpened: true,
            postEyesOpened: true,
          };

          // start with maximum possible selected channels but only relevant channels will be shown when rendering the graph
          const frequencyAmplitudesAssessmentLegendItems = (shouldTakeHeadsetAssessment
            ? channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment
            : channelsRelevantToAmplitudesDistrubutionGraph
          ).reduce(
            (acc, cur) => ({
              ...acc,
              [cur]:
                cur === CHANNEL_IDS.Cz
                  ? {
                      ...baseLegendItems,
                      preUnderTask: true,
                      postUnderTask: true,
                    }
                  : { ...baseLegendItems },
            }),
            {}
          );

          getFrequenciesToImagesFullNames().then(
            frequenciesToImagesFullNames => {
              this.setState({
                frequencyAmplitudesAssessment,
                amplitudePerFrequencyGraphSelectedChannels,
                frequencyAmplitudesAssessmentLegendItems,
                frequenciesToImagesFullNames,
              });
            }
          );
        }
      );
    }
  }

  onShareReportDialogClose = () => window.open(document.referrer, '_self');

  onShareReportClick = () => this.setState({ isShareReportDialogOpen: false });

  onFiltersUpdated() {
    const { sessionsAnalysis, sessionBlocks, trainingSessions } = this.props;
    const {
      savedSelectedFilterProtocols,
      savedSelectedFilterPrograms,
      savedSelectedFilterSessionTypes,
      savedSelectedFilterTrainingSite,
      filterSavedStartDate,
      filterSavedEndDate,
      filterSavedStartTime,
      filterSavedEndTime,
      minDurationSavedValue,
      maxDurationSavedValue,
      minNoiseSavedValue,
      maxNoiseSavedValue,
      minAlertsNumberSavedValue,
      maxAlertsNumberSavedValue,
      minPositiveFeedbackSavedValue,
      maxPositiveFeedbackSavedValue,
    } = this.state;

    const savedStartDateMS =
      (filterSavedStartDate && filterSavedStartDate.valueOf()) || 0;
    const savedEndDateMS =
      (filterSavedEndDate && filterSavedEndDate.valueOf()) ||
      Number.POSITIVE_INFINITY;

    const filterSessionBy = session => {
      return (
        session.totalSuccessRate >= minPositiveFeedbackSavedValue &&
        session.totalSuccessRate <= maxPositiveFeedbackSavedValue &&
        session.alerts >= minAlertsNumberSavedValue &&
        session.alerts <= maxAlertsNumberSavedValue &&
        session.averageNoise >= minNoiseSavedValue &&
        session.averageNoise <= maxNoiseSavedValue &&
        session.duration >= minDurationSavedValue &&
        session.duration <= maxDurationSavedValue &&
        session.timestamp >= savedStartDateMS &&
        session.timestamp <= savedEndDateMS &&
        savedSelectedFilterProtocols.some(
          protocol => protocol === session.protocol
        ) &&
        savedSelectedFilterTrainingSite.some(
          trainingSite => trainingSite === session.training_channel
        ) &&
        savedSelectedFilterPrograms.some(
          program =>
            program ===
            (sessionBlocks[session.sessionKey] &&
              sessionBlocks[session.sessionKey].protocolDetailsItem &&
              sessionBlocks[session.sessionKey].protocolDetailsItem.name)
        ) &&
        // filter by session type
        trainingSessions[session.sessionKey] &&
        (!trainingSessions[session.sessionKey].isRapidSession ||
          (trainingSessions[session.sessionKey].rapidSessionType
            ? savedSelectedFilterSessionTypes.find(
                savedSelectedFilterSessionType =>
                  savedSelectedFilterSessionType ===
                  rapidSessionTypes[
                    trainingSessions[session.sessionKey].rapidSessionType
                  ].name
              )
            : savedSelectedFilterSessionTypes.find(
                savedSelectedFilterSessionType =>
                  savedSelectedFilterSessionType ===
                  rapidSessionTypes.withElectrode.name
              ))) &&
        // filter by session type end
        (!filterSavedStartTime ||
          +dayJS(session.timestamp).format('HH') >
            +filterSavedStartTime.slice(0, 2) ||
          (+dayJS(session.timestamp).format('HH') ===
            +filterSavedStartTime.slice(0, 2) &&
            +dayJS(session.timestamp).format('mm') >=
              +filterSavedStartTime.slice(-2))) &&
        (!filterSavedEndTime ||
          +dayJS(session.timestamp).format('HH') <
            +filterSavedEndTime.slice(0, 2) ||
          (+dayJS(session.timestamp).format('HH') ===
            +filterSavedEndTime.slice(0, 2) &&
            +dayJS(session.timestamp).format('mm') <=
              +filterSavedEndTime.slice(-2)))
      );
    };

    const filteredSessionsAnalysis = sessionsAnalysis
      .filter(filterSessionBy)
      .filter(session => !session.isDeleted)
      .map(session => ({ ...session, selected: true }));

    this.setState(
      { filteredSessionsAnalysis, selectedSessions: filteredSessionsAnalysis },
      this.updateSessionsGraphsState
    );
  }

  onResetFiltersClick = () => {
    const onClearFunctions = [
      this.onClearAlertsNumberClick,
      this.onClearNoiseClick,
      this.onClearPositiveFeedbackClick,
      this.onClearSelectedDateClick,
      this.onClearSelectedDurationClick,
      this.onClearSelectedProtocolsClick,
      this.onClearSelectedProgramsClick,
      this.onClearSelectedSessionTypesClick,
      this.onClearSelectedTrainingSiteClick,
      this.onClearSelectedTimeClick,
    ];

    onClearFunctions.map(fn => fn());

    reportGeneratorEventLogger.log(
      'on_reset_filters',
      this.getSessionsGraphsFilterSavedValues()
    );
  };

  onSessionsTableLinkToSessionClick = session => {
    const { trainingSessions } = this.props;

    sessionStorage.sessionId = session.sessionKey;
    sessionStorage.session = JSON.stringify(
      trainingSessions[session.sessionKey]
    );
    window.open('sessionPages.html', '_blank');
  };

  onSessionsTableHeadCheckboxClick = event => {
    const { checked } = event.target;

    reportGeneratorEventLogger.log(
      'on_head_checkbox_click_in_sessions_table',
      checked
    );

    this.setState(prvState => {
      const prvFilteredSessionsAnalysis = prvState.filteredSessionsAnalysis;
      const newFilteredSessionsAnalysis = prvFilteredSessionsAnalysis.map(
        session => {
          // eslint-disable-next-line no-param-reassign
          session.selected = checked;
          return session;
        }
      );

      return {
        filteredSessionsAnalysis: newFilteredSessionsAnalysis,
        selectedSessions: checked ? newFilteredSessionsAnalysis : [],
      };
    }, this.updateSessionsGraphsState);
  };

  onSessionsTableSessionClick = clickedSession => {
    reportGeneratorEventLogger.log(
      'on_session_click_in_sessions_table',
      clickedSession
    );

    this.setState(prvState => {
      const newFilteredSessionsAnalysis = cloneDeep(
        prvState.filteredSessionsAnalysis
      );
      const session = newFilteredSessionsAnalysis.find(
        s => s.sessionKey === clickedSession.sessionKey
      );
      session.selected = !session.selected;
      return {
        filteredSessionsAnalysis: newFilteredSessionsAnalysis,
        selectedSessions: newFilteredSessionsAnalysis.filter(s => s.selected),
      };
    }, this.updateSessionsGraphsState);
  };

  onViewReportClick = () => {
    reportGeneratorEventLogger.log('on_view_report_click');
    this.setState({ isPreviewReportModalOpen: true });
  };

  onDiscardReportClick = () => {
    reportGeneratorEventLogger.log('on_discard_report_click');
    this.setState({ isDiscardReportDialogOpen: true });
  };

  onDiscardReportConfirm = () => {
    reportGeneratorEventLogger.log('on_discard_report_confirm');

    this.setState({
      reportCardsData: this.getDefaultReportCardsData(),
      isDiscardReportDialogOpen: false,
    });
  };

  onCloseDiscardReportDialog = () => {
    reportGeneratorEventLogger.log('on_close_discard_report');

    this.setState({ isDiscardReportDialogOpen: false });
  };

  onReportCardsReordered = newReportCards => {
    reportGeneratorEventLogger.log('on_report_cards_reordered');
    const { reportCardsData } = this.state;
    const newReportCardsData = newReportCards.map(reportCard =>
      reportCardsData.find(
        reportCardData => reportCardData.id === reportCard.id
      )
    );
    this.setState({
      reportCardsData: this.getDefaultReportCardsData().concat(
        newReportCardsData
      ),
    });
  };

  onDeleteReportCardClick = deletedReportCard => {
    const { reportCardsData } = this.state;

    const deletedReportCardData = reportCardsData.find(
      reportCardData => reportCardData.id === deletedReportCard.id
    );
    reportGeneratorEventLogger.log('on_delete_report_card');
    this.setState(prvState => ({
      reportCardsData: prvState.reportCardsData.filter(
        reportCardData => reportCardData.id !== deletedReportCardData.id
      ),
    }));
  };

  onWindowScroll = () => {
    const { reportCardsHolderMarginTop } = this.state;
    const scrollOffset = 80;
    if (
      document.scrollingElement.scrollTop >= scrollOffset &&
      reportCardsHolderMarginTop === 0
    ) {
      this.setState({ reportCardsHolderMarginTop: -50 });
    }
    if (
      document.scrollingElement.scrollTop <= scrollOffset &&
      reportCardsHolderMarginTop === -50
    ) {
      this.setState({ reportCardsHolderMarginTop: 0 });
    }
  };

  onWindowBeforeUnLoad = e => {
    const { reportCardsData, reportLink } = this.state;

    if (
      reportCardsData.length > this.getDefaultReportCardsData().length &&
      reportLink === null
    ) {
      e.preventDefault();

      e.returnValue = ''; // needed by chrome
    }
  };

  onViewReport = () => {
    const { isUserAllowedToUseThisFeature } = this.props;
    if (isUserAllowedToUseThisFeature) {
      window.open(`${this.state.reportLink}&viewer=clinic`, '_self');
    } else {
      this.setState({ isFeatureNotAllowedDialogOpen: true });
    }
  };

  getAssessmentOptions = ({ assessmentIds }) =>
    assessmentIds.map(assessmentId => {
      const { assessmentResults } = this.state;
      const assessment = assessmentResults[assessmentId];

      return {
        value: assessmentId,
        text: `Assessment: ${dayJS(
          assessment ? assessment.endTimestamp : null
        ).format(DAY_JS_DATE_FORMATS.dayMonthYearSlashesWithTime)}`,
        timestamp: assessment ? assessment.endTimestamp : null,
      };
    });

  onClearSelectedDateClick = () =>
    this.setState(prvState => {
      const newState = {
        filterStartDate: prvState.filterDefaultStartDate,
        filterEndDate: prvState.filterDefaultEndDate,
        filterSavedStartDate: null,
        filterSavedEndDate: null,
      };

      reportGeneratorEventLogger.log('on_clear_selected_date', {
        filterStartDate: prvState.filterDefaultStartDate,
        filterEndDate: prvState.filterDefaultEndDate,
      });

      return newState;
    }, this.onFiltersUpdated);

  getDateFilterProps = () => {
    const {
      filterStartDate,
      filterEndDate,
      filterFocusedDateInput,
      filterDefaultEndDate,
      filterDefaultStartDate,
      filterSavedStartDate,
      filterSavedEndDate,
    } = this.state;

    const onDatesFilterChange = ({ startDate, endDate }) => {
      const newDates = {
        filterStartDate: startDate && startDate.startOf('day'),
        filterEndDate: endDate && endDate.endOf('day'),
      };

      this.setState(newDates);
      reportGeneratorEventLogger.log(
        'dates_filter_changed',
        pickBy(newDates, value => value !== null)
      );
    };

    const onDatesFilterClickOutSide = () =>
      this.setState(prvState => ({
        filterStartDate:
          prvState.filterSavedStartDate || prvState.filterDefaultStartDate,
        filterEndDate:
          prvState.filterSavedEndDate || prvState.filterDefaultEndDate,
      }));

    const onDatesFilterFocusChange = focusedInput => {
      this.setState({
        // Force the focusedInput to always be truthy so that dates are always selectable
        filterFocusedDateInput: !focusedInput ? 'startDate' : focusedInput,
      });
    };

    const onDatesFilterDoneClick = () =>
      this.setState(prvState => {
        const datesToBeSaved = {
          filterSavedStartDate: prvState.filterStartDate,
          filterSavedEndDate: prvState.filterEndDate,
        };

        reportGeneratorEventLogger.log(
          'dates_filter_done_click',
          datesToBeSaved
        );
        return datesToBeSaved;
      }, this.onFiltersUpdated);

    return {
      savedStartDate: filterSavedStartDate,
      savedEndDate: filterSavedEndDate,
      startDate:
        filterStartDate || filterEndDate
          ? filterStartDate
          : filterDefaultStartDate,
      endDate:
        filterStartDate || filterEndDate ? filterEndDate : filterDefaultEndDate,
      focusedDateInput: filterFocusedDateInput,
      onDatesChange: onDatesFilterChange,
      onDatesFocusChange: onDatesFilterFocusChange,
      onDatesDoneClick: onDatesFilterDoneClick,
      onClearSelectedDateClick: this.onClearSelectedDateClick,
      onDatesClickOutside: onDatesFilterClickOutSide,
      isDatesDoneBtnEnabled:
        filterStartDate !== null &&
        filterEndDate !== null &&
        (!filterStartDate.isSame(filterDefaultStartDate) ||
          !filterEndDate.isSame(filterDefaultEndDate)),
    };
  };

  onClearSelectedTimeClick = () =>
    this.setState(prvState => {
      const newState = {
        filterStartTime: prvState.filterDefaultStartTime,
        filterEndTime: prvState.filterDefaultEndTime,
        filterSavedStartTime: null,
        filterSavedEndTime: null,
      };

      reportGeneratorEventLogger.log('on_clear_selected_time', {
        filterStartTime: prvState.filterDefaultStartTime,
        filterEndTime: prvState.filterDefaultEndTime,
      });

      return newState;
    }, this.onFiltersUpdated);

  getTimeFilterProps = () => {
    const {
      filterStartTime,
      filterEndTime,
      filterFocusedTimeInput,
      filterDefaultEndTime,
      filterDefaultStartTime,
      filterSavedStartTime,
      filterSavedEndTime,
    } = this.state;

    // const onTimesFilterChange = ({ startTime, endTime }) => {
    //   const newTimes = {
    //     filterStartTime: startTime && startTime.startOf('day'),
    //     filterEndTime: endTime && endTime.endOf('day'),
    //   };

    //   this.setState(newTimes);
    //   reportGeneratorEventLogger.log(
    //     'times_filter_changed',
    //     pickBy(newTimes, value => value !== null)
    //   );
    // };

    const onStartTimeChange = startTime => {
      this.setState({ filterStartTime: startTime });
      reportGeneratorEventLogger.log('start_time_filter_changed', startTime);
    };

    const onEndTimeChange = endTime => {
      this.setState({ filterEndTime: endTime });
      reportGeneratorEventLogger.log('end_time_filter_changed', endTime);
    };

    const onTimesFilterClickOutSide = () =>
      this.setState(prvState => ({
        filterStartTime:
          prvState.filterSavedStartTime || prvState.filterDefaultStartTime,
        filterEndTime:
          prvState.filterSavedEndTime || prvState.filterDefaultEndTime,
      }));

    const onTimesFilterFocusChange = focusedInput => {
      this.setState({
        // Force the focusedInput to always be truthy so that dates are always selectable
        filterFocusedTimeInput: !focusedInput ? 'startTime' : focusedInput,
      });
    };

    const onTimesFilterDoneClick = () =>
      this.setState(prvState => {
        const timesToBeSaved = {
          filterSavedStartTime: prvState.filterStartTime,
          filterSavedEndTime: prvState.filterEndTime,
        };

        reportGeneratorEventLogger.log(
          'times_filter_done_click',
          timesToBeSaved
        );
        return timesToBeSaved;
      }, this.onFiltersUpdated);

    return {
      savedStartTime: filterSavedStartTime,
      savedEndTime: filterSavedEndTime,
      startTime:
        filterStartTime || filterEndTime
          ? filterStartTime
          : filterDefaultStartTime,
      endTime:
        filterStartTime || filterEndTime ? filterEndTime : filterDefaultEndTime,
      focusedTimeInput: filterFocusedTimeInput,
      // onTimesChange: onTimesFilterChange,
      // eslint-disable-next-line object-shorthand
      onStartTimeChange: onStartTimeChange,
      // eslint-disable-next-line object-shorthand
      onEndTimeChange: onEndTimeChange,
      onTimesFocusChange: onTimesFilterFocusChange,
      onTimesDoneClick: onTimesFilterDoneClick,
      onClearSelectedTimeClick: this.onClearSelectedTimeClick,
      onTimesClickOutside: onTimesFilterClickOutSide,
      isTimesDoneBtnEnabled:
        filterStartTime !== null &&
        filterEndTime !== null &&
        (+filterEndTime.slice(0, 2) > +filterStartTime.slice(0, 2) ||
          (+filterEndTime.slice(0, 2) === +filterStartTime.slice(0, 2) &&
            +filterEndTime.slice(-2) >= +filterStartTime.slice(-2))),
    };
  };

  getSelectedSessionsForZoneAndStreakGraphs() {
    const selectedSessions = this.getSelectedSessions();
    const selectSessionsThatOnlyHasZoneAndStreakData = session =>
      [
        'zonePercentage',
        'deepZonePercentage',
        'maxStreak',
        'medianStreak',
      ].every(requiredDataKey => has(session, requiredDataKey));

    // we filter these sessions because old sessions did not have these data at all
    return selectedSessions.filter(selectSessionsThatOnlyHasZoneAndStreakData);
  }

  getSelectedSessions() {
    const { selectedSessions } = this.state;

    return selectedSessions;
  }

  onClearSelectedDurationClick = () =>
    this.setState(prvState => {
      const newState = {
        minDurationValue: prvState.minDuration,
        maxDurationValue: prvState.maxDuration,
        minDurationSavedValue: prvState.minDuration,
        maxDurationSavedValue: prvState.maxDuration,
      };

      reportGeneratorEventLogger.log(
        'on_clear_selected_duration_click',
        newState
      );

      return newState;
    }, this.onFiltersUpdated);

  getDurationFilterProps = () => {
    const {
      minDuration,
      maxDuration,
      minDurationValue,
      maxDurationValue,
      minDurationSavedValue,
      maxDurationSavedValue,
    } = this.state;

    const getOnDurationValueChange = statePropName => event => {
      const getCaretPosition = element => {
        if (element === null) return -1;
        return element.selectionStart || -1;
      };

      const setCaretPosition = (elem, caretPos) => {
        if (elem != null && caretPos !== -1) {
          if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
          } else {
            if (elem.selectionStart) {
              elem.focus();
              elem.setSelectionRange(caretPos, caretPos);
            } else elem.focus();
          }
        }
      };

      const eventTarget = event.target;
      const formattedValue = eventTarget.value;
      const caretPosition = getCaretPosition(eventTarget);
      const prvCharIndex = caretPosition;
      const prvChar = formattedValue[prvCharIndex];
      const newChar = formattedValue[prvCharIndex - 1];

      const restoreCaretPosition = () =>
        setCaretPosition(eventTarget, caretPosition);

      if (formattedValue === '') {
        this.setState({
          [statePropName]: 0,
        });
      } else if (
        prvChar !== ':' &&
        prvChar !== undefined &&
        isCharADigit(newChar)
      ) {
        if (!(prvCharIndex === 5 && parseInt(newChar, 10) > 5)) {
          const newFormattedValue = formattedValue
            .split('')
            .map((value, index) => (index === prvCharIndex ? '' : value))
            .join('');

          const parsedValue = MMSStoSeconds(newFormattedValue);

          this.setState(
            {
              [statePropName]: parsedValue,
            },
            restoreCaretPosition
          );
        } else {
          this.setState(
            prvState => ({
              [statePropName]: prvState[statePropName],
            }),
            restoreCaretPosition
          );
        }
      } else {
        this.setState(
          prvState => ({
            [statePropName]: prvState[statePropName],
          }),
          restoreCaretPosition
        );
      }
    };

    const onDurationDoneClick = () =>
      this.setState(prvState => {
        const newState = {
          minDurationSavedValue: prvState.minDurationValue,
          maxDurationSavedValue: prvState.maxDurationValue,
        };

        reportGeneratorEventLogger.log('on_duration_done_click', newState);
        return newState;
      }, this.onFiltersUpdated);

    const onDurationSliderValuesChange = values => {
      this.setState({
        minDurationValue: values[0],
        maxDurationValue: values[1],
      });
    };

    const onDurationClickOutside = () =>
      this.setState(prvState => ({
        minDurationValue: prvState.minDurationSavedValue,
        maxDurationValue: prvState.maxDurationSavedValue,
      }));

    const isDurationDoneBtnEnabled =
      (minDurationValue !== minDurationSavedValue ||
        maxDurationValue !== maxDurationSavedValue) &&
      minDurationValue <= maxDurationValue;

    return {
      minDuration,
      maxDuration,
      minDurationValue,
      maxDurationValue,
      minDurationSavedValue,
      maxDurationSavedValue,
      onMinDurationValueChange: getOnDurationValueChange('minDurationValue'),
      onMaxDurationValueChange: getOnDurationValueChange('maxDurationValue'),
      onClearSelectedDurationClick: this.onClearSelectedDurationClick,
      onDurationDoneClick,
      onDurationSliderValuesChange,
      onDurationClickOutside,
      isDurationDoneBtnEnabled,
      ...this.getFormattedDurationValues(),
    };
  };

  onClearPositiveFeedbackClick = () =>
    this.setState(prvState => {
      const newState = {
        minPositiveFeedbackValue: prvState.minPositiveFeedback,
        maxPositiveFeedbackValue: prvState.maxPositiveFeedback,
        maxPositiveFeedbackSavedValue: prvState.maxPositiveFeedback,
        minPositiveFeedbackSavedValue: prvState.minPositiveFeedback,
      };

      reportGeneratorEventLogger.log(
        'on_clear_positive_feedback_click',
        newState
      );
      return newState;
    }, this.onFiltersUpdated);

  getPositiveFeedbackFilterProps = () => {
    const {
      minPositiveFeedback,
      maxPositiveFeedback,
      minPositiveFeedbackValue,
      maxPositiveFeedbackValue,
      minPositiveFeedbackSavedValue,
      maxPositiveFeedbackSavedValue,
      isPositiveFeedbackFilterSaved,
    } = this.state;

    const getOnPositiveFeedbackValueChange = statePropName => event => {
      const { value } = event.target;

      if (value.split('').every(isCharADigit)) {
        this.setState({
          [statePropName]: parseInt(value, 10) || 0,
        });
      }
    };

    const onPositiveFeedbackDoneClick = () =>
      this.setState(prvState => {
        const newState = {
          minPositiveFeedbackSavedValue: prvState.minPositiveFeedbackValue,
          maxPositiveFeedbackSavedValue: prvState.maxPositiveFeedbackValue,
        };

        reportGeneratorEventLogger.log(
          'on_positive_feedback_done_click',
          newState
        );
        return newState;
      }, this.onFiltersUpdated);

    const onPositiveFeedbackClickOutside = () =>
      this.setState(prvState => ({
        minPositiveFeedbackValue: prvState.minPositiveFeedbackSavedValue,
        maxPositiveFeedbackValue: prvState.maxPositiveFeedbackSavedValue,
      }));

    const onPositiveFeedbackSliderValuesChange = values => {
      this.setState({
        minPositiveFeedbackValue: values[0],
        maxPositiveFeedbackValue: values[1],
      });
    };

    const isPositiveFeedbackDoneBtnEnabled =
      (minPositiveFeedbackValue !== minPositiveFeedbackSavedValue ||
        maxPositiveFeedbackValue !== maxPositiveFeedbackSavedValue) &&
      minPositiveFeedbackValue <= maxPositiveFeedbackValue;

    return {
      minPositiveFeedback,
      maxPositiveFeedback,
      minPositiveFeedbackValue,
      maxPositiveFeedbackValue,
      minPositiveFeedbackSavedValue,
      maxPositiveFeedbackSavedValue,
      isPositiveFeedbackFilterSaved,
      onMinPositiveFeedbackValueChange: getOnPositiveFeedbackValueChange(
        'minPositiveFeedbackValue'
      ),
      onMaxPositiveFeedbackValueChange: getOnPositiveFeedbackValueChange(
        'maxPositiveFeedbackValue'
      ),
      onClearPositiveFeedbackClick: this.onClearPositiveFeedbackClick,
      onPositiveFeedbackDoneClick,
      onPositiveFeedbackSliderValuesChange,
      onPositiveFeedbackClickOutside,
      isPositiveFeedbackDoneBtnEnabled,
    };
  };

  onClearAlertsNumberClick = () =>
    this.setState(prvState => {
      const newState = {
        minAlertsNumberValue: prvState.minAlertsNumber,
        maxAlertsNumberValue: prvState.maxAlertsNumber,
        minAlertsNumberSavedValue: prvState.minAlertsNumber,
        maxAlertsNumberSavedValue: prvState.maxAlertsNumber,
      };

      reportGeneratorEventLogger.log('on_clear_alerts_number', newState);
      return newState;
    }, this.onFiltersUpdated);

  getAlertsNumberFilterProps = () => {
    const {
      minAlertsNumber,
      maxAlertsNumber,
      minAlertsNumberValue,
      maxAlertsNumberSavedValue,
      minAlertsNumberSavedValue,
      maxAlertsNumberValue,
    } = this.state;

    const getOnAlertsNumberValueChange = statePropName => event => {
      const { value } = event.target;

      if (value.split('').every(isCharADigit)) {
        this.setState({
          [statePropName]: parseInt(value, 10),
        });
      }
    };

    const onAlertsNumberDoneClick = () =>
      this.setState(prvState => {
        const newState = {
          minAlertsNumberSavedValue: prvState.minAlertsNumberValue,
          maxAlertsNumberSavedValue: prvState.maxAlertsNumberValue,
        };

        reportGeneratorEventLogger.log('on_alerts_number_done_click', newState);
        return newState;
      }, this.onFiltersUpdated);

    const onAlertsNumberSliderValuesChange = values => {
      this.setState({
        minAlertsNumberValue: values[0],
        maxAlertsNumberValue: values[1],
      });
    };

    const onAlertsNumberClickOutside = () =>
      this.setState(prvState => ({
        minAlertsNumberValue: prvState.minAlertsNumberSavedValue,
        maxAlertsNumberValue: prvState.maxAlertsNumberSavedValue,
      }));

    const isAlertsNumberDoneBtnEnabled =
      (minAlertsNumberValue !== minAlertsNumberSavedValue ||
        maxAlertsNumberValue !== maxAlertsNumberSavedValue) &&
      minAlertsNumberValue <= maxAlertsNumberValue;

    return {
      minAlertsNumber,
      maxAlertsNumber,
      minAlertsNumberValue,
      maxAlertsNumberValue,
      maxAlertsNumberSavedValue,
      minAlertsNumberSavedValue,
      onMinAlertsNumberValueChange: getOnAlertsNumberValueChange(
        'minAlertsNumberValue'
      ),
      onMaxAlertsNumberValueChange: getOnAlertsNumberValueChange(
        'maxAlertsNumberValue'
      ),
      onClearAlertsNumberClick: this.onClearAlertsNumberClick,
      onAlertsNumberDoneClick,
      onAlertsNumberSliderValuesChange,
      onAlertsNumberClickOutside,
      isAlertsNumberDoneBtnEnabled,
    };
  };

  onClearNoiseClick = () =>
    this.setState(prvState => {
      const newState = {
        minNoiseValue: prvState.minNoise,
        maxNoiseValue: prvState.maxNoise,
        minNoiseSavedValue: prvState.minNoise,
        maxNoiseSavedValue: prvState.maxNoise,
      };

      reportGeneratorEventLogger.log('on_clear_noise_click', newState);
      return newState;
    }, this.onFiltersUpdated);

  getNoiseFilterProps = () => {
    const {
      minNoise,
      maxNoise,
      minNoiseValue,
      maxNoiseValue,
      minNoiseSavedValue,
      maxNoiseSavedValue,
    } = this.state;

    const getOnNoiseValueChange = statePropName => event => {
      const { value } = event.target;

      if (value.split('').every(isCharADigit)) {
        this.setState({
          [statePropName]: parseInt(value, 10) || 0,
        });
      }
    };

    const onNoiseDoneClick = () =>
      this.setState(prvState => {
        const newState = {
          minNoiseSavedValue: prvState.minNoiseValue,
          maxNoiseSavedValue: prvState.maxNoiseValue,
        };

        reportGeneratorEventLogger.log('on_noise_done_click', newState);
        return newState;
      }, this.onFiltersUpdated);

    const onNoiseSliderValuesChange = values => {
      this.setState({
        minNoiseValue: values[0],
        maxNoiseValue: values[1],
      });
    };

    const onNoiseClickOutside = () =>
      this.setState(prvState => ({
        minNoiseValue: prvState.minNoiseSavedValue,
        maxNoiseValue: prvState.maxNoiseSavedValue,
      }));

    const isNoiseDoneBtnEnabled =
      (minNoiseValue !== minNoiseSavedValue ||
        maxNoiseValue !== maxNoiseSavedValue) &&
      minNoiseValue <= maxNoiseValue;

    return {
      minNoise,
      maxNoise,
      minNoiseValue,
      maxNoiseValue,
      minNoiseSavedValue,
      maxNoiseSavedValue,
      onMinNoiseValueChange: getOnNoiseValueChange('minNoiseValue'),
      onMaxNoiseValueChange: getOnNoiseValueChange('maxNoiseValue'),
      onClearNoiseClick: this.onClearNoiseClick,
      onNoiseDoneClick,
      onNoiseSliderValuesChange,
      onNoiseClickOutside,
      isNoiseDoneBtnEnabled,
    };
  };

  onClearSelectedProtocolsClick = () =>
    this.setState(prvState => {
      reportGeneratorEventLogger.log('on_clear_selected_protocols_click', {
        selectedFilterProtocols: prvState.allFilterProtocols,
      });

      return {
        selectedFilterProtocols: prvState.allFilterProtocols,
        savedSelectedFilterProtocols: prvState.allFilterProtocols,
        shouldProtocolsToggleSelectAll: false,
      };
    }, this.onFiltersUpdated);

  onClearSelectedProgramsClick = () =>
    this.setState(prvState => {
      reportGeneratorEventLogger.log('on_clear_selected_programs_click', {
        selectedFilterPrograms: prvState.allFilterPrograms,
      });

      return {
        selectedFilterPrograms: prvState.allFilterPrograms,
        savedSelectedFilterPrograms: prvState.allFilterPrograms,
        shouldProgramsToggleSelectAll: false,
      };
    }, this.onFiltersUpdated);

  onClearSelectedSessionTypesClick = () =>
    this.setState(prvState => {
      reportGeneratorEventLogger.log('on_clear_selected_session_types_click', {
        selectedFilterSessionTypes: prvState.allFilterSessionTypes,
      });

      return {
        selectedFilterSessionTypes: prvState.allFilterSessionTypes,
        savedSelectedFilterSessionTypes: prvState.allFilterSessionTypes,
        shouldSessionTypesToggleSelectAll: false,
      };
    }, this.onFiltersUpdated);

  onClearSelectedTrainingSiteClick = () =>
    this.setState(prvState => {
      reportGeneratorEventLogger.log('on_clear_selected_training_site_click', {
        selectedFilterTrainingSite: prvState.allFilterTrainingSite,
      });

      return {
        selectedFilterTrainingSite: prvState.allFilterTrainingSite,
        savedSelectedFilterTrainingSite: prvState.allFilterTrainingSite,
        shouldTrainingSiteToggleSelectAll: false,
      };
    }, this.onFiltersUpdated);

  getProtocolsFilterProps = () => {
    const {
      allFilterProtocols,
      selectedFilterProtocols,
      isProtocolsFilterSaved,
      shouldProtocolsToggleSelectAll,
      savedSelectedFilterProtocols,
    } = this.state;

    const onProtocolsFilterDoneClick = () => {
      this.setState(prvState => {
        reportGeneratorEventLogger.log('on_protocols_filter_done_click', {
          savedSelectedFilterProtocols: prvState.selectedFilterProtocols,
        });

        return {
          savedSelectedFilterProtocols: prvState.selectedFilterProtocols,
        };
      }, this.onFiltersUpdated);
    };

    const onProtocolsClickOutside = () => {
      this.setState(prvState => {
        const newShouldProtocolsToggleSelectAll =
          prvState.savedSelectedFilterProtocols.length !==
          prvState.allFilterProtocols.length;
        return {
          selectedFilterProtocols: prvState.savedSelectedFilterProtocols,
          shouldProtocolsToggleSelectAll: newShouldProtocolsToggleSelectAll,
        };
      });
    };

    const onProtocolClick = protocol => {
      const protocolIndexInSelectedProtocols = selectedFilterProtocols.findIndex(
        selectedProtocol => selectedProtocol === protocol
      );
      const newSelectedFilterProtocols = cloneDeep(selectedFilterProtocols);

      if (protocolIndexInSelectedProtocols >= 0) {
        newSelectedFilterProtocols.splice(protocolIndexInSelectedProtocols, 1);
      } else {
        newSelectedFilterProtocols.push(protocol);
      }

      reportGeneratorEventLogger.log('on_protocol_click', {
        selectedFilterProtocols: newSelectedFilterProtocols,
      });

      this.setState({
        selectedFilterProtocols: newSelectedFilterProtocols,
      });
    };

    const onProtocolsSelectToggleClick = () => {
      this.setState(prvState => {
        let newSelectedFilterProtocols;
        if (prvState.shouldProtocolsToggleSelectAll) {
          newSelectedFilterProtocols = cloneDeep(allFilterProtocols);
        } else {
          newSelectedFilterProtocols = [];
        }

        reportGeneratorEventLogger.log('on_protocols_select_toggle', {
          selectedFilterProtocols: newSelectedFilterProtocols,
        });

        return {
          selectedFilterProtocols: newSelectedFilterProtocols,
          shouldProtocolsToggleSelectAll: !prvState.shouldProtocolsToggleSelectAll,
        };
      });
    };

    return {
      allProtocols: allFilterProtocols,
      selectedProtocols: selectedFilterProtocols,
      savedSelectedProtocols: savedSelectedFilterProtocols,
      shouldProtocolsToggleSelectAll,
      onProtocolClick,
      onProtocolsDoneClick: onProtocolsFilterDoneClick,
      isProtocolsFilterSaved,
      onClearSelectedProtocolsClick: this.onClearSelectedProtocolsClick,
      onProtocolsClickOutside,
      onProtocolsSelectToggleClick,
      isProtocolsDoneBtnEnabled:
        !isEqual(selectedFilterProtocols, savedSelectedFilterProtocols) &&
        selectedFilterProtocols.length !== 0,
    };
  };

  getProgramsFilterProps = () => {
    const {
      allFilterPrograms,
      selectedFilterPrograms,
      isProgramsFilterSaved,
      shouldProgramsToggleSelectAll,
      savedSelectedFilterPrograms,
    } = this.state;

    const onProgramsFilterDoneClick = () => {
      this.setState(prvState => {
        reportGeneratorEventLogger.log('on_program_filter_done_click', {
          savedSelectedFilterPrograms: prvState.selectedFilterPrograms,
        });

        return {
          savedSelectedFilterPrograms: prvState.selectedFilterPrograms,
        };
      }, this.onFiltersUpdated);
    };

    const onProgramsClickOutside = () => {
      this.setState(prvState => {
        const newShouldProgramsToggleSelectAll =
          prvState.savedSelectedFilterPrograms.length !==
          prvState.allFilterPrograms.length;
        return {
          selectedFilterPrograms: prvState.savedSelectedFilterPrograms,
          shouldProgramsToggleSelectAll: newShouldProgramsToggleSelectAll,
        };
      });
    };

    const onProgramClick = program => {
      const programIndexInSelectedPrograms = selectedFilterPrograms.findIndex(
        selectedProgram => selectedProgram === program
      );
      const newSelectedFilterPrograms = cloneDeep(selectedFilterPrograms);

      if (programIndexInSelectedPrograms >= 0) {
        newSelectedFilterPrograms.splice(programIndexInSelectedPrograms, 1);
      } else {
        newSelectedFilterPrograms.push(program);
      }

      reportGeneratorEventLogger.log('on_program_click', {
        selectedFilterPrograms: newSelectedFilterPrograms,
      });

      this.setState({
        selectedFilterPrograms: newSelectedFilterPrograms,
      });
    };

    const onProgramsSelectToggleClick = () => {
      this.setState(prvState => {
        let newSelectedFilterPrograms;
        if (prvState.shouldProgramsToggleSelectAll) {
          newSelectedFilterPrograms = cloneDeep(allFilterPrograms);
        } else {
          newSelectedFilterPrograms = [];
        }

        reportGeneratorEventLogger.log('on_programs_select_toggle', {
          selectedFilterPrograms: newSelectedFilterPrograms,
        });

        return {
          selectedFilterPrograms: newSelectedFilterPrograms,
          shouldProgramsToggleSelectAll: !prvState.shouldProgramsToggleSelectAll,
        };
      });
    };

    return {
      allPrograms: allFilterPrograms,
      selectedPrograms: selectedFilterPrograms,
      savedSelectedPrograms: savedSelectedFilterPrograms,
      shouldProgramsToggleSelectAll,
      onProgramClick,
      onProgramsDoneClick: onProgramsFilterDoneClick,
      isProgramsFilterSaved,
      onClearSelectedProgramsClick: this.onClearSelectedProgramsClick,
      onProgramsClickOutside,
      onProgramsSelectToggleClick,
      isProgramsDoneBtnEnabled:
        !isEqual(selectedFilterPrograms, savedSelectedFilterPrograms) &&
        selectedFilterPrograms.length !== 0,
    };
  };

  getSessionTypesFilterProps = () => {
    const {
      allFilterSessionTypes,
      selectedFilterSessionTypes,
      isSessionTypesFilterSaved,
      shouldSessionTypesToggleSelectAll,
      savedSelectedFilterSessionTypes,
    } = this.state;

    const onSessionTypesFilterDoneClick = () => {
      this.setState(prvState => {
        reportGeneratorEventLogger.log('on_session_types_filter_done_click', {
          savedSelectedFilterSessionTypes: prvState.selectedFilterSessionTypes,
        });

        return {
          savedSelectedFilterSessionTypes: prvState.selectedFilterSessionTypes,
        };
      }, this.onFiltersUpdated);
    };

    const onSessionTypesClickOutside = () => {
      this.setState(prvState => {
        const newShouldSessionTypesToggleSelectAll =
          prvState.savedSelectedFilterSessionTypes.length !==
          prvState.allFilterSessionTypes.length;
        return {
          selectedFilterSessionTypes: prvState.savedSelectedFilterSessionTypes,
          shouldSessionTypesToggleSelectAll: newShouldSessionTypesToggleSelectAll,
        };
      });
    };

    const onSessionTypeClick = sessionType => {
      const sessionTypeIndexInSelectedSessionTypes = selectedFilterSessionTypes.findIndex(
        selectedSessionType => selectedSessionType === sessionType
      );

      const newSelectedFilterSessionTypes = cloneDeep(
        selectedFilterSessionTypes
      );

      if (sessionTypeIndexInSelectedSessionTypes >= 0) {
        newSelectedFilterSessionTypes.splice(
          sessionTypeIndexInSelectedSessionTypes,
          1
        );
      } else {
        newSelectedFilterSessionTypes.push(sessionType);
      }

      reportGeneratorEventLogger.log('on_session_type_click', {
        selectedFilterSessionTypes: newSelectedFilterSessionTypes,
      });

      this.setState({
        selectedFilterSessionTypes: newSelectedFilterSessionTypes,
      });
    };

    const onSessionTypesSelectToggleClick = () => {
      this.setState(prvState => {
        let newSelectedFilterSessionTypes;
        if (prvState.shouldSessionTypesToggleSelectAll) {
          newSelectedFilterSessionTypes = cloneDeep(allFilterSessionTypes);
        } else {
          newSelectedFilterSessionTypes = [];
        }

        reportGeneratorEventLogger.log('on_session_types_select_toggle', {
          selectedFilterSessionTypes: newSelectedFilterSessionTypes,
        });

        return {
          selectedFilterSessionTypes: newSelectedFilterSessionTypes,
          shouldSessionTypesToggleSelectAll: !prvState.shouldSessionTypesToggleSelectAll,
        };
      });
    };

    const shouldHideSessionTypeFilter = !(
      this.props.trainingSessions &&
      Object.keys(this.props.trainingSessions).find(
        sessionId =>
          this.props.trainingSessions[sessionId].rapidSessionType ===
          rapidSessionTypes.withoutElectrode.val
      )
    );

    return {
      allSessionTypes: allFilterSessionTypes,
      selectedSessionTypes: selectedFilterSessionTypes,
      savedSelectedSessionTypes: savedSelectedFilterSessionTypes,
      shouldSessionTypesToggleSelectAll,
      onSessionTypeClick,
      onSessionTypesDoneClick: onSessionTypesFilterDoneClick,
      isSessionTypesFilterSaved,
      onClearSelectedSessionTypesClick: this.onClearSelectedSessionTypesClick,
      onSessionTypesClickOutside,
      onSessionTypesSelectToggleClick,
      isSessionTypesDoneBtnEnabled: !isEqual(
        selectedFilterSessionTypes,
        savedSelectedFilterSessionTypes
      ),
      shouldHideSessionTypeFilter,
    };
  };

  getTrainingSiteFilterProps = () => {
    const {
      allFilterTrainingSite,
      selectedFilterTrainingSite,
      isTrainingSiteFilterSaved,
      shouldTrainingSiteToggleSelectAll,
      savedSelectedFilterTrainingSite,
    } = this.state;

    const onTrainingSiteFilterDoneClick = () => {
      this.setState(prvState => {
        reportGeneratorEventLogger.log('on_training_site_filter_done_click', {
          savedSelectedFilterTrainingSite: prvState.selectedFilterTrainingSite,
        });

        return {
          savedSelectedFilterTrainingSite: prvState.selectedFilterTrainingSite,
        };
      }, this.onFiltersUpdated);
    };

    const onTrainingSiteClickOutside = () => {
      this.setState(prvState => {
        const newShouldTrainingSiteToggleSelectAll =
          prvState.savedSelectedFilterTrainingSite.length !==
          prvState.allFilterTrainingSite.length;
        return {
          selectedFilterTrainingSite: prvState.savedSelectedFilterTrainingSite,
          shouldTrainingSiteToggleSelectAll: newShouldTrainingSiteToggleSelectAll,
        };
      });
    };

    const onTrainingSiteClick = trainingSite => {
      const trainingSiteIndexInSelectedTrainingSite = selectedFilterTrainingSite.findIndex(
        selectedTrainingSite => selectedTrainingSite === trainingSite
      );
      const newSelectedFilterTrainingSite = cloneDeep(
        selectedFilterTrainingSite
      );

      if (trainingSiteIndexInSelectedTrainingSite >= 0) {
        newSelectedFilterTrainingSite.splice(
          trainingSiteIndexInSelectedTrainingSite,
          1
        );
      } else {
        newSelectedFilterTrainingSite.push(trainingSite);
      }

      reportGeneratorEventLogger.log('on_training_site_click', {
        selectedFilterTrainingSite: newSelectedFilterTrainingSite,
      });

      this.setState({
        selectedFilterTrainingSite: newSelectedFilterTrainingSite,
      });
    };

    const onTrainingSiteSelectToggleClick = () => {
      this.setState(prvState => {
        let newSelectedFilterTrainingSite;
        if (prvState.shouldTrainingSiteToggleSelectAll) {
          newSelectedFilterTrainingSite = cloneDeep(allFilterTrainingSite);
        } else {
          newSelectedFilterTrainingSite = [];
        }

        reportGeneratorEventLogger.log('on_training_site_select_toggle', {
          selectedFilterTrainingSite: newSelectedFilterTrainingSite,
        });

        return {
          selectedFilterTrainingSite: newSelectedFilterTrainingSite,
          shouldTrainingSiteToggleSelectAll: !prvState.shouldTrainingSiteToggleSelectAll,
        };
      });
    };

    return {
      allTrainingSite: allFilterTrainingSite,
      selectedTrainingSite: selectedFilterTrainingSite,
      savedSelectedTrainingSite: savedSelectedFilterTrainingSite,
      shouldTrainingSiteToggleSelectAll,
      onTrainingSiteClick,
      onTrainingSiteDoneClick: onTrainingSiteFilterDoneClick,
      isTrainingSiteFilterSaved,
      onClearSelectedTrainingSiteClick: this.onClearSelectedTrainingSiteClick,
      onTrainingSiteClickOutside,
      onTrainingSiteSelectToggleClick,
      isTrainingSiteDoneBtnEnabled:
        !isEqual(selectedFilterTrainingSite, savedSelectedFilterTrainingSite) &&
        selectedFilterTrainingSite.length !== 0,
    };
  };

  getFormattedDurationValues() {
    const {
      minDurationSavedValue,
      maxDurationSavedValue,
      minDurationValue,
      maxDurationValue,
      maxDuration,
    } = this.state;

    const maxDurationInMinutes = floatSecondsToFloatMinutes(maxDuration);
    const desiredLengthForMinutes =
      maxDurationInMinutes < 10
        ? 1
        : `${Math.floor(maxDurationInMinutes / 10)}`.length + 1;

    const formattedMinDurationSavedValue = formatSecondsToMMSS({
      seconds: minDurationSavedValue,
      desiredLengthForMinutes,
    });
    const formattedMaxDurationSavedValue = formatSecondsToMMSS({
      seconds: maxDurationSavedValue,
      desiredLengthForMinutes,
    });

    const formattedMinDurationValue = formatSecondsToMMSS({
      seconds: minDurationValue,
      desiredLengthForMinutes,
    });

    const formattedMaxDurationValue = formatSecondsToMMSS({
      seconds: maxDurationValue,
      desiredLengthForMinutes,
    });

    return {
      formattedMinDurationSavedValue,
      formattedMaxDurationSavedValue,
      formattedMinDurationValue,
      formattedMaxDurationValue,
    };
  }

  getSessionsGraphsFilterSavedValues() {
    const filterSavedValues = {};

    const {
      minDuration,
      maxDuration,
      minDurationSavedValue,
      maxDurationSavedValue,
      filterSavedStartDate,
      filterSavedEndDate,
      filterDefaultStartDate,
      filterDefaultEndDate,
      filterSavedStartTime,
      filterSavedEndTime,
      filterDefaultStartTime,
      filterDefaultEndTime,
      savedSelectedFilterProtocols,
      allFilterProtocols,
      savedSelectedFilterPrograms,
      allFilterPrograms,
      savedSelectedFilterSessionTypes,
      allFilterSessionTypes,
      savedSelectedFilterTrainingSite,
      allFilterTrainingSite,
      minPositiveFeedbackSavedValue,
      maxPositiveFeedbackSavedValue,
      minPositiveFeedback,
      maxPositiveFeedback,
      minAlertsNumberSavedValue,
      maxAlertsNumberSavedValue,
      minAlertsNumber,
      maxAlertsNumber,
      minNoiseSavedValue,
      maxNoiseSavedValue,
      minNoise,
      maxNoise,
    } = this.state;

    const {
      formattedMinDurationSavedValue,
      formattedMaxDurationSavedValue,
    } = this.getFormattedDurationValues();

    if (
      minDurationSavedValue !== minDuration ||
      maxDurationSavedValue !== maxDuration
    ) {
      filterSavedValues.duration = `Duration: ${formattedMinDurationSavedValue} - ${formattedMaxDurationSavedValue}`;
    }

    if (
      filterSavedStartDate &&
      filterSavedEndDate &&
      (filterSavedStartDate !== filterDefaultStartDate ||
        filterSavedEndDate !== filterDefaultEndDate)
    ) {
      filterSavedValues.dates = `Dates: ${filterSavedStartDate.format(
        'YYYY-MM-DD'
      )}-${filterSavedEndDate.format('YYYY-MM-DD')}`;
    }

    if (
      filterSavedStartTime &&
      filterSavedEndTime &&
      (filterSavedStartTime !== filterDefaultStartTime ||
        filterSavedEndTime !== filterDefaultEndTime)
    ) {
      filterSavedValues.times = `Times: ${filterSavedStartTime}-${filterSavedEndTime}`;
    }

    if (savedSelectedFilterProtocols.length !== allFilterProtocols.length) {
      filterSavedValues.protocols = `Protocols: ${savedSelectedFilterProtocols.join(
        ','
      )}`;
    }

    if (savedSelectedFilterPrograms.length !== allFilterPrograms.length) {
      filterSavedValues.programs = `Programs: ${savedSelectedFilterPrograms.join(
        ','
      )}`;
    }

    if (
      savedSelectedFilterSessionTypes.length !== allFilterSessionTypes.length
    ) {
      filterSavedValues.sessionTypes = `Session Types: ${savedSelectedFilterSessionTypes.join(
        ','
      )}`;
    }

    if (
      savedSelectedFilterTrainingSite.length !== allFilterTrainingSite.length
    ) {
      filterSavedValues.trainingSite = `Active Channel: ${savedSelectedFilterTrainingSite.join(
        ','
      )}`;
    }

    if (
      minPositiveFeedbackSavedValue !== minPositiveFeedback ||
      maxPositiveFeedbackSavedValue !== maxPositiveFeedback
    ) {
      filterSavedValues.positiveFeedback = `Positive Feedback: ${minPositiveFeedbackSavedValue}% - ${maxPositiveFeedbackSavedValue}%`;
    }

    if (
      minAlertsNumberSavedValue !== minAlertsNumber ||
      maxAlertsNumberSavedValue !== maxAlertsNumber
    ) {
      filterSavedValues.alertsNumber = `Alerts Number: ${minAlertsNumberSavedValue} - ${maxAlertsNumberSavedValue}`;
    }

    if (minNoiseSavedValue !== minNoise || maxNoiseSavedValue !== maxNoise) {
      filterSavedValues.noise = `Noise: ${minNoiseSavedValue}% - ${maxNoiseSavedValue}%`;
    }

    return filterSavedValues;
  }

  getFilterSavedValues() {
    const { selectedGraphsTab } = this.state;

    switch (selectedGraphsTab) {
      case sessionToSessionReportGeneratorTabs.sessionGraphs:
        return this.getSessionsGraphsFilterSavedValues();
      default:
        return {};
    }
  }

  getUserCardData = () => {
    const {
      patientFullName,
      patientAge,
      patientEmail,
      patientGender,
      sessionsAnalysis,
    } = this.props;

    const totalNumberOfSessions = sessionsAnalysis.length;
    const totalDurationOfSessions = sessionsAnalysis.reduce(
      (acc, cur) => acc + cur.duration,
      0
    );

    const totalDurationOfSessionsInMinutes = formatSecondsToMMSS({
      seconds: totalDurationOfSessions,
      shouldShowSeconds: false,
    });

    return {
      name: patientFullName,
      age: patientAge,
      email: patientEmail,
      gender: patientGender,
      totalNumberOfSessions,
      totalDurationInMinutes: totalDurationOfSessionsInMinutes,
    };
  };

  getDefaultReportCardsData = () => {
    const userCardData = {
      type: 'UserCard',
      id: userCardReportCardID,
      data: this.getUserCardData(),
    };

    const protocolsUsedInTrainingData = {
      type: 'ProtocolsUsedInTraining',
      id: protocolsUsedInTrainingReportCardID,
    };

    const progressReportTitleData = {
      type: 'ProgressReportTitle',
      id: progressReportTitleId,
      data: { timestamp: Date.now() },
    };

    const protocolsUsedInTrainingTitleData = {
      type: 'ProtocolsUsedInTrainingTitle',
      id: protocolsUsedInTrainingSectionTitleId,
    };

    const userAddedReportCardsTitleData = {
      type: 'UserAddReportCardsTitle',
      id: userAddedReportCardsSectionTitleId,
    };

    return [
      progressReportTitleData,
      userCardData,
      protocolsUsedInTrainingTitleData,
      protocolsUsedInTrainingData,
      userAddedReportCardsTitleData,
    ];
  };

  getGraphPayLoad(type) {
    if (type === zoneGraphType) {
      return this.getZoneGraphProps();
    }

    if (type === symptomsTrackingGraphType) {
      return this.getSymptomsTrackingGraphProps();
    }

    if (type === sessionComparisonGraphType) {
      return this.getSessionComparisonGraphProps();
    }

    if (type === frequenciesSessionComparisonGraphType) {
      return this.getFrequenciesSessionComparisonGraphProps();
    }

    if (type === streakSessionComparisonGraphType) {
      return this.getStreakSessionComparisonGraphProps();
    }

    if (type === amplitudeAndNoiseGraphType) {
      return this.getAmplitudeAndNoiseGraphProps();
    }

    if (type === inDepthSessionAnalysisGraphType) {
      return this.getInDepthSessionAnalysisGraphProps();
    }

    if (type === averageAndMaxStreakGraphType) {
      return this.getAverageAndMaxStreakGraphProps();
    }

    if (type === assessmentPerformanceGraphType) {
      return this.getPerformanceGraphProps();
    }

    if (type === assessmentCPTPerformanceGraphType) {
      return this.getCPTPerformanceGraphProps();
    }

    if (type === assessmentBrainMapsGraphType) {
      return this.getAssessmentBrainMapsComparisonGraphProps();
    }

    if (type === assessmentNoiseGraphType) {
      return this.getAssessmentNoiseComparisonGraphProps();
    }

    if (type === assessmentAmplitudePerFrequencyGraphType) {
      return this.getAssessmentAmplitudePerFrequencyComparisonGraphProps();
    }

    if (type === assessmentSwingleChecksGraphType) {
      return this.getAssessmentSwingleChecksProps();
    }

    if (type.startsWith(insightGraphType)) {
      return this.getInsightsGraphProps(type.replace(insightGraphType, ''));
    }

    if (type === freeTextCardType) {
      return this.getFreeTextCardProps();
    }

    if (type === textEditorCardType) {
      // note: there is normal props for edit mode and preview props, each with a different func.
      // (getTextEditorCardPreviewProps, getTextEditorCardProps)
      return this.getTextEditorCardPreviewProps();
    }

    if (type === assessmentQuestionnairesResultGraphType) {
      return this.getAssessmentQuestionnaireResultsGraphProps();
    }

    if (type === overallSymptomsTrackingGraphType) {
      return this.getOverallSymptomTrackingProps();
    }

    throw new Error('Unknown type provided for getReportCardData');
  }

  onAddableContentHeaderSave = ({ reportCardDataId, value }) =>
    this.setState(prvState => {
      const reportCardData = prvState.reportCardsData.find(
        cardData => cardData.id === reportCardDataId
      );

      reportCardData.data.header = value;

      return {
        reportCardsData: prvState.reportCardsData,
      };
    });

  onAddableContentSubHeaderSave = ({ reportCardDataId, value }) =>
    this.setState(prvState => {
      const reportCardData = prvState.reportCardsData.find(
        cardData => cardData.id === reportCardDataId
      );

      reportCardData.data.subHeader = value;

      return {
        reportCardsData: prvState.reportCardsData,
      };
    });

  setReportCardsDataInitialState = () => {
    const { reportCardsData } = this.state;

    const defaultReportCardsData = this.getDefaultReportCardsData();

    const newReportCardsData = defaultReportCardsData.concat(
      reportCardsData.filter(
        reportCardData => !reportCardData.id.startsWith('built-in')
      )
    );

    this.setState({ reportCardsData: newReportCardsData });
  };

  updateSessionsGraphsState() {
    const selectedSessions = this.getSelectedSessions();
    const prvAmplitudeAndNoiseGraphLegendData = this.state
      .amplitudeAndNoiseGraphLegendData;

    const prvFrequenciesSessionComparisonParameters = this.state
      .frequenciesSessionComparisonParameters;

    const selectedSessionsFrequencyNames = this.getFrequencyNames(
      selectedSessions
    );
    const nextFrequenciesSessionComparisonParameters = selectedSessionsFrequencyNames.reduce(
      (acc, frequencyName) => ({ ...acc, [frequencyName]: true }),
      {}
    );

    const frequenciesSessionComparisonParameters = {
      ...nextFrequenciesSessionComparisonParameters,
      ...pick(
        prvFrequenciesSessionComparisonParameters,
        Object.keys(nextFrequenciesSessionComparisonParameters)
      ),
    };

    const amplitudeAndNoiseGraphLegendData = {
      averageNoise: true,
      ...cloneDeep(frequenciesSessionComparisonParameters),
      ...mapKeys(
        mapValues(frequenciesSessionComparisonParameters, () => false),
        (value, key) => `trend-${key}`
      ),
      ...pick(
        prvAmplitudeAndNoiseGraphLegendData,
        Object.keys(frequenciesSessionComparisonParameters).concat(
          Object.keys(frequenciesSessionComparisonParameters).map(
            key => `trend-${key}`
          )
        )
      ),
    };

    this.setState({
      amplitudeAndNoiseGraphLegendData,
      frequenciesSessionComparisonParameters,
    });
  }

  setSymptomTrackerGraphData = () => {
    const {
      symptomsTrackingAnswers,
      symptomsTrackingAllQuestions,
      shouldUseNewSymptomTracker,
      assessmentResultsWithDeletedAssessments,
    } = this.props;

    const symptomsTrackingAnswersFiltered = JSON.parse(
      JSON.stringify(symptomsTrackingAnswers)
    );
    Object.keys(symptomsTrackingAnswers).forEach(answerId => {
      if (
        assessmentResultsWithDeletedAssessments[
          symptomsTrackingAnswers[answerId].assessmentId
        ] &&
        assessmentResultsWithDeletedAssessments[
          symptomsTrackingAnswers[answerId].assessmentId
        ].isDeleted
      ) {
        delete symptomsTrackingAnswersFiltered[answerId];
      }
    });

    const { selectedSymptomsTrackingQuestionnaires } = this.state;

    const symptomsTrackingGraphData = aggregateSymptomsTrackingAnswersDataSortedByTimestamp(
      {
        symptomsTrackingAnswers: symptomsTrackingAnswersFiltered,
        allSymptomTrackerQuestions: symptomsTrackingAllQuestions,
        questionsFilter: question =>
          shouldUseNewSymptomTracker
            ? selectedSymptomsTrackingQuestionnaires.includes(
                getQuestionnaireIdFromQuestion({ question })
              )
            : () => true,
      }
    );

    const symptomsTrackingLegendItems = turnAggregatedSymptomsTrackingAnswersDataIntoLegendItems(
      { aggregatedSymptomsTrackingAnswersData: symptomsTrackingGraphData }
    );

    this.setState({ symptomsTrackingGraphData, symptomsTrackingLegendItems });
  };

  async setSymptomTrackerGraphsInitialState() {
    const {
      symptomsTrackingAnswers,
      shouldUseNewSymptomTracker,
      symptomsTrackingAllQuestions,
    } = this.props;

    if (shouldUseNewSymptomTracker) {
      const allQuestionsInAnswers = getQuestionsFromSymptomTrackingAnswers({
        answersThatHaveQuestionnaireIds: symptomsTrackingAnswers,
        allQuestions: symptomsTrackingAllQuestions,
      });

      const questionnaires = await getQuestionnairesFromQuestions({
        questions: allQuestionsInAnswers,
        clinicId: sessionStorage.userId,
        // shouldExcludeCustomQuestionnaires: true,
      });

      const questionnairesWithoutSubScale = await getQuestionnairesFromQuestions(
        {
          questions: allQuestionsInAnswers,
          clinicId: sessionStorage.userId,
          shouldDifferentiateBasedOnSubScale: false,
          // shouldExcludeCustomQuestionnaires: true,
        }
      );

      return this.setState(
        {
          selectedSymptomsTrackingQuestionnaires: Object.values(
            questionnaires
          ).map(questionnaire => questionnaire.id),
          allSymptomTrackingQuestionnaires: questionnaires,
          allSymptomTrackingQuestionnairesWithoutSubScales: questionnairesWithoutSubScale,
          questionnaireResultsSelectedQuestionnaires: Object.values(
            questionnairesWithoutSubScale
          ).map(questionnaire => questionnaire.id),
        },
        this.setSymptomTrackerGraphData
      );
    }

    return this.setSymptomTrackerGraphData();
  }

  setSessionGraphsFiltersInitialState() {
    const { sessionsAnalysis, sessionBlocks } = this.props;

    const allFilterTrainingSiteWithDuplicates = sessionsAnalysis.map(
      session => session.training_channel
    );
    const allFilterTrainingSite = uniqWith(
      allFilterTrainingSiteWithDuplicates,
      isEqual
    );

    const allFilterProgramsWithDuplicates = Object.keys(sessionBlocks || [])
      .filter(
        sessionId =>
          sessionBlocks[sessionId].protocolDetailsItem &&
          sessionBlocks[sessionId].protocolDetailsItem.name
      )
      .map(sessionId => sessionBlocks[sessionId].protocolDetailsItem.name);

    const allFilterPrograms = uniqWith(
      allFilterProgramsWithDuplicates,
      isEqual
    );

    const allFilterSessionTypes = [
      rapidSessionTypes.withElectrode.name,
      rapidSessionTypes.withoutElectrode.name,
    ];

    const allFilterProtocolsWithDuplicates = sessionsAnalysis.map(
      session => session.protocol
    );

    const allFilterProtocols = uniqWith(
      allFilterProtocolsWithDuplicates,
      isEqual
    );

    const filterDefaultStartDate =
      (sessionsAnalysis[0] &&
        moment(new Date(sessionsAnalysis[0].timestamp))) ||
      moment().subtract(10, 'days');

    const filterDefaultEndDate =
      (sessionsAnalysis[0] &&
        moment(
          new Date(sessionsAnalysis[sessionsAnalysis.length - 1].timestamp)
        )) ||
      moment();

    const filterDefaultStartTime = '--:--';

    const filterDefaultEndTime = '--:--';

    const minAlertsNumber =
      sessionsAnalysis.length === 0
        ? defaultMinAlertsNumber
        : sessionsAnalysis.reduce(
            (acc, cur) => (acc < cur.alerts ? acc : cur.alerts),
            sessionsAnalysis[0].alerts
          );

    const maxAlertsNumber =
      sessionsAnalysis.length === 0
        ? defaultMaxAlertsNumber
        : sessionsAnalysis.reduce(
            (acc, cur) => (acc > cur.alerts ? acc : cur.alerts),
            sessionsAnalysis[0].alerts
          );

    const minAlertsNumberValue = minAlertsNumber;
    const minAlertsNumberSavedValue = minAlertsNumber;
    const maxAlertsNumberValue = maxAlertsNumber;
    const maxAlertsNumberSavedValue = maxAlertsNumber;

    const minDuration =
      sessionsAnalysis.length === 0
        ? defaultMinDuration
        : sessionsAnalysis.reduce(
            (acc, cur) => (acc < cur.duration ? acc : cur.duration),
            sessionsAnalysis[0].duration
          );

    const maxDuration =
      sessionsAnalysis.length === 0
        ? defaultMaxDuration
        : sessionsAnalysis.reduce(
            (acc, cur) => (acc > cur.duration ? acc : cur.duration),
            sessionsAnalysis[0].duration
          );

    const minDurationSavedValue = minDuration;
    const minDurationValue = minDuration;
    const maxDurationSavedValue = maxDuration;
    const maxDurationValue = maxDuration;

    this.setState({
      allFilterProtocols,
      selectedFilterProtocols: allFilterProtocols,
      savedSelectedFilterProtocols: allFilterProtocols,
      allFilterPrograms,
      selectedFilterPrograms: allFilterPrograms,
      savedSelectedFilterPrograms: allFilterPrograms,
      allFilterSessionTypes,
      selectedFilterSessionTypes: allFilterSessionTypes,
      savedSelectedFilterSessionTypes: allFilterSessionTypes,
      allFilterTrainingSite,
      selectedFilterTrainingSite: allFilterTrainingSite,
      savedSelectedFilterTrainingSite: allFilterTrainingSite,
      filterDefaultStartDate,
      filterDefaultEndDate,
      filterDefaultStartTime,
      filterDefaultEndTime,
      minDuration,
      minDurationValue,
      minDurationSavedValue,
      maxDuration,
      maxDurationValue,
      maxDurationSavedValue,
      minAlertsNumber,
      minAlertsNumberValue,
      minAlertsNumberSavedValue,
      maxAlertsNumber,
      maxAlertsNumberValue,
      maxAlertsNumberSavedValue,
    });
  }

  getSessionComparisonSlicesOptionsWithNumbersOfSessions() {
    const selectedSessions = this.getSelectedSessions();

    return sessionToSessionComparisonSlicesOptions.map(sessionSliceOption => {
      const numberOfSessionsForSessionComparison = getNumberOfSessionsInComparison(
        {
          sessions: selectedSessions,
          sessionComparisonSlice: sessionSliceOption.value,
        }
      );

      const sessionText =
        numberOfSessionsForSessionComparison === 1 ? 'session' : 'sessions';

      const newSessionSliceOption = { ...sessionSliceOption };
      newSessionSliceOption.text = `${sessionSliceOption.text} (${numberOfSessionsForSessionComparison} ${sessionText} vs ${numberOfSessionsForSessionComparison} ${sessionText})`;

      return newSessionSliceOption;
    });
  }

  getFrequencyNames = memoize(selectedSessions => {
    const frequencyNames = selectedSessions.reduce((names, session) => {
      session.frequencyNames.forEach(frequencyName => {
        // eslint-disable-next-line no-param-reassign
        names[frequencyName] = frequencyName;
      });
      return names;
    }, {});

    return Object.keys(frequencyNames);
  });

  getFrequencyNamesToDerivedFrequencyNames = memoize(selectedSessions =>
    selectedSessions.reduce(
      (selectedSessionsAcc, session) => ({
        ...selectedSessionsAcc,
        ...session.frequencyNames.reduce(
          (frequencyNamesAcc, frequencyName, frequencyNameIndex) => ({
            ...frequencyNamesAcc,
            [frequencyName]: session.derivedFrequencyNames[frequencyNameIndex],
          }),
          {}
        ),
      }),
      {}
    )
  );

  getFrequencyColors() {
    const selectedSessionsFrequencyNames = this.getFrequencyNames(
      this.getSelectedSessions()
    );
    const frequencyNamesToDerivedFrequencyNames = this.getFrequencyNamesToDerivedFrequencyNames(
      this.getSelectedSessions()
    );
    const frequencyNamesOccurrences = Object.values(
      frequencyNamesToDerivedFrequencyNames
    ).reduce((acc, cur) => ({ ...acc, [cur]: acc[cur] + 1 || 1 }), {});

    const currentDerivedFrequencyColorsIndex = {};
    return Math.max(...Object.values(frequencyNamesOccurrences)) > 5
      ? generateColors(selectedSessionsFrequencyNames.length)
      : selectedSessionsFrequencyNames.reduce((colorsObject, frequencyName) => {
          const derivedFrequencyName =
            frequencyNamesToDerivedFrequencyNames[frequencyName];
          currentDerivedFrequencyColorsIndex[derivedFrequencyName] =
            currentDerivedFrequencyColorsIndex[derivedFrequencyName] + 1 || 0;
          const currentDerivedFrequencyColorIndex =
            currentDerivedFrequencyColorsIndex[derivedFrequencyName];

          const color =
            `${derivedFrequencyName}` === 'undefined'
              ? shadesOfGreyColors[currentDerivedFrequencyColorIndex]
              : frequenciesColors[derivedFrequencyName][
                  currentDerivedFrequencyColorIndex
                ];

          return { ...colorsObject, [frequencyName]: color };
        }, {});
  }

  getFrequenciesSessionComparisonGraphProps = () => {
    const {
      frequenciesSessionComparisonSlice,
      frequenciesSessionComparisonParameters,
      frequenciesSessionComparisonAggregationMethod,
    } = this.state;

    const selectedSessions = this.getSelectedSessions();

    const mapSessionComparisonSlicesToData = sessionComparisonSessionsSlices => {
      return sessionComparisonSessionsSlices
        .map(sessionSlice =>
          sessionSlice.map(session => {
            return session.frequencyNames.reduce(
              (acc, cur, index) => ({
                ...acc,
                [cur]: session.averagePowers[index],
              }),
              {}
            );
          })
        )
        .map(sessionSlice =>
          frequenciesSessionComparisonAggregationMethod ===
          frequenciesSessionComparisonAggregationMethods.average
            ? reduceToAverageOverKeys(
                sessionSlice,
                Object.keys(frequenciesSessionComparisonParameters)
              )
            : reduceToMedianOverKeys(
                sessionSlice,
                Object.keys(frequenciesSessionComparisonParameters)
              )
        );
    };

    const {
      data,
      numberOfSessionsInComparison,
    } = getDataAndNumberOfSessionsInComparison({
      sessions: selectedSessions,
      sessionComparisonSlice: frequenciesSessionComparisonSlice,
      mapSessionComparisonSlicesToData,
    });

    const numOfComparisonParameters = Object.keys(
      frequenciesSessionComparisonParameters
    ).length;

    const colors = this.getFrequencyColors();

    return {
      data,
      numberOfSessionsInComparison,
      numOfComparisonParameters,
      sessionSliceOptions: this.getSessionComparisonSlicesOptionsWithNumbersOfSessions(),
      sessionSlice: frequenciesSessionComparisonSlice,
      comparisonParameters: frequenciesSessionComparisonParameters,
      aggregationMethod: frequenciesSessionComparisonAggregationMethod,
      colors,
    };
  };

  getSessionComparisonGraphProps() {
    const { sessionComparisonSlice, sessionComparisonParameters } = this.state;
    const selectedSessions = this.getSelectedSessions();

    const mapSessionComparisonSlicesToData = sessionComparisonSessionsSlices =>
      sessionComparisonSessionsSlices.map(sessionsSlice =>
        reduceToAverageOverKeys(
          sessionsSlice.map(session =>
            pickBy(session, (value, key) => sessionComparisonParameters[key])
          ),
          Object.keys(sessionComparisonParameters)
        )
      );

    const {
      data,
      numberOfSessionsInComparison,
    } = getDataAndNumberOfSessionsInComparison({
      sessions: selectedSessions,
      sessionComparisonSlice,
      mapSessionComparisonSlicesToData,
    });

    return {
      data,
      numberOfSessionsInComparison,
      sessionSlice: sessionComparisonSlice,
      comparisonParameters: sessionComparisonParameters,
      dataFormatedNames: sessionComparisonParametersNames,
      sessionSliceOptions: this.getSessionComparisonSlicesOptionsWithNumbersOfSessions(),
    };
  }

  getAmplitudeAndNoiseGraphProps() {
    const {
      areAmplitudeAndNoiseGraphTrendLinesEnabled: areTrendLinesEnabled,
      amplitudeAndNoiseGraphLegendData,
    } = this.state;

    const selectedSessions = this.getSelectedSessions();

    const amplitudeAndNoiseGraphData = selectedSessions.map(session => ({
      date: format(
        new Date(+session.timestamp),
        FNS_DATE_FORMATS.americanFullDate
      ),
      averageNoise: session.averageNoise,
      ...session.averagePowers.reduce(
        (acc, cur, index) => ({
          ...acc,
          [session.frequencyNames[index]]: cur,
        }),
        {}
      ),
    }));

    const frequencyColors = this.getFrequencyColors();

    const amplitudeAndNoiseGraphLegendKeys = Object.keys(
      amplitudeAndNoiseGraphLegendData
    ).sort();

    const colors = Array.isArray(frequencyColors)
      ? mapValues(
          amplitudeAndNoiseGraphLegendData,
          (isLegendItemEnabled, legendItem) =>
            legendItem === 'averageNoise'
              ? averageNoiseColor
              : frequencyColors[
                  amplitudeAndNoiseGraphLegendKeys.indexOf(
                    legendItem.replace('trend-', '')
                  )
                ]
        )
      : {
          ...frequencyColors,
          ...mapKeys(frequencyColors, (value, key) => `trend-${key}`),
          averageNoise: averageNoiseColor,
        };

    return {
      data: amplitudeAndNoiseGraphData,
      legendItems: amplitudeAndNoiseGraphLegendData,
      areTrendLinesEnabled,
      colors,
    };
  }

  getInDepthSessionAnalysisGraphProps() {
    const { selectedInDepthSession, currentSessionData } = this.state;
    const { trainingSessions } = this.props;
    // eslint-disable-next-line no-undef
    const frequencyNamesArr =
      trainingSessions &&
      trainingSessions[selectedInDepthSession] &&
      trainingSessions[selectedInDepthSession].rounds &&
      trainingSessions[selectedInDepthSession].rounds[
        Object.keys(trainingSessions[selectedInDepthSession].rounds)[0]
      ] // eslint-disable-next-line no-undef
        ? getFrequencyNamesArr(
            trainingSessions[selectedInDepthSession].rounds[
              Object.keys(trainingSessions[selectedInDepthSession].rounds)[0]
            ]
          )
        : [];

    const data = currentSessionData
      ? currentSessionData.roundsPowerMeans.map((roundData, index) => {
          return {
            name: `Round ${index + 1}`,
          };
        })
      : [];

    data.forEach((round, index) => {
      currentSessionData.roundsPowerMeans[index].forEach(
        (result, freqIndex) => {
          data[index][frequencyNamesArr[freqIndex]] =
            currentSessionData.roundsPowerMeans[index][freqIndex];
        }
      );
    });

    data.forEach((round, index) => {
      currentSessionData.roundsThresholdMeans[index].forEach(
        (result, freqIndex) => {
          // eslint-disable-next-line prefer-template
          data[index][frequencyNamesArr[freqIndex] + ' threshold'] =
            currentSessionData.roundsThresholdMeans[index][freqIndex];
        }
      );
    });

    const sessions = this.props.sessionsAnalysis.map((session, index) => {
      return {
        sessionLabel:
          // eslint-disable-next-line prefer-template
          dayJS(new Date(+session.timestamp)).format(
            DAY_JS_DATE_FORMATS.americanWithTime
          ) + ` | Session #${index + 1}`,
        ...session,
      };
    });

    const shouldRenderGraph =
      this.props.trainingSessions &&
      this.props.trainingSessions[this.state.selectedInDepthSession] &&
      Object.keys(
        this.props.trainingSessions[this.state.selectedInDepthSession].rounds
      ).length > 1;

    const frequencyColors = this.getFrequencyColors();

    return {
      data,
      sessions,
      selectedInDepthSession,
      frequencyNamesArr,
      shouldRenderGraph,
      toggleFrequencyAmplitudes: this.state.toggleFrequencyAmplitudes,
      toggleThresholds: this.state.toggleThresholds,
      trainingSessions: this.props.trainingSessions,
      frequencyColors,
    };
  }

  getStreakSessionComparisonGraphProps() {
    const {
      streakSessionComparisonSlice,
      streakSessionComparisonParameters,
    } = this.state;

    const selectedSessions = this.getSelectedSessions();

    const mapSessionComparisonSlicesToData = sessionComparisonSessionsSlices =>
      sessionComparisonSessionsSlices.map(sessionsSlice =>
        reduceToAverageOverKeys(
          sessionsSlice.map(session =>
            pickBy(
              session,
              (value, key) => streakSessionComparisonParameters[key]
            )
          ),
          Object.keys(streakSessionComparisonParameters)
        )
      );

    const {
      data,
      numberOfSessionsInComparison,
    } = getDataAndNumberOfSessionsInComparison({
      sessions: selectedSessions,
      sessionComparisonSlice: streakSessionComparisonSlice,
      mapSessionComparisonSlicesToData,
    });

    return {
      data,
      numberOfSessionsInComparison,
      sessionSliceOptions: this.getSessionComparisonSlicesOptionsWithNumbersOfSessions(),
      sessionSlice: streakSessionComparisonSlice,
      comparisonParameters: streakSessionComparisonParameters,
      dataFormatedNames: streakSessionComparisonParametersNames,
    };
  }

  renderNoDataIndicator = ({
    text = 'User has no recorded data yet.',
  } = {}) => (
    <div className={styles.no_data_indicator_container}>
      <NoDataIndicator text={text} />
    </div>
  );

  renderNoDataIndicatorForAssessmentGraphs = () => {
    const { t } = this.props;
    return this.renderNoDataIndicator({
      text: t('two-assessments-are-needed-to-show-data'),
    });
  };

  renderNoDataIndicatorForInDepthSessionAnalysis = () => {
    // const { t } = this.props;
    return this.renderNoDataIndicator({
      text: 'Graph cannot be displayed for one round only.',
    });
  };

  getSymptomsTrackingGraphProps = () => {
    const {
      symptomsTrackingGraphData,
      symptomsTrackingLegendItems,
    } = this.state;

    return {
      data: symptomsTrackingGraphData,
      legendItems: symptomsTrackingLegendItems,
    };
  };

  getOverallSymptomTrackingProps = () => {
    const {
      filteredOverallQuestionnaires,
      allOverallQuestionnaires,
      overallSymptomTrackerGraphData,
    } = this.state;
    return {
      filteredOverallQuestionnaires,
      allOverallQuestionnaires,
      graphData: overallSymptomTrackerGraphData,
    };
  };

  onSelectedQuestionnairesDataChange = event => {
    if (event.target.value.length) {
      this.setState({ filteredOverallQuestionnaires: event.target.value });
    }
  };

  onSelectedSymptomQuestionnairesChange = event => {
    if (event.target.value && event.target.value.length) {
      this.setState(
        {
          selectedSymptomsTrackingQuestionnaires: event.target.value,
        },
        this.setSymptomTrackerGraphData
      );
    }
  };

  onQuestionnaireResultsSelectedQuestionnairesChange = event => {
    if (event.target.value.length) {
      this.setState({
        questionnaireResultsSelectedQuestionnaires: event.target.value,
      });
    }
  };

  renderSymptomsTrackingGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const {
      t,
      // onDownloadSymptomsTrackingDataAsCSV,
      shouldUseNewSymptomTracker,
    } = this.props;
    const { onDownloadSymptomsTrackingDataAsCSV } = this.state;

    const {
      selectedSymptomsTrackingQuestionnaires,
      allSymptomTrackingQuestionnaires,
    } = this.state;
    const symptomsTrackingGraphProps = this.getSymptomsTrackingGraphProps();
    const { data: symptomsTrackingGraphData } = symptomsTrackingGraphProps;

    const symptomsTrackingGraphDefaultHeader = t('individual_symptom_tracking');
    const symptomsTrackingGraphDefaultSubHeader = t(
      'see_answers_for_symptom_tracking'
    );

    const onLegendItemClick = legendItemName => {
      reportGeneratorEventLogger.log(
        'clicking_on_symptoms_tracking_graph_legend_item',
        {
          legendItemName,
        }
      );

      this.setState(prvState => {
        const legendItems = cloneDeep(prvState.symptomsTrackingLegendItems);
        const legendItemArr = legendItems.find(
          arr => arr[0] === legendItemName
        );
        legendItemArr[1] = !legendItemArr[1];
        return {
          symptomsTrackingLegendItems: legendItems,
        };
      });
    };

    const shouldRenderGraph = symptomsTrackingGraphData.length > 0;

    const getIsAddToReportBtnDisabled = () => {
      const { legendItems } = this.getSymptomsTrackingGraphProps();
      const areAllLegendItemsDisabled =
        legendItems.filter(legendItemArr => legendItemArr[1]).length === 0;
      // notice that when all questionnaires are deselected, legendItems will be empty, and so areAllLegendItemsDisabled is true
      return areAllLegendItemsDisabled;
    };

    const verticalMoreMenuItems = [
      {
        text: 'Export CSV',
        onClick: () => {
          const { legendItems } = this.getSymptomsTrackingGraphProps();
          const filterQuestions = (questionId, question) => {
            return legendItems.find(
              ([questionText, isQuestionEnabled]) =>
                isQuestionEnabled && questionText === question.questionRep
            );
          };
          onDownloadSymptomsTrackingDataAsCSV(filterQuestions);
        },
        key: 'csv',
      },
    ];
    const headerRightContent = (
      <div>
        <VerticalMoreMenu menuItems={verticalMoreMenuItems} />
      </div>
    );

    return (
      <AddableContent
        header={symptomsTrackingGraphDefaultHeader}
        subHeader={symptomsTrackingGraphDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <SymptomsTrackingGraph
              onLegendItemClick={onLegendItemClick}
              selectedQuestionnaires={selectedSymptomsTrackingQuestionnaires}
              allQuestionnaires={allSymptomTrackingQuestionnaires}
              onSelectedQuestionnairesChange={
                this.onSelectedSymptomQuestionnairesChange
              }
              shouldUseNewSymptomTracker={shouldUseNewSymptomTracker}
              {...symptomsTrackingGraphProps}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        headerRightContent={headerRightContent}
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log('try_adding_symptoms_tracking_graph');
          this.setState({
            addToReportModalState: {
              addableContentHeader: symptomsTrackingGraphDefaultHeader,
              addableContentSubHeader: symptomsTrackingGraphDefaultSubHeader,
              addableContentType: symptomsTrackingGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderSymptomsTrackingGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderOverallSymptomsTrackingGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const {
      filteredOverallQuestionnaires,
      allOverallQuestionnaires,
      overallSymptomTrackerGraphData,
    } = this.state;
    // const symptomsTrackingGraphProps = this.getOverallSymptomTrackingProps();
    // const { data: overallSymptomTrackerGraphData } = symptomsTrackingGraphProps;

    const symptomsTrackingGraphDefaultHeader = 'Overall Symptom Tracking';
    const symptomsTrackingGraphDefaultSubHeader =
      'See the overall score for each standardized or custom questionnaire your client completed';

    const shouldRenderGraph =
      overallSymptomTrackerGraphData.length > 0 &&
      !isEmpty(allOverallQuestionnaires) &&
      Object.keys(allOverallQuestionnaires).find(
        questionnaire => !allOverallQuestionnaires[questionnaire].hideResult
      );

    const verticalMoreMenuItems = [
      {
        text: 'Export CSV',
        onClick: () => {
          // this.props.onDownloadOverallSymptomsTrackingDataAsCSV(() => true);
          this.state.onDownloadOverallSymptomsTrackingDataAsCSV(() => true); //
        },
        key: 'csv',
      },
    ];
    const headerRightContent = (
      <div>
        <VerticalMoreMenu menuItems={verticalMoreMenuItems} />
      </div>
    );

    return (
      <AddableContent
        header={symptomsTrackingGraphDefaultHeader}
        subHeader={symptomsTrackingGraphDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <OverallSymptomsTrackingGraph
              filteredOverallQuestionnaires={filteredOverallQuestionnaires}
              allOverallQuestionnaires={allOverallQuestionnaires}
              onSelectedQuestionnairesDataChange={
                this.onSelectedQuestionnairesDataChange
              }
              graphData={overallSymptomTrackerGraphData}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        headerRightContent={shouldRenderGraph ? headerRightContent : null}
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log(
            'try_adding_overall_symptoms_tracking_graph'
          );
          this.setState({
            addToReportModalState: {
              addableContentHeader: symptomsTrackingGraphDefaultHeader,
              addableContentSubHeader: symptomsTrackingGraphDefaultSubHeader,
              addableContentType: overallSymptomsTrackingGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderOverallSymptomsTrackingGraphAddableContent,
              // getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={!!shouldRenderGraph}
        // isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  getZoneGraphProps() {
    const { zoneGraphLegendItems } = this.state;
    const selectedSessions = this.getSelectedSessionsForZoneAndStreakGraphs();

    const zoneGraphData = selectedSessions.map(session => ({
      zonePercentage: session.zonePercentage,
      deepZonePercentage: session.deepZonePercentage,
      date: format(
        new Date(+session.timestamp),
        FNS_DATE_FORMATS.americanFullDate
      ),
    }));

    return { data: zoneGraphData, legendItems: zoneGraphLegendItems };
  }

  renderZoneGraphAddableContent = ({ addableContentProps } = {}) => {
    const { t } = this.props;

    const zoneGraphDefaultHeader = t('zone_over_graph');
    const zoneGraphDefaultSubHeader = t('zone_graph_default_description');

    const selectedSessions = this.getSelectedSessionsForZoneAndStreakGraphs();
    const shouldRenderGraph = selectedSessions.length > 0;

    const onLegendItemClick = legendItem => {
      reportGeneratorEventLogger.log('on_legend_item_click_in_zone_graph', {
        legendItemName: legendItem,
      });

      this.setState(prvState => {
        // eslint-disable-next-line no-shadow
        const { zoneGraphLegendItems } = prvState;
        const shouldShowLegendItem = !zoneGraphLegendItems[legendItem];
        let shouldShowTrendLine = zoneGraphLegendItems.trend;

        if (
          legendItem === 'zone' &&
          !shouldShowLegendItem &&
          !zoneGraphLegendItems.deepZone
        ) {
          shouldShowTrendLine = false;
        }

        if (
          legendItem === 'deepZone' &&
          !shouldShowLegendItem &&
          !zoneGraphLegendItems.zone
        ) {
          shouldShowTrendLine = false;
        }

        return {
          zoneGraphLegendItems: {
            ...zoneGraphLegendItems,
            [legendItem]: shouldShowLegendItem,
            trend:
              legendItem === 'trend'
                ? shouldShowLegendItem
                : shouldShowTrendLine,
          },
        };
      });
    };

    const getIsAddToReportBtnDisabled = () => {
      const { zoneGraphLegendItems } = this.state;
      const areZoneAndDeepZoneLegendItemsDisabled =
        !zoneGraphLegendItems.zone && !zoneGraphLegendItems.deepZone;
      return areZoneAndDeepZoneLegendItemsDisabled;
    };

    return (
      <div data-test-id="zone-graph-addable-content">
        <AddableContent
          header={zoneGraphDefaultHeader}
          subHeader={zoneGraphDefaultSubHeader}
          content={
            shouldRenderGraph ? (
              <ZoneGraph
                {...this.getZoneGraphProps()}
                onLegendItemClick={onLegendItemClick}
              />
            ) : (
              this.renderNoDataIndicator()
            )
          }
          onAddToReportClick={() => {
            reportGeneratorEventLogger.log('try_adding_zone_graph');

            this.setState({
              addToReportModalState: {
                addableContentHeader: zoneGraphDefaultHeader,
                addableContentSubHeader: zoneGraphDefaultSubHeader,
                addableContentType: zoneGraphType,
                isModalOpen: true,
                renderAddableContent: this.renderZoneGraphAddableContent,
                getIsAddToReportBtnDisabled,
              },
            });
          }}
          shouldShowAddToReport={shouldRenderGraph}
          isAddToReportDisabled={getIsAddToReportBtnDisabled()}
          {...addableContentProps}
        />
      </div>
    );
  };

  renderSessionComparisonGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { t } = this.props;

    const sessionComparisonDefaultHeader = t('session_comparison_graph_header');
    const sessionComparisonDefaultSubHeader = t(
      'session_comparison_graph_sub_header'
    );

    const onSessionSliceChange = event => {
      const newState = { sessionComparisonSlice: +event.target.value };
      reportGeneratorEventLogger.log(
        'change_session_comparison_slice_for_session_comparison_graph',
        newState
      );

      this.setState(newState);
    };

    const onComparisonParameterClick = comparisonParameter => {
      reportGeneratorEventLogger.log(
        'toggling_comparison_parameter_in_session_comparison_graph',
        { comparisonParameter }
      );

      this.setState(prvState => {
        const newSessionComparisonParameters = cloneDeep(
          prvState.sessionComparisonParameters
        );

        newSessionComparisonParameters[comparisonParameter] = !prvState
          .sessionComparisonParameters[comparisonParameter];

        return {
          sessionComparisonParameters: newSessionComparisonParameters,
        };
      });
    };

    const selectedSessions = this.getSelectedSessions();
    const sessionComparisonGraphProps = this.getSessionComparisonGraphProps();
    const shouldRenderGraph =
      selectedSessions.length >= minimumNumberOfSessionsForComparison;

    const getIsAddToReportBtnDisabled = () => {
      const { comparisonParameters } = this.getSessionComparisonGraphProps();
      const areAllComparisonParametersDisabled =
        Object.entries(comparisonParameters).filter(arr => arr[1]).length === 0;
      return areAllComparisonParametersDisabled;
    };

    return (
      <AddableContent
        header={sessionComparisonDefaultHeader}
        subHeader={sessionComparisonDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <PercentageSessionComparisonGraph
              onSessionSliceChange={onSessionSliceChange}
              onComparisonParameterClick={onComparisonParameterClick}
              {...sessionComparisonGraphProps}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log('try_adding_session_comparison_graph');
          this.setState({
            addToReportModalState: {
              addableContentHeader: sessionComparisonDefaultHeader,
              addableContentSubHeader: sessionComparisonDefaultSubHeader,
              addableContentType: sessionComparisonGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderSessionComparisonGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderFrequenciesSessionComparisonGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { t } = this.props;

    const selectedSessions = this.getSelectedSessions();

    const frequenciesSessionComparisonGraphDefaultHeader = t(
      'frequencies_session_comparison'
    );
    const frequenciesSessionComparisonGraphDefaultSubHeader = t(
      'frequencies_session_comparison_description'
    );

    const onComparisonParameterClick = comparisonParameter => {
      reportGeneratorEventLogger.log(
        'toggling_comparison_parameter_in_frequencies_session_comparison_graph',
        { comparisonParameter }
      );

      this.setState(prvState => {
        const newFrequenciesSessionComparisonParameters = cloneDeep(
          prvState.frequenciesSessionComparisonParameters
        );

        newFrequenciesSessionComparisonParameters[
          comparisonParameter
        ] = !prvState.frequenciesSessionComparisonParameters[
          comparisonParameter
        ];

        return {
          frequenciesSessionComparisonParameters: newFrequenciesSessionComparisonParameters,
        };
      });
    };

    const onSessionSliceChange = event => {
      const newState = {
        frequenciesSessionComparisonSlice: +event.target.value,
      };

      reportGeneratorEventLogger.log(
        'change_session_comparison_slice_for_frequencies_session_comparison_graph',
        newState
      );

      this.setState(newState);
    };

    const onAggregationMethodChange = event => {
      const newState = {
        frequenciesSessionComparisonAggregationMethod: event.target.value,
      };

      reportGeneratorEventLogger.log(
        'change_aggregation_method_for_frequencies_session_comparison_graph',
        newState
      );

      this.setState(newState);
    };

    const frequenciesSessionComparisonGraphProps = this.getFrequenciesSessionComparisonGraphProps();

    const shouldRenderGraph =
      selectedSessions.length >= minimumNumberOfSessionsForComparison;

    const getIsAddToReportBtnDisabled = () => {
      const {
        comparisonParameters,
      } = this.getFrequenciesSessionComparisonGraphProps();
      const areAllComparisonParametersDisabled =
        Object.entries(comparisonParameters).filter(arr => arr[1]).length === 0;
      return areAllComparisonParametersDisabled;
    };

    return (
      <AddableContent
        header={frequenciesSessionComparisonGraphDefaultHeader}
        subHeader={frequenciesSessionComparisonGraphDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <UVSessionComparisonGraph
              {...{
                onComparisonParameterClick,
                onSessionSliceChange,
                onAggregationMethodChange,
              }}
              {...frequenciesSessionComparisonGraphProps}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log(
            'try_adding_frequencies_session_comparison_graph'
          );

          this.setState({
            addToReportModalState: {
              addableContentHeader: frequenciesSessionComparisonGraphDefaultHeader,
              addableContentSubHeader: frequenciesSessionComparisonGraphDefaultSubHeader,
              addableContentType: frequenciesSessionComparisonGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderFrequenciesSessionComparisonGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderStreakSessionComparisonGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { t } = this.props;

    const selectedSessions = this.getSelectedSessions();

    const streakSessionComparisonGraphDefaultHeader = t(
      'streak_session_comparison'
    );
    const streakSessionComparisonGraphDefaultSubHeader = t(
      'streak_session_comparison_description'
    );

    const onComparisonParameterClick = comparisonParameter => {
      reportGeneratorEventLogger.log(
        'toggling_comparison_parameter_in_streak_session_comparison_graph',
        { comparisonParameter }
      );

      this.setState(prvState => {
        const newStreakSessionComparisonParameters = cloneDeep(
          prvState.streakSessionComparisonParameters
        );

        newStreakSessionComparisonParameters[comparisonParameter] = !prvState
          .streakSessionComparisonParameters[comparisonParameter];

        return {
          streakSessionComparisonParameters: newStreakSessionComparisonParameters,
        };
      });
    };

    const onSessionSliceChange = event => {
      const newState = { streakSessionComparisonSlice: +event.target.value };
      reportGeneratorEventLogger.log(
        'change_session_comparison_slice_for_streak_session_comparison_graph',
        newState
      );

      this.setState(newState);
    };

    const streakSessionComparisonGraphProps = this.getStreakSessionComparisonGraphProps();

    const shouldRenderGraph =
      selectedSessions.length >= minimumNumberOfSessionsForComparison;

    const getIsAddToReportBtnDisabled = () => {
      const {
        comparisonParameters,
      } = this.getStreakSessionComparisonGraphProps();
      const areAllComparisonParametersDisabled =
        Object.entries(comparisonParameters).filter(arr => arr[1]).length === 0;
      return areAllComparisonParametersDisabled;
    };

    return (
      <AddableContent
        header={streakSessionComparisonGraphDefaultHeader}
        subHeader={streakSessionComparisonGraphDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <SecondsSessionComparisonGraph
              onComparisonParameterClick={onComparisonParameterClick}
              onSessionSliceChange={onSessionSliceChange}
              {...streakSessionComparisonGraphProps}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log(
            'try_adding_streak_session_comparison_graph'
          );

          this.setState({
            addToReportModalState: {
              addableContentHeader: streakSessionComparisonGraphDefaultHeader,
              addableContentSubHeader: streakSessionComparisonGraphDefaultSubHeader,
              addableContentType: streakSessionComparisonGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderStreakSessionComparisonGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderAmplitudeAndNoiseGraphAddableContent = ({
    addableContentProps = {},
    graphProps = {},
  } = {}) => {
    const { t } = this.props;

    const selectedSessions = this.getSelectedSessions();

    const amplitudeAndNoiseGraphDefaultHeader = t(
      'session_average_amplitude_and_noise'
    );
    const amplitudeAndNoiseGraphDefaultSubHeader = t(
      'session_average_amplitude_and_noise_description'
    );

    const { amplitudeAndNoiseGraphLegendData } = this.state;

    const onLegendItemClick = legendItem => {
      reportGeneratorEventLogger.log(
        'on_legend_item_click_in_amplitude_and_noise_graph',
        { legendItemName: legendItem }
      );

      this.setState(prvState => {
        const shouldShowLegendItem = !prvState.amplitudeAndNoiseGraphLegendData[
          legendItem
        ];

        return {
          amplitudeAndNoiseGraphLegendData: {
            ...amplitudeAndNoiseGraphLegendData,
            [legendItem]: shouldShowLegendItem,
          },
        };
      });
    };

    const onTrendLinesEnabledChange = event => {
      const { checked } = event.target;

      reportGeneratorEventLogger.log(
        'toggling_trend_lines_in_amplitude_and_noise_graph',
        { checked }
      );

      this.setState(prvState => ({
        areAmplitudeAndNoiseGraphTrendLinesEnabled: checked,
        amplitudeAndNoiseGraphLegendData: mapValues(
          prvState.amplitudeAndNoiseGraphLegendData,
          (value, key) =>
            key.startsWith('trend-')
              ? checked &&
                prvState.amplitudeAndNoiseGraphLegendData[
                  key.replace('trend-', '')
                ]
              : value
        ),
      }));
    };

    const amplitudeAndNoiseGraphProps = this.getAmplitudeAndNoiseGraphProps();

    const shouldRenderGraph = selectedSessions.length > 0;

    const getIsAddToReportBtnDisabled = () => {
      const { legendItems } = this.getAmplitudeAndNoiseGraphProps();
      const areAllLegendItemsDisabled =
        Object.entries(legendItems).filter(legendItemArr => legendItemArr[1])
          .length === 0;
      return areAllLegendItemsDisabled;
    };

    return (
      <AddableContent
        header={amplitudeAndNoiseGraphDefaultHeader}
        subHeader={amplitudeAndNoiseGraphDefaultSubHeader}
        content={
          shouldRenderGraph ? (
            <AmplitudeAndNoiseGraph
              onLegendItemClick={onLegendItemClick}
              onTrendLinesEnabledChange={onTrendLinesEnabledChange}
              {...amplitudeAndNoiseGraphProps}
              {...graphProps}
            />
          ) : (
            this.renderNoDataIndicator()
          )
        }
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log(
            'try_adding_amplitude_and_noise_graph'
          );

          this.setState({
            addToReportModalState: {
              addableContentHeader: amplitudeAndNoiseGraphDefaultHeader,
              addableContentSubHeader: amplitudeAndNoiseGraphDefaultSubHeader,
              addableContentType: amplitudeAndNoiseGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAmplitudeAndNoiseGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderInDepthSessionAnalysisGraphAddableContent = ({
    addableContentProps = {},
    graphProps = {},
  } = {}) => {
    const inDepthSessionAnalysisGraphDefaultHeader =
      'In-Depth Session Analysis';
    const inDepthSessionAnalysisGraphDefaultSubHeader =
      'See the Average Frequencies and Thresholds per session';

    const inDepthSessionAnalysisGraphProps = this.getInDepthSessionAnalysisGraphProps();

    const { shouldRenderGraph } = inDepthSessionAnalysisGraphProps;

    const getIsAddToReportBtnDisabled = () => {
      return this.state.isLoadingCurrentSesion;
    };

    const onSelectedSessionChange = async e => {
      const sessionKey = e.target.value;
      this.setState({ isLoadingCurrentSesion: true });
      try {
        // eslint-disable-next-line no-undef
        const data = await fireFunctionPost('sessionInfo', {
          sessionId: sessionKey,
        });
        this.setState({
          selectedInDepthSession: sessionKey,
          currentSessionData: data,
          isLoadingCurrentSesion: false,
        });
      } catch (error) {
        this.setState({ isLoadingCurrentSesion: false });
        showNotification('danger', 'Field to load session');
      }
    };

    const onToggleFrequencyAmplitudes = e => {
      this.setState({ toggleFrequencyAmplitudes: e.target.checked });
    };

    const onToggleThresholds = e => {
      this.setState({ toggleThresholds: e.target.checked });
    };

    return (
      <AddableContent
        header={inDepthSessionAnalysisGraphDefaultHeader}
        subHeader={inDepthSessionAnalysisGraphDefaultSubHeader}
        content={
          <InDepthSessionAnalysisGraph
            onSelectedSessionChange={onSelectedSessionChange}
            onToggleFrequencyAmplitudes={onToggleFrequencyAmplitudes}
            onToggleThresholds={onToggleThresholds}
            {...inDepthSessionAnalysisGraphProps}
            {...graphProps}
            renderNoDataIndicator={
              this.renderNoDataIndicatorForInDepthSessionAnalysis
            }
            shouldRenderGraph={shouldRenderGraph}
            isLoadingCurrentSesion={this.state.isLoadingCurrentSesion}
          />
        }
        onAddToReportClick={() => {
          reportGeneratorEventLogger.log(
            'try_adding_amplitude_and_noise_graph'
          );

          this.setState({
            addToReportModalState: {
              addableContentHeader: inDepthSessionAnalysisGraphDefaultHeader,
              addableContentSubHeader: inDepthSessionAnalysisGraphDefaultSubHeader,
              addableContentType: inDepthSessionAnalysisGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderInDepthSessionAnalysisGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={!!shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  getAverageAndMaxStreakGraphProps() {
    const { averageAndMaxStreakGraphLegendItems } = this.state;
    const selectedSessions = this.getSelectedSessionsForZoneAndStreakGraphs();

    const averageAndMaxStreakGraphData = selectedSessions.map(session => ({
      maxStreakTime: session.maxStreak,
      averageStreakTime: session.medianStreak,
      date: format(
        new Date(+session.timestamp),
        FNS_DATE_FORMATS.americanFullDate
      ),
    }));

    return {
      data: averageAndMaxStreakGraphData,
      legendItems: averageAndMaxStreakGraphLegendItems,
    };
  }

  renderAverageAndMaxStreakGraphAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { t } = this.props;

    const selectedSessions = this.getSelectedSessionsForZoneAndStreakGraphs();

    const averageAndMaxStreakDefaultHeader = t(
      'session_average_and_max_streak'
    );
    const averageAndMaxStreakDefaultSubHeader = t(
      'session_average_and_max_streak_description'
    );

    const averageAndMaxStreakGraphProps = this.getAverageAndMaxStreakGraphProps();
    const shouldRenderGraph = selectedSessions.length > 0;

    const onLegendItemClick = legendItem => {
      reportGeneratorEventLogger.log(
        'on_legend_item_click_in_average_and_max_streak_graph',
        { legendItemName: legendItem }
      );

      this.setState(prvState => {
        // eslint-disable-next-line no-shadow
        const { averageAndMaxStreakGraphLegendItems } = prvState;

        const shouldShowLegendItem = !averageAndMaxStreakGraphLegendItems[
          legendItem
        ];

        return {
          averageAndMaxStreakGraphLegendItems: {
            ...averageAndMaxStreakGraphLegendItems,
            [legendItem]: shouldShowLegendItem,
          },
        };
      });
    };

    const getIsAddToReportBtnDisabled = () => {
      const { averageAndMaxStreakGraphLegendItems } = this.state;
      const areAllLegendItemsDisabled =
        Object.entries(averageAndMaxStreakGraphLegendItems).filter(
          // eslint-disable-next-line no-unused-vars
          ([legendItem, isLegendItemEnabled]) => isLegendItemEnabled
        ).length === 0;
      return areAllLegendItemsDisabled;
    };

    return (
      <div data-test-id="average-and-max-streak-graph-addable-content">
        <AddableContent
          header={averageAndMaxStreakDefaultHeader}
          subHeader={averageAndMaxStreakDefaultSubHeader}
          content={
            shouldRenderGraph ? (
              <AverageAndMaxStreakGraph
                {...averageAndMaxStreakGraphProps}
                onLegendItemClick={onLegendItemClick}
              />
            ) : (
              this.renderNoDataIndicator()
            )
          }
          onAddToReportClick={() => {
            reportGeneratorEventLogger.log(
              'try_adding_average_and_max_streak_graph'
            );

            this.setState({
              addToReportModalState: {
                addableContentHeader: averageAndMaxStreakDefaultHeader,
                addableContentSubHeader: averageAndMaxStreakDefaultSubHeader,
                addableContentType: averageAndMaxStreakGraphType,
                isModalOpen: true,
                renderAddableContent: this
                  .renderAverageAndMaxStreakGraphAddableContent,
                getIsAddToReportBtnDisabled,
              },
            });
          }}
          shouldShowAddToReport={shouldRenderGraph}
          isAddToReportDisabled={getIsAddToReportBtnDisabled()}
          {...addableContentProps}
        />
      </div>
    );
  };

  getAssessmentSymptomTrackingAnswers = () => {
    const { allQuestionnaires } = this.props;
    const {
      symptomsTrackingAnswers,
      assessmentResultsWithDeletedAssessments,
    } = this.props;

    const symptomTrackingAnswersFromAssessments = pickBy(
      symptomsTrackingAnswers,
      answer => answer.assessmentId && answer.isReady
    );

    const assessmentSymptomTrackingAnswers = transform(
      symptomTrackingAnswersFromAssessments,
      (acc, value) => {
        acc[value.assessmentId] = value;
        acc[value.assessmentId].standardizedTotalScores = pickBy(
          value.totalScores,
          (val, questionnaireId) =>
            allQuestionnaires[questionnaireId].isStandardized
        );
      }
    );

    const filteredAssessmentSymptomTrackingAnswers = {};

    Object.keys(assessmentSymptomTrackingAnswers).forEach(assessmentId => {
      let shouldFilterOut = true;
      Object.keys(
        assessmentSymptomTrackingAnswers[assessmentId].questions
      ).forEach(questionId => {
        if (
          !allQuestionnaires[
            assessmentSymptomTrackingAnswers[assessmentId].questions[questionId]
              .questionnaireId
          ].hideResult &&
          !assessmentResultsWithDeletedAssessments[assessmentId].isDeleted
        ) {
          shouldFilterOut = false;
        }
      });
      if (!shouldFilterOut) {
        filteredAssessmentSymptomTrackingAnswers[assessmentId] =
          assessmentSymptomTrackingAnswers[assessmentId];
      }
    });

    return filteredAssessmentSymptomTrackingAnswers;
  };

  getAssessmentQuestionnaireResultsGraphProps = () => {
    const {
      questionnaireResultsSelectedAssessmentIds,
      questionnaireResultsSelectedQuestionnaires,
    } = this.state;
    const { allQuestionnaires, answerScales } = this.props;

    const [
      preAssessment,
      postAssessment,
    ] = questionnaireResultsSelectedAssessmentIds;

    const symptomTrackingAnswersFromAssessments = this.getAssessmentSymptomTrackingAnswers();

    const preAssessmentQuestionnaireResults =
      symptomTrackingAnswersFromAssessments &&
      symptomTrackingAnswersFromAssessments[
        questionnaireResultsSelectedAssessmentIds[0]
      ];
    const postAssessmentQuestionnaireResults =
      symptomTrackingAnswersFromAssessments &&
      symptomTrackingAnswersFromAssessments[
        questionnaireResultsSelectedAssessmentIds[1]
      ];

    const preQuestionnairesKeys = Object.keys(
      preAssessmentQuestionnaireResults.standardizedTotalScores
    );
    const postQuestionnairesKeys = Object.keys(
      postAssessmentQuestionnaireResults.standardizedTotalScores
    );

    const commonTotalScoreKeysBetweenPreAndPost = intersection(
      preQuestionnairesKeys,
      postQuestionnairesKeys
    );

    const commonPreAssessmentQuestionnaireResults = {
      ...preAssessmentQuestionnaireResults,
      // standardizedTotalScores: pick(
      //   preAssessmentQuestionnaireResults.standardizedTotalScores,
      //   commonTotalScoreKeysBetweenPreAndPost
      // ),
    };
    const commonPostAssessmentQuestionnaireResults = {
      ...postAssessmentQuestionnaireResults,
      // standardizedTotalScores: pick(
      //   postAssessmentQuestionnaireResults.standardizedTotalScores,
      //   commonTotalScoreKeysBetweenPreAndPost
      // ),
    };

    const isSomeDataHidden =
      commonTotalScoreKeysBetweenPreAndPost.length !==
        preQuestionnairesKeys.length ||
      commonTotalScoreKeysBetweenPreAndPost.length !==
        postQuestionnairesKeys.length;

    const allQuestionnairesOptions = Object.values(
      pick(
        this.state.allSymptomTrackingQuestionnairesWithoutSubScales,
        commonTotalScoreKeysBetweenPreAndPost
      )
    )
      .map(questionnaire => ({
        value: questionnaire.id,
        label: questionnaire.name,
      }))
      .filter(
        questionnaire => !allQuestionnaires[questionnaire.value].hideResult
      );

    return {
      preAssessment,
      postAssessment,
      preAssessmentTimestamp:
        symptomTrackingAnswersFromAssessments[preAssessment].timestamp,
      postAssessmentTimestamp:
        symptomTrackingAnswersFromAssessments[postAssessment].timestamp,
      isSomeDataHidden,
      questionnaireResults: commonPreAssessmentQuestionnaireResults,
      postQuestionnaireResults: commonPostAssessmentQuestionnaireResults,
      selectedQuestionnaires: intersection(
        questionnaireResultsSelectedQuestionnaires,
        commonTotalScoreKeysBetweenPreAndPost
      ).filter(id => !allQuestionnaires[id].hideResult),
      questionnaires: pick(
        allQuestionnaires,
        intersection(
          commonTotalScoreKeysBetweenPreAndPost,
          questionnaireResultsSelectedQuestionnaires
        )
      ),
      allQuestionnairesOptions,
      answerScales,
      allQuestionnaires,
    };
  };

  getPerformanceGraphProps = () => {
    const { allQuestionnaires } = this.props;
    const {
      CPTResults,
      swingleChecks,
      swingleResults,
      performanceGraphSelectedAssessmentIds,
      assessmentResults,
    } = this.state;

    const [
      preAssessment,
      postAssessment,
    ] = performanceGraphSelectedAssessmentIds;

    const preAssessmentCPTResults =
      CPTResults && CPTResults[performanceGraphSelectedAssessmentIds[0]];
    const postAssessmentCPTResults =
      CPTResults && CPTResults[performanceGraphSelectedAssessmentIds[1]];

    const symptomTrackingAnswersFromAssessments = this.getAssessmentSymptomTrackingAnswers();
    const allQuestionnaireResultsFromAssessments = Object.values(
      symptomTrackingAnswersFromAssessments
    );
    const preQuestionnaireResults = allQuestionnaireResultsFromAssessments.find(
      answers => answers.assessmentId === preAssessment
    );
    const postQuestionnaireResults = allQuestionnaireResultsFromAssessments.find(
      answers => answers.assessmentId === postAssessment
    );

    const preQuestionnairesScoreData =
      preQuestionnaireResults &&
      !isEmpty(preQuestionnaireResults.standardizedTotalScores)
        ? {
            'Questionnaire Results': getQuestionnairesScorePercentage({
              questionnaires: allQuestionnaires,
              questionnaireResults: preQuestionnaireResults,
            }),
          }
        : {};

    const postQuestionnairesScoreData =
      postQuestionnaireResults &&
      !isEmpty(postQuestionnaireResults.standardizedTotalScores)
        ? {
            'Questionnaire Results': getQuestionnairesScorePercentage({
              questionnaires: allQuestionnaires,
              questionnaireResults: postQuestionnaireResults,
            }),
          }
        : {};

    const preAssessmentCategoryScores = {
      ...preQuestionnairesScoreData,
      ...(preAssessmentCPTResults
        ? {
            'Focus Index': preAssessmentCPTResults.focusIndex,
            'Timing Index': preAssessmentCPTResults.timingIndex,
          }
        : {}),
      ...getSwingleCategoriesScores({
        checks: swingleChecks,
        results: swingleResults[performanceGraphSelectedAssessmentIds[0]],
      }),
    };

    const postAssessmentCategoryScores = {
      ...postQuestionnairesScoreData,
      ...(postAssessmentCPTResults
        ? {
            'Focus Index': postAssessmentCPTResults.focusIndex,
            'Timing Index': postAssessmentCPTResults.timingIndex,
          }
        : {}),
      ...getSwingleCategoriesScores({
        checks: swingleChecks,
        results: swingleResults[performanceGraphSelectedAssessmentIds[1]],
      }),
    };

    const preAssessmentCategoryScoresKeys = Object.keys(
      preAssessmentCategoryScores
    );
    const postAssessmentCategoryScoresKeys = Object.keys(
      postAssessmentCategoryScores
    );
    const commonCategoriesBetweenPreAndPostAssessment = intersection(
      preAssessmentCategoryScoresKeys,
      postAssessmentCategoryScoresKeys
    );

    const isSomeDataHidden =
      commonCategoriesBetweenPreAndPostAssessment.length !==
        preAssessmentCategoryScoresKeys.length ||
      commonCategoriesBetweenPreAndPostAssessment.length !==
        postAssessmentCategoryScoresKeys.length;

    const performanceGraphData = commonCategoriesBetweenPreAndPostAssessment.map(
      category => ({
        category,
        preScore: preAssessmentCategoryScores[category],
        postScore: postAssessmentCategoryScores[category],
      })
    );

    return {
      data: performanceGraphData,
      preAssessment,
      postAssessment,
      preAssessmentTimestamp: assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp: assessmentResults[postAssessment].timestamp,
      isSomeDataHidden,
    };
  };

  onAssessmentChange = ({
    newAssessment,
    changeType,
    assessmentArrayNameInState,
    onStateUpdated = () => {},
  }) => {
    reportGeneratorEventLogger.log('on_selected_assessment_change', {
      newAssessment,
      changeType,
      graphType: assessmentArrayNameInState,
    });

    this.setState(prvState => {
      const oldAssessmentArrayInState = prvState[assessmentArrayNameInState];
      const newAssessmentArrayInState = [...oldAssessmentArrayInState];
      const index = changeType === 'preAssessment' ? 0 : 1;
      newAssessmentArrayInState[index] = newAssessment;
      return {
        [assessmentArrayNameInState]: newAssessmentArrayInState,
      };
    }, onStateUpdated);
  };

  getInsightsGraphProps = selectedInsightItemId => {
    const { insightItems, insightsConfig } = this.props;

    const insightItem = insightItems[selectedInsightItemId];

    const insightItemDetails = getInsightItemDetails({
      insightItem,
      insightsConfig,
    });

    const {
      graphData,
      date,
      protocolName,
      yAxisDashboard,
      unit,
      isGraphHidden = false,
    } = insightItemDetails;

    return {
      data: graphData,
      date,
      protocolName,
      yAxisLabel: yAxisDashboard,
      unit,
      isGraphHidden,
    };
  };

  // insight tab begins ------
  renderAllInsightsAddableContents = () => {
    const { insightItems, insightsConfig, t } = this.props;

    const insights = Object.values(insightItems)
      .filter(insight => insight.timestamp)
      .sort((insight1, insight2) => insight2.timestamp - insight1.timestamp);

    const insightsGroupedByStartDate = groupBy(insights, insight => {
      return dayJS(insight.timestamp)
        .startOf('month')
        .format(DAY_JS_DATE_FORMATS.american);
    });

    return Object.entries(insightsGroupedByStartDate).length === 0 ? (
      <Paper className={styles.paper}>
        <p className={styles.no_insights_label}>
          {t('insights-empty-description')}
        </p>
      </Paper>
    ) : (
      Object.entries(insightsGroupedByStartDate).map(([startDate, items]) => {
        const endDate = dayJS(startDate, DAY_JS_DATE_FORMATS.american)
          .endOf('month')
          .format(DAY_JS_DATE_FORMATS.american);
        return (
          items.some(item =>
            getInsightItemDetails({
              insightItem: item,
              insightsConfig,
            })
          ) && (
            <div key={startDate}>
              <p className={styles.date_header}>
                {startDate} - {endDate}
              </p>
              <div className={styles.insights}>
                {items.map(insightItem => (
                  <div key={insightItem.id}>
                    {this.renderInsightsAddableContent({
                      addableContentProps: {
                        type: `${insightGraphType}${insightItem.id}`,
                      },
                    })}
                  </div>
                ))}
              </div>
            </div>
          )
        );
      })
    );
  };
  // insights tab ends ------

  renderInsightsAddableContent = ({ addableContentProps } = {}) => {
    const selectedInsightItemId = addableContentProps.type.replace(
      insightGraphType,
      ''
    );

    const { insightItems, insightsConfig } = this.props;
    const insightItem = insightItems[selectedInsightItemId];

    const insightItemDetails = getInsightItemDetails({
      insightItem,
      insightsConfig,
    });

    const shouldRenderGraph = !!insightItemDetails;

    const getIsAddToReportBtnDisabled = () => false;

    const insightGraphDefaultHeader =
      shouldRenderGraph && insightItemDetails.title;
    const insightGraphDefaultSubHeader =
      shouldRenderGraph && insightItemDetails.message;
    return (
      insightItemDetails && (
        <AddableContent
          header={insightGraphDefaultHeader}
          subHeader={insightGraphDefaultSubHeader}
          headerIcon={insightItemDetails.icon}
          headerIconWidth={insightItemDetails.iconWidth}
          headerIconTopMargin={insightItemDetails.iconTopMargin}
          content={
            shouldRenderGraph ? (
              <InsightsBarsGraph
                {...this.getInsightsGraphProps(selectedInsightItemId)}
              />
            ) : (
              this.renderNoDataIndicator()
            )
          }
          onAddToReportClick={() => {
            this.setState({
              addToReportModalState: {
                addableContentHeader: insightGraphDefaultHeader,
                addableContentSubHeader: insightGraphDefaultSubHeader,
                addableContentHeaderIcon: insightItemDetails.icon,
                addableContentType: addableContentProps.type,
                isModalOpen: true,
                renderAddableContent: this.renderInsightsAddableContent,
                getIsAddToReportBtnDisabled,
              },
            });
          }}
          isAddToReportDisabled={getIsAddToReportBtnDisabled()}
          headerErrorMsg="Professional summary card title can not be empty"
          shouldShowAddToReport={shouldRenderGraph}
          isExpandable
          {...addableContentProps}
        />
      )
    );
  };

  getUsedFormatsInTextEditor = editorState => {
    const { blocks, entityMap } = convertToRaw(editorState.getCurrentContent());

    const INLINE_STYLES = new Set(['BOLD', 'ITALIC', 'UNDERLINE']);
    // const BLOCK_TYPES = new Set(['unordered-list', 'ordered-list']);
    const usedFormats = new Set();

    // Check for links: If entityMap has keys, we assume there's a link
    if (Object.keys(entityMap).length > 0) usedFormats.add('link');

    // Flags to stop early when all styles are found
    let foundAllInlines = false;
    let foundAllBlocks = false;

    blocks.forEach(block => {
      if (!(foundAllInlines && foundAllBlocks)) {
        if (!foundAllBlocks) {
          // Add to usedFormats if it's an unordered list
          if (block.type === 'unordered-list-item') {
            usedFormats.add('unordered-list');
          }

          // Add to usedFormats if it's an ordered list
          if (block.type === 'ordered-list-item') {
            usedFormats.add('ordered-list');
          }

          // Check if both block types are found, short-circuit if true
          if (
            usedFormats.has('unordered-list') &&
            usedFormats.has('ordered-list')
          ) {
            foundAllBlocks = true;
          }
        }

        if (!foundAllInlines) {
          block.inlineStyleRanges.forEach(range =>
            usedFormats.add(range.style.toLowerCase())
          );
          foundAllInlines = Array.from(INLINE_STYLES).every(style =>
            usedFormats.has(style.toLowerCase())
          );
        }
      }
    });

    return Array.from(usedFormats);
  };

  extractContent = s => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  getTextEditorCardPreviewProps = () => {
    // note: there is normal props for edit mode and preview props, each with a different func.
    // (getTextEditorCardPreviewProps, getTextEditorCardProps)
    const { textEditorCardContent } = this.state;

    return {
      content: draftToHtml(
        convertToRaw(textEditorCardContent.getCurrentContent())
      ),
    };
  };

  getTextEditorCardProps = () => {
    // note: there is normal props for edit mode and preview props, each with a different func.
    // (getTextEditorCardPreviewProps, getTextEditorCardProps)
    const { textEditorCardContent, addToReportModalState } = this.state;

    return {
      content: textEditorCardContent,
      isModalOpen: addToReportModalState.isModalOpen,
    };
  };

  getFreeTextCardProps = () => {
    const { freeTextCardContent } = this.state;
    return { content: freeTextCardContent };
  };

  // other tab / free text or text editor begins ------
  renderFreeTextCardAddableContent = ({ addableContentProps } = {}) => {
    const freeTextCardHeader = 'Professional summary';

    const getIsAddToReportBtnDisabled = () => {
      const { freeTextCardContent } = this.state;
      return freeTextCardContent.trim('') === '';
    };

    return (
      <AddableContent
        header={freeTextCardHeader}
        content={
          <FreeTextCard
            onChange={event => {
              this.setState({ freeTextCardContent: event.target.value });
            }}
            {...this.getFreeTextCardProps()}
          />
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: freeTextCardHeader,
              addableContentType: freeTextCardType,
              isModalOpen: true,
              renderAddableContent: this.renderFreeTextCardAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        headerErrorMsg="Professional summary card title can not be empty"
        {...addableContentProps}
      />
    );
  };

  renderTextEditorCardAddableContent = ({ addableContentProps } = {}) => {
    const TextEditorCardHeader = 'Professional summary';

    const updateTextEditorCardContentState = editorStateContent => {
      this.setState({ textEditorCardContent: editorStateContent });
    };

    const getIsAddToReportBtnDisabled = () => {
      const { textEditorCardContent } = this.state;

      return !this.extractContent(
        draftToHtml(convertToRaw(textEditorCardContent.getCurrentContent()))
      ).trim(' ');
    };

    return (
      <AddableContent
        header={TextEditorCardHeader}
        content={
          <TextEditorCard
            updateTextEditorCardContentState={updateTextEditorCardContentState}
            {...this.getTextEditorCardProps()}
          />
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: TextEditorCardHeader,
              addableContentType: textEditorCardType,
              isModalOpen: true,
              renderAddableContent: this.renderTextEditorCardAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        headerErrorMsg="Professional summary card title can not be empty"
        {...addableContentProps}
      />
    );
  };
  // other tab ends ------

  renderAssessmentQuestionnaireResultsAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { questionnaireResultsSelectedQuestionnaires } = this.state;
    const questionnaireResultsGraphHeader = 'Symptom Scores';
    const questionnaireResultsGraphSubHeader = 'Summary of symptom scores';

    const getIsAddToReportBtnDisabled = () => {
      return questionnaireResultsSelectedQuestionnaires.length === 0;
    };

    const symptomTrackingAnswersFromAssessments = this.getAssessmentSymptomTrackingAnswers();
    const shouldRenderGraph =
      Object.values(symptomTrackingAnswersFromAssessments).length > 1;

    const assessmentIds = Object.values(
      symptomTrackingAnswersFromAssessments
    ).map(answer => answer.assessmentId);

    const assessmentOptions =
      shouldRenderGraph &&
      this.getAssessmentOptions({
        assessmentIds,
      });

    return (
      <AddableContent
        header={questionnaireResultsGraphHeader}
        subHeader={questionnaireResultsGraphSubHeader}
        content={
          shouldRenderGraph ? (
            <AssessmentQuestionnaireResults
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'questionnaireResultsSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'questionnaireResultsSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              onSelectedQuestionnairesChange={
                this.onQuestionnaireResultsSelectedQuestionnairesChange
              }
              {...this.getAssessmentQuestionnaireResultsGraphProps()}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: questionnaireResultsGraphHeader,
              addableContentSubHeader: questionnaireResultsGraphSubHeader,
              addableContentType: assessmentQuestionnairesResultGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentQuestionnaireResultsAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        {...addableContentProps}
      />
    );
  };

  renderAssessmentPerformanceAddableContent = ({
    addableContentProps,
  } = {}) => {
    const {
      swingleChecks,
      swingleResults,
      performanceGraphSelectedAssessmentIds,
      CPTResults,
    } = this.state;
    const performanceGraphHeader = 'Overall Performance';
    const performanceGraphSubHeader =
      'Brain health profile comprising summary measures from the current assessment';

    const shouldRenderGraph =
      ((!isEmpty(swingleChecks) && !isEmpty(swingleResults)) ||
        !isEmpty(CPTResults)) &&
      performanceGraphSelectedAssessmentIds.length > 1 &&
      this.getPerformanceGraphProps().data.length >=
        minimumNumberOfPointsForPerformanceGraph;

    const assessmentOptions =
      !isEmpty(swingleResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(swingleResults),
      });

    return (
      <AddableContent
        header={performanceGraphHeader}
        subHeader={performanceGraphSubHeader}
        content={
          !isEmpty(swingleResults) ? (
            <AssessmentPerformanceComparisonGraph
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'performanceGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'performanceGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...(shouldRenderGraph ? this.getPerformanceGraphProps() : {})}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: performanceGraphHeader,
              addableContentSubHeader: performanceGraphSubHeader,
              addableContentType: assessmentPerformanceGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentPerformanceAddableContent,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  getDownloadedFileSuffix = () => {
    const { patientFullName } = this.props;
    const nameHyphensSeparated = patientFullName.replaceAll(' ', '-');
    return getDateNameSuffix({ nameHyphensSeparated });
  };

  getAssessmentBrainMapsComparisonGraphProps = () => {
    const {
      frequencyAssessmentBrainMaps,
      brainMapsGraphSelectedAssessmentIds,
      frequencyAssessmentBrainMapsSelectedFrequencies,
      assessmentResults,
      frequenciesToImagesFullNames,
    } = this.state;

    const allFrequencies = Object.keys(
      frequencyAssessmentBrainMapsSelectedFrequencies
    );
    const selectedFrequencies = Object.keys(
      pickBy(frequencyAssessmentBrainMapsSelectedFrequencies, value => value)
    );

    const [preAssessment, postAssessment] = brainMapsGraphSelectedAssessmentIds;

    return {
      frequencyAssessmentBrainMaps,
      allFrequencies,
      selectedFrequencies,
      preAssessment,
      postAssessment,
      preAssessmentTimestamp: assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp: assessmentResults[postAssessment].timestamp,
      // preAssessmentNoise:
      //   assessmentResults[preAssessment].assessment_v2 &&
      //   assessmentResults[preAssessment].assessment_v2.noise,
      // postAssessmentNoise:
      //   assessmentResults[preAssessment].assessment_v2 &&
      //   assessmentResults[postAssessment].assessment_v2.noise,
      frequenciesToImagesFullNames,
    };
  };

  renderAssessmentBrainMapsAddableContent = ({ addableContentProps } = {}) => {
    const brainMapsGraphHeader =
      'Sequential Quantitative Electroencephalogram (sqEEG)';
    const brainMapsGraphSubHeader =
      'Voltage (µV) for each electrode relative to the mean voltage for the normative population in the same age group as of the date of the client’s first assessment. Red represents a higher amplitude than the database; blue represents a lower amplitude than the database. Increments are standardized units with 0 indicating no difference compared to the database.';

    const getAssessmentBrainMapsResults = () => {
      const { assessmentResults } = this.state;
      const assessmentBrainMapsResults = this.getBrainMapsAssessmentResults({
        assessmentResults,
      });
      return assessmentBrainMapsResults;
    };

    const getShouldRenderGraph = () => {
      const {
        brainMapsGraphSelectedAssessmentIds,
        frequencyAssessmentBrainMaps,
        frequencyAssessmentBrainMapsSelectedFrequencies,
      } = this.state;
      const assessmentBrainMapsResults = getAssessmentBrainMapsResults();

      const shouldRenderGraph =
        !isEmpty(assessmentBrainMapsResults) &&
        !isEmpty(frequencyAssessmentBrainMaps) &&
        !isEmpty(frequencyAssessmentBrainMapsSelectedFrequencies) &&
        brainMapsGraphSelectedAssessmentIds.length > 1;
      return shouldRenderGraph;
    };

    const shouldRenderGraph = getShouldRenderGraph();

    const assessmentBrainMapsResults = getAssessmentBrainMapsResults();
    const assessmentOptions =
      !isEmpty(assessmentBrainMapsResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(assessmentBrainMapsResults),
      });

    const getIsAddToReportBtnDisabled = () => {
      const selectedFrequencies = getShouldRenderGraph()
        ? this.getAssessmentBrainMapsComparisonGraphProps().selectedFrequencies
        : [];
      return selectedFrequencies.length === 0;
    };

    return (
      <AddableContent
        header={brainMapsGraphHeader}
        subHeader={brainMapsGraphSubHeader}
        content={
          shouldRenderGraph ? (
            <AssessmentBrainMapsComparisonGraph
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'brainMapsGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'brainMapsGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              onSelectedFrequenciesChange={event => {
                const newSelectedFrequencies = event.target.value;
                reportGeneratorEventLogger.log(
                  'on_assessment_brain_maps_frequencies_to_show_change',
                  { newSelectedFrequencies }
                );
                this.setState(prvState => {
                  const newFrequencyAssessmentBrainMapsSelectedFrequencies = mapValues(
                    prvState.frequencyAssessmentBrainMapsSelectedFrequencies,
                    (value, key) => newSelectedFrequencies.indexOf(key) > -1
                  );
                  return {
                    frequencyAssessmentBrainMapsSelectedFrequencies: newFrequencyAssessmentBrainMapsSelectedFrequencies,
                  };
                });
              }}
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...(shouldRenderGraph
                ? this.getAssessmentBrainMapsComparisonGraphProps()
                : {})}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: brainMapsGraphHeader,
              addableContentSubHeader: brainMapsGraphSubHeader,
              addableContentType: assessmentBrainMapsGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentBrainMapsAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  getAssessmentNoiseComparisonGraphProps = () => {
    const {
      brainMapsGraphSelectedAssessmentIds,
      assessmentResults,
    } = this.state;

    const [preAssessment, postAssessment] = brainMapsGraphSelectedAssessmentIds;

    return {
      preAssessment,
      postAssessment,
      preAssessmentTimestamp:
        assessmentResults[preAssessment] &&
        assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp:
        assessmentResults[postAssessment] &&
        assessmentResults[postAssessment].timestamp,
      preAssessmentNoise:
        (assessmentResults[preAssessment].assessment_v2 &&
          assessmentResults[preAssessment].assessment_v2.normalized_noise) ||
        assessmentResults[preAssessment].assessment_v2.noise,
      postAssessmentNoise:
        (assessmentResults[preAssessment].assessment_v2 &&
          assessmentResults[postAssessment].assessment_v2.normalized_noise) ||
        assessmentResults[postAssessment].assessment_v2.noise,
      isNormalized: !!(
        assessmentResults[preAssessment].assessment_v2 &&
        assessmentResults[preAssessment].assessment_v2.normalized_noise
      ),
    };
  };

  getIsNormalizedNoiseSubHeader = () => {
    const {
      brainMapsGraphSelectedAssessmentIds,
      assessmentResults,
    } = this.state;
    const [preAssessment] = brainMapsGraphSelectedAssessmentIds;
    return (
      assessmentResults[preAssessment] &&
      assessmentResults[preAssessment].assessment_v2 &&
      assessmentResults[preAssessment].assessment_v2.normalized_noise
    );
  };

  renderNoiseGraphAddableContent = ({ addableContentProps } = {}) => {
    const noiseGraphHeader = 'EEG Noise';
    const noiseGraphSubHeader = this.getIsNormalizedNoiseSubHeader()
      ? 'The chart estimates the normalized noise amount for each electrode during the assessment, which is derived by deducting the mean value of noise for each channel.  Assessment results only include data from clean segments of the recordings, and noise is the total portion filtered out. The lower the score, the better.'
      : 'Estimate of the amount of noise for each electrode during the assessment. The results only include data from clean segments of the recordings and the noise percentage shows the total portion that was filtered out.';

    const getAssessmentNoiseResults = () => {
      const { assessmentResults } = this.state;
      const assessmentNoiseResults = this.getNoiseAssessmentResults({
        assessmentResults,
      });
      return assessmentNoiseResults;
    };

    const getShouldRenderGraph = () => {
      const assessmentNoiseResults = getAssessmentNoiseResults();
      const shouldRenderGraph = Object.keys(assessmentNoiseResults).length > 1;
      return shouldRenderGraph;
    };

    const shouldRenderGraph = getShouldRenderGraph();

    const assessmentNoiseResults = getAssessmentNoiseResults();
    const assessmentOptions =
      !isEmpty(assessmentNoiseResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(assessmentNoiseResults),
      });

    const getIsAddToReportBtnDisabled = () => {
      return !getShouldRenderGraph();
    };

    return (
      <AddableContent
        header={noiseGraphHeader}
        subHeader={noiseGraphSubHeader}
        content={
          shouldRenderGraph ? (
            <AssessmentNoiseComparisonGraph
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'brainMapsGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'brainMapsGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              // onSelectedFrequenciesChange={event => {
              //   const newSelectedFrequencies = event.target.value;
              //   reportGeneratorEventLogger.log(
              //     'on_assessment_brain_maps_frequencies_to_show_change',
              //     { newSelectedFrequencies }
              //   );
              //   this.setState(prvState => {
              //     const newFrequencyAssessmentBrainMapsSelectedFrequencies = mapValues(
              //       prvState.frequencyAssessmentBrainMapsSelectedFrequencies,
              //       (value, key) => newSelectedFrequencies.indexOf(key) > -1
              //     );
              //     return {
              //       frequencyAssessmentBrainMapsSelectedFrequencies: newFrequencyAssessmentBrainMapsSelectedFrequencies,
              //     };
              //   });
              // }}
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...(shouldRenderGraph
                ? this.getAssessmentNoiseComparisonGraphProps()
                : {})}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: noiseGraphHeader,
              addableContentSubHeader: noiseGraphSubHeader,
              addableContentType: assessmentNoiseGraphType,
              isModalOpen: true,
              renderAddableContent: this.renderNoiseGraphAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  getAssessmentAmplitudePerFrequencyComparisonGraphProps = () => {
    const {
      frequencyAmplitudesAssessment,
      amplitudePerFrequencyGraphSelectedAssessmentIds,
      amplitudePerFrequencyGraphSelectedChannels,
      frequencyAmplitudesAssessmentLegendItems,
      frequencyAmplitudesAssessmentValuesType,
      assessmentResults,
    } = this.state;

    const [
      preAssessment,
      postAssessment,
    ] = amplitudePerFrequencyGraphSelectedAssessmentIds;

    const preAssessmentStageType = assessmentResults[preAssessment].stagesType;
    const postAssessmentStageType =
      assessmentResults[postAssessment].stagesType;

    const preAssessmentAmplitudesByChannel =
      frequencyAmplitudesAssessment[preAssessment];
    const postAssessmentAmplitudesByChannel =
      frequencyAmplitudesAssessment[postAssessment];

    const preAssessmentAmplitudesByChannelKeys = intersection(
      Object.keys(preAssessmentAmplitudesByChannel),
      preAssessmentStageType === 'headsetStages'
        ? channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment
        : channelsRelevantToAmplitudesDistrubutionGraph
    );
    const postAssessmentAmplitudesByChannelKeys = intersection(
      Object.keys(postAssessmentAmplitudesByChannel),
      postAssessmentStageType === 'headsetStages'
        ? channelsRelevantToAmplitudesDistrubutionGraphHeadsetAssessment
        : channelsRelevantToAmplitudesDistrubutionGraph
    );
    const commonChannelsBetweenPreAndPostAssessmentAmplitudes = intersection(
      preAssessmentAmplitudesByChannelKeys,
      postAssessmentAmplitudesByChannelKeys
    );
    const isSomeDataHidden =
      preAssessmentStageType !== postAssessmentStageType ||
      commonChannelsBetweenPreAndPostAssessmentAmplitudes.length !==
        preAssessmentAmplitudesByChannelKeys.length ||
      commonChannelsBetweenPreAndPostAssessmentAmplitudes.length !==
        postAssessmentAmplitudesByChannelKeys.length;

    const getAmplitudesByChannelReducerToData = ({ statePrefix }) => (
      acc,
      [frequency, amplitudesByValueType]
    ) => {
      const amplitudes =
        amplitudesByValueType[frequencyAmplitudesAssessmentValuesType];
      const processedAmplitudes = transform(
        amplitudes,
        (result, amplitude, state) => {
          const newAmplitude =
            frequencyAmplitudesAssessmentValuesType ===
            amplitudesPerFrequencyValueTypes.relative
              ? amplitude * 100
              : amplitude;
          // eslint-disable-next-line no-param-reassign
          result[
            `${statePrefix}${capitalizeFirstLetter(state)}`
          ] = newAmplitude;
          return result;
        },
        {}
      );
      const dataUnit = {
        name: frequency,
        ...processedAmplitudes,
      };
      acc.push(dataUnit);
      return acc;
    };

    const data = {};
    Object.entries(
      pick(
        preAssessmentAmplitudesByChannel,
        commonChannelsBetweenPreAndPostAssessmentAmplitudes
      )
    ).forEach(([channel, amplitudesByChannel]) => {
      data[channel] = Object.entries(amplitudesByChannel).reduce(
        getAmplitudesByChannelReducerToData({ statePrefix: 'pre' }),
        []
      );
    });
    Object.entries(
      pick(
        postAssessmentAmplitudesByChannel,
        commonChannelsBetweenPreAndPostAssessmentAmplitudes
      )
    ).forEach(([channel, amplitudesByChannel]) => {
      const postAssessmentAmplitudesData = Object.entries(
        amplitudesByChannel
      ).reduce(
        getAmplitudesByChannelReducerToData({ statePrefix: 'post' }),
        []
      );
      data[channel] = data[channel].map((dataUnit, index) => ({
        ...dataUnit,
        ...postAssessmentAmplitudesData[index],
      }));
    });

    const selectedChannels = Object.keys(
      pickBy(
        amplitudePerFrequencyGraphSelectedChannels,
        (value, channel) =>
          value &&
          commonChannelsBetweenPreAndPostAssessmentAmplitudes.includes(channel)
      )
    );

    const sortedData = Object.keys(data)
      .sort(
        (channel1, channel2) =>
          channelsRelevantToAmplitudesDistrubutionGraph.indexOf(channel1) -
          channelsRelevantToAmplitudesDistrubutionGraph.indexOf(channel2)
      )
      .reduce((acc, channel) => {
        acc[channel] = data[channel].sort(
          (amplitudesPerChannel1, amplitudesPerChannel2) => {
            return (
              sortedFrequencies.indexOf(amplitudesPerChannel1.name) -
              sortedFrequencies.indexOf(amplitudesPerChannel2.name)
            );
          }
        );
        return acc;
      }, {});

    const allChannels = intersection(
      Object.keys(amplitudePerFrequencyGraphSelectedChannels),
      commonChannelsBetweenPreAndPostAssessmentAmplitudes
    ).map(channel => ({
      label: CHANNEL_IDS_TO_NAMES[channel],
      value: channel,
    }));

    const unit =
      frequencyAmplitudesAssessmentValuesType ===
      amplitudesPerFrequencyValueTypes.relative
        ? '%'
        : '(µV)';
    const dataMax =
      frequencyAmplitudesAssessmentValuesType ===
      amplitudesPerFrequencyValueTypes.relative
        ? 60
        : 5;
    const dataMin =
      frequencyAmplitudesAssessmentValuesType ===
      amplitudesPerFrequencyValueTypes.relative
        ? 'dataMin'
        : 0;

    return {
      data: sortedData,
      unit,
      dataMax,
      dataMin,
      legendItems: frequencyAmplitudesAssessmentLegendItems,
      allValueTypes: frequencyAmplitudesAssessmentValueTypesOptions,
      valuesType: frequencyAmplitudesAssessmentValuesType,
      valuesTypeFormattedText:
        frequencyAmplitudesAssessmentValuesType ===
        amplitudesPerFrequencyValueTypes.relative
          ? 'Relative Amplitude'
          : 'Absolute Amplitude',
      preAssessment,
      postAssessment,
      selectedChannels,
      allChannels,
      isSomeDataHidden,
      formattedChannelNames: CHANNEL_IDS_TO_NAMES,
      preAssessmentTimestamp: assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp: assessmentResults[postAssessment].timestamp,
    };
  };

  renderAssessmentAmplitudesPerFrequencyAddableContent = ({
    addableContentProps,
  } = {}) => {
    const {
      assessmentResults,
      amplitudePerFrequencyGraphSelectedAssessmentIds,
      frequencyAmplitudesAssessment,
      amplitudePerFrequencyGraphSelectedChannels,
      frequencyAmplitudesAssessmentLegendItems,
    } = this.state;

    const amplitudePerFrequencyGraphHeader = 'EEG Distribution';
    const amplitudePerFrequencyGraphSubHeader =
      'Distribution of EEG amplitudes across frequency bands per electrode';

    const eegAssessmentResults = this.getEEGAssessmentResults({
      assessmentResults,
    });

    const shouldRenderGraph =
      !isEmpty(eegAssessmentResults) &&
      !isEmpty(frequencyAmplitudesAssessment) &&
      !isEmpty(amplitudePerFrequencyGraphSelectedChannels) &&
      !isEmpty(frequencyAmplitudesAssessmentLegendItems) &&
      amplitudePerFrequencyGraphSelectedAssessmentIds.length > 1;

    const getIsAddToReportBtnDisabled = () => {
      const {
        // eslint-disable-next-line no-shadow
        frequencyAmplitudesAssessmentLegendItems,
      } = this.state;

      const { selectedChannels } =
        shouldRenderGraph &&
        this.getAssessmentAmplitudePerFrequencyComparisonGraphProps();

      const areAllLegendItemsDisabled = Object.values(
        pickBy(frequencyAmplitudesAssessmentLegendItems, (value, channel) =>
          selectedChannels.includes(channel)
        )
      ).every(channelLegendItems =>
        Object.values(channelLegendItems).every(value => value === false)
      );

      const areAllChannelsDeselected = Object.values(selectedChannels).every(
        value => value === false
      );

      return (
        areAllLegendItemsDisabled ||
        areAllChannelsDeselected ||
        (shouldRenderGraph &&
          isEmpty(
            this.getAssessmentAmplitudePerFrequencyComparisonGraphProps().data
          ))
      );
    };

    const assessmentOptions =
      !isEmpty(eegAssessmentResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(eegAssessmentResults),
      });

    const verticalMoreMenuItems = [
      {
        text: 'Export CSV',
        onClick: () => {
          const {
            data,
          } = this.getAssessmentAmplitudePerFrequencyComparisonGraphProps();

          const frequencies = Object.values(data)[0].map(
            amplitudesPerFrequency => amplitudesPerFrequency.name
          );

          const header = ['Region', 'State', ...frequencies];
          const dataArray = Object.entries(data).reduce(
            (acc, [channel, amplitudesPerChannel]) => {
              Object.values(amplitudesStates).forEach(state => {
                const amplitudesByState = amplitudesPerChannel
                  .filter(amplitude => has(amplitude, state))
                  .map(amplitudes => {
                    return setFixedDecimalsIfNeeded({
                      number: amplitudes[state],
                    });
                  });

                if (amplitudesByState.length > 0) {
                  acc.push([
                    CHANNEL_IDS_TO_NAMES[channel],
                    amplitudesStatesFormattedNames[state],
                    ...amplitudesByState,
                  ]);
                }
              });

              return acc;
            },
            []
          );

          const csv = convertArrayToCSV(dataArray, { header });

          downloadURIContent({
            content: `data:text/csv;charset=utf-8,${csv}`,
            name: `eeg_${this.getDownloadedFileSuffix()}.csv`,
          });
        },
        key: 'csv',
      },
    ];
    const headerRightContent = (
      <div>
        <VerticalMoreMenu menuItems={verticalMoreMenuItems} />
      </div>
    );

    return (
      <AddableContent
        header={amplitudePerFrequencyGraphHeader}
        headerRightContent={headerRightContent}
        subHeader={amplitudePerFrequencyGraphSubHeader}
        content={
          !isEmpty(eegAssessmentResults) &&
          shouldRenderGraph &&
          this.getAssessmentAmplitudePerFrequencyComparisonGraphProps()
            .allChannels.length ? (
            <AssessmentAmplitudePerFrequencyComparisonGraph
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'amplitudePerFrequencyGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'amplitudePerFrequencyGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              onSelectedChannelsChange={event => {
                const selectedChannels = event.target.value;
                reportGeneratorEventLogger.log(
                  'on_assessments_amplitudes_per_frequency_channels_change',
                  { selectedChannels }
                );
                const newAmplitudePerFrequencyGraphSelectedChannels = mapValues(
                  amplitudePerFrequencyGraphSelectedChannels,
                  (value, key) => selectedChannels.indexOf(key) > -1
                );
                this.setState({
                  amplitudePerFrequencyGraphSelectedChannels: newAmplitudePerFrequencyGraphSelectedChannels,
                });
              }}
              onLegendItemClick={(legendItem, channel) => {
                reportGeneratorEventLogger.log(
                  'on_assessments_amplitudes_per_frequency_legend_item_click',
                  { channel, legendItem }
                );
                this.setState(prvState => {
                  const prvFrequencyAmplitudesAssessmentLegendItems =
                    prvState.frequencyAmplitudesAssessmentLegendItems;

                  const newFrequencyAmplitudesAssessmentLegendItems = prvFrequencyAmplitudesAssessmentLegendItems;
                  newFrequencyAmplitudesAssessmentLegendItems[channel][
                    legendItem
                  ] = !newFrequencyAmplitudesAssessmentLegendItems[channel][
                    legendItem
                  ];

                  return {
                    frequencyAmplitudesAssessmentLegendItems: newFrequencyAmplitudesAssessmentLegendItems,
                  };
                });
              }}
              onValuesTypeChange={event => {
                this.setState({
                  frequencyAmplitudesAssessmentValuesType: event.target.value,
                });
                reportGeneratorEventLogger.log(
                  'on_assessments_amplitudes_per_frequency_value_types_change',
                  { newValueType: event.target.value }
                );
              }}
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...(shouldRenderGraph
                ? this.getAssessmentAmplitudePerFrequencyComparisonGraphProps()
                : {})}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: amplitudePerFrequencyGraphHeader,
              addableContentSubHeader: amplitudePerFrequencyGraphSubHeader,
              addableContentType: assessmentAmplitudePerFrequencyGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentAmplitudesPerFrequencyAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={
          shouldRenderGraph && getIsAddToReportBtnDisabled()
        }
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  getAssessmentSwingleChecksProps = () => {
    const {
      swingleChecks,
      headsetChecks,
      swingleResults,
      swingleChecksGraphSelectedAssessmentIds,
      swingleChecksGraphCatagories,
      headsetChecksGraphCatagories,
      assessmentResults,
    } = this.state;

    const [
      preAssessment,
      postAssessment,
    ] = swingleChecksGraphSelectedAssessmentIds;

    const preAssessmentStageType = assessmentResults[preAssessment].stagesType;
    const postAssessmentStageType =
      assessmentResults[postAssessment].stagesType;

    const preSwingleChecksResults = swingleResults[preAssessment];
    const postSwingleChecksResults = swingleResults[postAssessment];
    const preAssessmentSwingleResultsKeys = Object.keys(
      swingleResults[preAssessment]
    );
    const postAssessmentSwingleResultsKeys = Object.keys(
      swingleResults[postAssessment]
    );
    const commonSwingleChecksResultsKeys = intersection(
      preAssessmentSwingleResultsKeys,
      postAssessmentSwingleResultsKeys
    );

    const checks =
      preAssessmentStageType === 'headsetStages'
        ? headsetChecks
        : swingleChecks;

    const preSwingleCategoryScores = getSwingleCategoriesScores({
      checks,
      results: pick(
        swingleResults[preAssessment],
        commonSwingleChecksResultsKeys
      ),
    });

    const postSwingleCategoryScores = getSwingleCategoriesScores({
      checks,
      results: pick(
        swingleResults[postAssessment],
        commonSwingleChecksResultsKeys
      ),
    });

    const isSomeDataHidden =
      preAssessmentStageType !== postAssessmentStageType ||
      commonSwingleChecksResultsKeys.length !==
        preAssessmentSwingleResultsKeys.length ||
      commonSwingleChecksResultsKeys.length !==
        postAssessmentSwingleResultsKeys.length;

    const commonSwingleChecksGraphCatagories = getSwingleCatagoriesFromChecks({
      checks: pick(checks, commonSwingleChecksResultsKeys),
    });

    const selectedCatagories = Object.keys(
      pickBy(
        pick(
          preAssessmentStageType === 'headsetStages'
            ? headsetChecksGraphCatagories
            : swingleChecksGraphCatagories,
          commonSwingleChecksGraphCatagories
        ),
        value => value
      )
    );

    const swingleChecksGroupedByCategory = pick(
      groupBy(
        pick(checks, commonSwingleChecksResultsKeys),
        check => check.category.en
      ),
      selectedCatagories
    );

    const preSwingleNoise = assessmentResults[preAssessment].swingleNoise || {};
    const postSwingleNoise =
      assessmentResults[postAssessment].swingleNoise || {};

    return {
      swingleChecksGroupedByCategory,
      preSwingleChecksResults,
      postSwingleChecksResults,
      postSwingleCategoryScores,
      preSwingleCategoryScores,
      preAssessment,
      postAssessment,
      preAssessmentTimestamp: assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp: assessmentResults[postAssessment].timestamp,
      selectedCatagories,
      isSomeDataHidden,
      commonSwingleChecksGraphCatagories,
      allCatagories: Object.keys(
        pick(
          preAssessmentStageType === 'headsetStages'
            ? headsetChecksGraphCatagories
            : swingleChecksGraphCatagories,
          commonSwingleChecksGraphCatagories
        )
      ),
      preSwingleNoise,
      postSwingleNoise,
      swingleChecksOrder: this.props.swingleChecksOrder,
      stagesType: preAssessmentStageType || 'stages',
    };
  };

  renderAssessmentSwingleChecksAddableContent = ({
    addableContentProps,
  } = {}) => {
    const {
      swingleChecks,
      headsetChecks,
      swingleResults,
      swingleChecksGraphCatagories,
      swingleChecksGraphSelectedAssessmentIds,
    } = this.state;
    const assessmentSwingleChecksGraphHeader = 'EEG Metrics';
    const assessmentSwingleChecksGraphSubHeader =
      'EEG amplitude performance measures categorized by type of measure';

    const shouldRenderGraph =
      !isEmpty(swingleChecks) &&
      !isEmpty(headsetChecks) &&
      !isEmpty(swingleResults) &&
      !isEmpty(swingleChecksGraphCatagories) &&
      swingleChecksGraphSelectedAssessmentIds.length > 1;

    const assessmentOptions =
      !isEmpty(swingleResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(swingleResults),
      });

    const getIsAddToReportBtnDisabled = () => {
      // eslint-disable-next-line no-shadow
      const { swingleChecksGraphCatagories } = this.state;
      const selectedCatagories = Object.keys(
        pickBy(swingleChecksGraphCatagories, value => value)
      );
      return (
        selectedCatagories.length === 0 ||
        (shouldRenderGraph &&
          this.getAssessmentSwingleChecksProps()
            .commonSwingleChecksGraphCatagories.length === 0)
      );
    };

    return (
      <AddableContent
        header={assessmentSwingleChecksGraphHeader}
        subHeader={assessmentSwingleChecksGraphSubHeader}
        content={
          !isEmpty(swingleResults) ? (
            <AssessmentSwingleChecks
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'swingleChecksGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'swingleChecksGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              onSelectedCatagoriesChange={event => {
                const newSelectedCatagories = event.target.value;
                reportGeneratorEventLogger.log(
                  'on_assessment_swingle_checks_graph_selected_catagories_change',
                  { newSelectedCatagories }
                );
                this.setState(prvState => {
                  const isHeadset =
                    this.getAssessmentSwingleChecksProps().stagesType ===
                    'headsetStages';
                  const prvSwingleChecksGraphCatagories = isHeadset
                    ? prvState.headsetChecksGraphCatagories
                    : prvState.swingleChecksGraphCatagories;
                  const newSwingleChecksGraphCatagories = mapValues(
                    prvSwingleChecksGraphCatagories,
                    (value, key) => newSelectedCatagories.indexOf(key) > -1
                  );
                  return {
                    [isHeadset
                      ? 'headsetChecksGraphCatagories'
                      : 'swingleChecksGraphCatagories']: newSwingleChecksGraphCatagories,
                  };
                });
              }}
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...this.getAssessmentSwingleChecksProps()}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: assessmentSwingleChecksGraphHeader,
              addableContentSubHeader: assessmentSwingleChecksGraphSubHeader,
              addableContentType: assessmentSwingleChecksGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentSwingleChecksAddableContent,
              getIsAddToReportBtnDisabled,
            },
          });
        }}
        isAddToReportDisabled={getIsAddToReportBtnDisabled()}
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  getSwingleAssessmentResults = ({ assessmentResults }) =>
    pickBy(assessmentResults, assessment => assessment.swingle);

  getCPTAssessmentResults = ({ assessmentResults }) =>
    pickBy(assessmentResults, assessment => assessment.cpt);

  getEEGAssessmentResults = ({ assessmentResults }) =>
    pickBy(assessmentResults, assessment => assessment.eeg);

  getBrainMapsAssessmentResults = ({ assessmentResults }) =>
    pickBy(assessmentResults, assessment => assessment.eeg);

  getNoiseAssessmentResults = ({ assessmentResults }) =>
    pickBy(
      assessmentResults,
      assessment => assessment.assessment_v2 && assessment.assessment_v2.noise
    );

  getCPTPerformanceGraphProps = () => {
    const {
      CPTResults,
      CPTPerformanceGraphSelectedAssessmentIds,
      assessmentResults,
    } = this.state;

    const [
      preAssessment,
      postAssessment,
    ] = CPTPerformanceGraphSelectedAssessmentIds;
    const rawPreCPTResult =
      CPTResults[CPTPerformanceGraphSelectedAssessmentIds[0]];
    const rawPostCPTResult =
      CPTResults[CPTPerformanceGraphSelectedAssessmentIds[1]];

    const CPTResultKeys = [
      'accuracy',
      'commissionErrors',
      'omissionErrors',
      'reactionTime',
      'responsesSD',
    ];
    const preCPTResult = pick(rawPreCPTResult, CPTResultKeys);
    const postCPTResult = pick(rawPostCPTResult, CPTResultKeys);

    const CPTPerformanceGraphData = Object.keys(preCPTResult).map(
      resultKey => ({
        category: resultKey,
        preScore: preCPTResult[resultKey],
        postScore: postCPTResult[resultKey],
        prePerformanceScore: get(rawPreCPTResult, [
          'performance',
          resultKey,
          'zScore',
        ]),
        postPerformanceScore: get(rawPostCPTResult, [
          'performance',
          resultKey,
          'zScore',
        ]),
        prePerformanceRank: get(rawPreCPTResult, [
          'performance',
          resultKey,
          'qRank',
        ]),
        postPerformanceRank: get(rawPostCPTResult, [
          'performance',
          resultKey,
          'qRank',
        ]),
      })
    );

    return {
      data: CPTPerformanceGraphData,
      preOverallScore: rawPreCPTResult.overallScore,
      postOverallScore: rawPostCPTResult.overallScore,
      preAssessment: CPTPerformanceGraphSelectedAssessmentIds[0],
      postAssessment: CPTPerformanceGraphSelectedAssessmentIds[1],
      preAssessmentTimestamp: assessmentResults[preAssessment].timestamp,
      postAssessmentTimestamp: assessmentResults[postAssessment].timestamp,
    };
  };

  renderAssessmentCPTPerformanceAddableContent = ({
    addableContentProps,
  } = {}) => {
    const { CPTResults, CPTPerformanceGraphSelectedAssessmentIds } = this.state;
    const cptPerformanceGraphHeader = 'Task Performance';
    const cptPerformanceGraphSubHeader =
      'Scores from the continuous performance task of response inhibition';

    const shouldRenderGraph =
      !isEmpty(CPTResults) &&
      CPTPerformanceGraphSelectedAssessmentIds.length > 1;

    const assessmentOptions =
      !isEmpty(CPTResults) &&
      this.getAssessmentOptions({
        assessmentIds: Object.keys(CPTResults),
      });

    const verticalMoreMenuItems = [
      {
        text: 'Export CSV',
        onClick: () => {
          const { data } = this.getCPTPerformanceGraphProps();
          const formattedKeys = {
            category: 'Category',
            postPerformanceRank: 'Post performance rank',
            postPerformanceScore: 'Post performance score',
            prePerformanceRank: 'Pre performance rank',
            prePerformanceScore: 'Pre performance score',
            postScore: 'Post score',
            preScore: 'Pre score',
          };

          const header = data.reduce(
            (acc, dataObject) => {
              acc.push(CPTCategoriesFormattedNames[dataObject.category]);
              return acc;
            },
            ['Type']
          );
          const rows = Object.keys(data[0])
            .filter(key => key !== 'category')
            .map(key => {
              return [
                formattedKeys[key],
                ...data.map(dataObject => {
                  const value = dataObject[key];
                  if (typeof value === 'number') {
                    return setFixedDecimalsIfNeeded({ number: value });
                  }
                  return value;
                }),
              ];
            });

          const csv = convertArrayToCSV(rows, { header });

          downloadURIContent({
            content: `data:text/csv;charset=utf-8,${csv}`,
            name: `cpt_${this.getDownloadedFileSuffix()}.csv`,
          });
        },
        key: 'csv',
      },
    ];
    const headerRightContent = (
      <div>
        <VerticalMoreMenu menuItems={verticalMoreMenuItems} />
      </div>
    );

    return (
      <AddableContent
        header={cptPerformanceGraphHeader}
        subHeader={cptPerformanceGraphSubHeader}
        headerRightContent={headerRightContent}
        content={
          !isEmpty(CPTResults) ? (
            <AssessmentCPTPerformanceComparisonGraph
              assessmentOptions={assessmentOptions}
              onPreAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'CPTPerformanceGraphSelectedAssessmentIds',
                  changeType: 'preAssessment',
                })
              }
              onPostAssessmentChange={newAssessment =>
                this.onAssessmentChange({
                  newAssessment,
                  assessmentArrayNameInState:
                    'CPTPerformanceGraphSelectedAssessmentIds',
                  changeType: 'postAssessment',
                })
              }
              noDataIndicator={
                !shouldRenderGraph &&
                this.renderNoDataIndicatorForAssessmentGraphs()
              }
              {...(shouldRenderGraph ? this.getCPTPerformanceGraphProps() : {})}
            />
          ) : (
            this.renderNoDataIndicatorForAssessmentGraphs()
          )
        }
        onAddToReportClick={() => {
          this.setState({
            addToReportModalState: {
              addableContentHeader: cptPerformanceGraphHeader,
              addableContentSubHeader: cptPerformanceGraphSubHeader,
              addableContentType: assessmentCPTPerformanceGraphType,
              isModalOpen: true,
              renderAddableContent: this
                .renderAssessmentCPTPerformanceAddableContent,
            },
          });
        }}
        shouldShowAddToReport={shouldRenderGraph}
        {...addableContentProps}
      />
    );
  };

  renderUserCard() {
    return <UserCard {...this.getUserCardData()} />;
  }

  onAddToReportModalHeaderChange = ({ value }) => {
    this.setState(prvState => ({
      addToReportModalState: {
        ...prvState.addToReportModalState,
        addableContentHeader: value,
      },
    }));
  };

  onAddToReportModalSubHeaderChange = ({ value }) => {
    this.setState(prvState => ({
      addToReportModalState: {
        ...prvState.addToReportModalState,
        addableContentSubHeader: value,
      },
    }));
  };

  onFeatureNotAllowedDialogDismiss = () => {
    this.setState({ isFeatureNotAllowedDialogOpen: false });
  };

  onSelectedGraphsTabChange = (event, selectedGraphsTab) => {
    reportGeneratorEventLogger.log('on_select_graph_tab_change', {
      selectedGraphsTab,
    });
    this.setState({ selectedGraphsTab });
  };

  onConfirmGenerateReport = async () => {
    const { reportCardsData } = this.state;

    const {
      patientId,
      patientFullName,
      frequenciesConfig,
      patientEmail,
    } = this.props;

    const now = Date.now();
    const report = {
      lastTimeModified: now,
      createdAt: now,
      reportCardsData,
      patientName: patientFullName,
      patientEmail,
      frequenciesConfig,
      title: `${patientFullName} ${format(
        new Date(now),
        FNS_DATE_FORMATS.yearMonthDayWithTime24
      )}`,
    };

    const reportDatabaseRef = await database
      .ref(`reports/${patientId}`)
      .push(report);

    reportGeneratorEventLogger.log('on_save_report', {
      reportId: reportDatabaseRef.key,
      customerId: patientId,
    });

    const reportLink = `${window.location.origin}/session-to-session-report-viewer.html?reportId=${reportDatabaseRef.key}&customerId=${patientId}&clinicId=${sessionStorage.userId}`;

    this.setState({
      reportLink,
      isShareReportDialogOpen: true,
      isConfirmGenerateReportDialogOpen: false,
      shareInAppWithClient: true,
      reportId: reportDatabaseRef.key,
    });
  };

  onCancelGenerateReport = () => {
    this.setState({ isConfirmGenerateReportDialogOpen: false });
  };

  render() {
    const {
      reportCardsHolderMarginTop,
      reportCardsHolderHeight,
      addToReportModalState,
      isPreviewReportModalOpen,
      filteredSessionsAnalysis,
      reportCardsData,
      isDiscardReportDialogOpen,
      isShareReportDialogOpen,
      changeablePatientEmail,
      isGeneratedReportDialogOpen,
      reportLink,
      isFeatureNotAllowedDialogOpen,
      isComponentReadyToRender,
      selectedGraphsTab,
      isConfirmGenerateReportDialogOpen,
      onDownloadSymptomsTrackingDataAsCSV,
      onDownloadOverallSymptomsTrackingDataAsCSV,
    } = this.state;

    const {
      patientFullName,
      frequenciesConfig,
      isUserAllowedToUseThisFeature,
      shouldShowBrainMaps,
      insightItems,
      insightsConfig,
    } = this.props;

    if (!isComponentReadyToRender) {
      return null;
    }

    const reportCards = mapReportCardsDataToReportCards({
      reportCardsData,
      addableContentProps: {
        onHeaderSave: this.onAddableContentHeaderSave,
        onSubHeaderSave: this.onAddableContentSubHeaderSave,
        areTextsEditable: true,
      },
      frequenciesConfig,
    });

    const userAddedReportCards = reportCards.filter(
      reportCard => !reportCard.id.startsWith('built-in')
    );

    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div
            className={styles.left_content}
            style={{
              marginTop:
                !mediaQueryListExtraSmall.matches && reportCardsHolderMarginTop,
              height: reportCardsHolderHeight,
            }}
          >
            <ClientReport
              onViewReportClick={this.onViewReportClick}
              onDiscardClick={this.onDiscardReportClick}
              onDeleteItemClick={this.onDeleteReportCardClick}
              reportCards={userAddedReportCards}
              onReportCardsReordered={this.onReportCardsReordered}
              isDiscardBtnEnabled={
                reportCards.length > this.getDefaultReportCardsData().length
              }
              isViewReportBtnEnabled={
                reportCards.length > this.getDefaultReportCardsData().length
              }
            />
          </div>

          <div className={styles.right_content}>
            {this.renderUserCard()}

            <Paper>
              <div className={styles.tabs_container}>
                <Tabs
                  value={selectedGraphsTab}
                  onChange={this.onSelectedGraphsTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="off"
                >
                  {this.props.nfFlag ? (
                    <Tab
                      label="Sessions Data"
                      value={sessionToSessionReportGeneratorTabs.sessionGraphs}
                      classes={{ root: styles.tab_root }}
                    />
                  ) : null}
                  <Tab
                    label="Brain Assessments Data"
                    value={sessionToSessionReportGeneratorTabs.assessmentGraphs}
                    classes={{ root: styles.tab_root }}
                  />
                  {this.props.nfFlag ? (
                    <Tab
                      label="Insights"
                      value={sessionToSessionReportGeneratorTabs.insights}
                      classes={{ root: styles.tab_root }}
                    />
                  ) : null}
                  <Tab
                    label="Other"
                    value={sessionToSessionReportGeneratorTabs.freeText}
                    classes={{ root: styles.tab_root }}
                  />
                </Tabs>
              </div>
            </Paper>

            {selectedGraphsTab ===
              sessionToSessionReportGeneratorTabs.sessionGraphs && (
              <React.Fragment>
                <div className={styles.session_filters_container}>
                  <SessionFilters
                    onResetFiltersClick={this.onResetFiltersClick}
                    {...this.getDateFilterProps()}
                    {...this.getTimeFilterProps()}
                    {...this.getProtocolsFilterProps()}
                    {...this.getProgramsFilterProps()}
                    {...this.getSessionTypesFilterProps()}
                    {...this.getTrainingSiteFilterProps()}
                    {...this.getDurationFilterProps()}
                    {...this.getPositiveFeedbackFilterProps()}
                    {...this.getAlertsNumberFilterProps()}
                    {...this.getNoiseFilterProps()}
                  />
                </div>

                <SessionsTable
                  sessions={filteredSessionsAnalysis}
                  trainingSessions={this.props.trainingSessions}
                  onSessionClick={this.onSessionsTableSessionClick}
                  onLinkToSessionClick={this.onSessionsTableLinkToSessionClick}
                  tableAlternativeUI={
                    filteredSessionsAnalysis.length === 0 ? (
                      <div style={{ paddingBottom: 47 }}>
                        {this.renderNoDataIndicator()}
                      </div>
                    ) : null
                  }
                  isHeadCheckboxChecked={
                    this.getSelectedSessions().length ===
                    filteredSessionsAnalysis.length
                  }
                  onHeadCheckboxClick={this.onSessionsTableHeadCheckboxClick}
                />

                {this.renderZoneGraphAddableContent()}

                {this.renderSessionComparisonGraphAddableContent()}

                {this.renderFrequenciesSessionComparisonGraphAddableContent()}

                {this.renderStreakSessionComparisonGraphAddableContent()}

                {this.renderAmplitudeAndNoiseGraphAddableContent()}

                {this.renderInDepthSessionAnalysisGraphAddableContent()}

                {this.renderAverageAndMaxStreakGraphAddableContent()}
              </React.Fragment>
            )}

            {selectedGraphsTab ===
              sessionToSessionReportGeneratorTabs.assessmentGraphs && (
              <React.Fragment>
                {onDownloadOverallSymptomsTrackingDataAsCSV &&
                onDownloadSymptomsTrackingDataAsCSV ? (
                  <React.Fragment>
                    {/* {this.renderAssessmentPerformanceAddableContent()} */}
                    {this.renderAssessmentQuestionnaireResultsAddableContent()}
                    {this.renderOverallSymptomsTrackingGraphAddableContent()}
                    {this.renderSymptomsTrackingGraphAddableContent()}
                    {this.renderAssessmentCPTPerformanceAddableContent()}
                    {this.renderAssessmentSwingleChecksAddableContent()}
                    {shouldShowBrainMaps &&
                      this.renderAssessmentBrainMapsAddableContent()}
                    {this.renderAssessmentAmplitudesPerFrequencyAddableContent()}
                    {/* {this.renderNoiseGraphAddableContent()} */}
                  </React.Fragment>
                ) : (
                  <div className={styles.insights_loader_container}>
                    <CircularProgress />
                  </div>
                )}
              </React.Fragment>
            )}

            {selectedGraphsTab ===
              sessionToSessionReportGeneratorTabs.insights && (
              <React.Fragment>
                {insightItems && insightsConfig ? (
                  this.renderAllInsightsAddableContents()
                ) : (
                  <div className={styles.insights_loader_container}>
                    <CircularProgress />
                  </div>
                )}
              </React.Fragment>
            )}

            {selectedGraphsTab ===
              sessionToSessionReportGeneratorTabs.freeText && (
              <React.Fragment>
                {/* {this.renderFreeTextCardAddableContent()} */}
                {this.renderTextEditorCardAddableContent()}
              </React.Fragment>
            )}

            {// this will disable scrolling for the modals, we need to add it only when modals are
            // enabled, because it causes the page to scroll up when one of the filteres is opened
            (addToReportModalState.isModalOpen || isPreviewReportModalOpen) && (
              <style>
                {`
                html,
                body {
                  height: 100%;
                }`}
              </style>
            )}

            <Modal open={addToReportModalState.isModalOpen}>
              <div>
                <AddToReportModal
                  onAddToReportBtnClick={() =>
                    this.setState(
                      prvState => {
                        const newReportCardsData = cloneDeep(
                          prvState.reportCardsData
                        );

                        const reportCardDataId = `report-card-${
                          addToReportModalState.addableContentType
                        }-${Date.now()}`;

                        const addableContentProps = {
                          header: addToReportModalState.addableContentHeader,
                          ...(addToReportModalState.addableContentSubHeader
                            ? {
                                subHeader:
                                  addToReportModalState.addableContentSubHeader,
                              }
                            : {}),
                          ...(addToReportModalState.addableContentHeaderIcon
                            ? {
                                headerIcon:
                                  addToReportModalState.addableContentHeaderIcon,
                              }
                            : {}),
                          shouldShowAddToReport: false,
                          filterSavedValues: this.getFilterSavedValues(),
                          reportCardDataId,
                        };

                        const reportCardData = {
                          id: reportCardDataId,
                          type: addToReportModalState.addableContentType,
                          title: addToReportModalState.addableContentHeader,
                          selectedSessions:
                            selectedGraphsTab ===
                            sessionToSessionReportGeneratorTabs.sessionGraphs
                              ? this.getSelectedSessions()
                              : [],
                          data: {
                            ...addableContentProps,
                            graphPayload: {
                              ...this.getGraphPayLoad(
                                addToReportModalState.addableContentType
                              ),
                              isInPreview: true,
                            },
                          },
                        };

                        const usedFormats = this.getUsedFormatsInTextEditor(
                          prvState.textEditorCardContent
                        );
                        reportGeneratorEventLogger.log(
                          'add_to_report_confirm',
                          {
                            is_text_editor_card:
                              addToReportModalState.addableContentType ===
                              textEditorCardType,

                            used_formats: usedFormats.length > 0,
                          }
                        );

                        newReportCardsData.push(reportCardData);

                        return {
                          reportCardsData: newReportCardsData,
                        };
                      },
                      () => {
                        this.setState(prvState => ({
                          freeTextCardContent:
                            addToReportModalState.addableContentType ===
                            freeTextCardType
                              ? ''
                              : prvState.freeTextCardContent,
                          textEditorCardContent:
                            addToReportModalState.addableContentType ===
                            textEditorCardType
                              ? EditorState.createEmpty()
                              : prvState.textEditorCardContent,
                          addToReportModalState: getAddToReportModalDefaultState(),
                        }));
                      }
                    )
                  }
                  onClickAway={event => {
                    // this is because the checkboxes menu use the select material ui component which uses a backdrop that has id menu-
                    // basically since its a backdrop any click in the menu is considered a click outside for the add to report modal
                    if (!event.target.closest('#menu-')) {
                      reportGeneratorEventLogger.log(
                        'on_click_away_from_add_to_report_modal'
                      );
                      this.setState({
                        addToReportModalState: getAddToReportModalDefaultState(),
                      });
                    }
                  }}
                  onCancelClick={() => {
                    reportGeneratorEventLogger.log(
                      'on_cancel_click_in_add_to_report_modal'
                    );
                    this.setState({
                      addToReportModalState: getAddToReportModalDefaultState(),
                    });
                  }}
                  addableContent={addToReportModalState.renderAddableContent({
                    addableContentProps: {
                      header: addToReportModalState.addableContentHeader,
                      subHeader: addToReportModalState.addableContentSubHeader,
                      type: addToReportModalState.addableContentType,
                      shouldShowAddToReport: false,
                      isExpandable: false,
                      areTextsEditable: true,
                      onHeaderSave: this.onAddToReportModalHeaderChange,
                      onSubHeaderSave: this.onAddToReportModalSubHeaderChange,
                    },
                    graphProps:
                      addToReportModalState.addableContentType ===
                      amplitudeAndNoiseGraphType
                        ? { isAnimationActive: false }
                        : {},
                  })}
                  isAddToReportBtnDisabled={
                    addToReportModalState.getIsAddToReportBtnDisabled &&
                    addToReportModalState.getIsAddToReportBtnDisabled()
                  }
                  addableContentHeader={
                    addToReportModalState.addableContentHeader
                  }
                  addableContentSubHeader={
                    addToReportModalState.addableContentSubHeader
                  }
                  onAddableContentHeaderChange={
                    this.onAddToReportModalHeaderChange
                  }
                  onAddableContentSubHeaderChange={
                    this.onAddToReportModalSubHeaderChange
                  }
                />
              </div>
            </Modal>

            <Modal open={isPreviewReportModalOpen}>
              <div id="preview-report">
                <PreviewReportModalContent
                  reportCards={reportCards}
                  onClickAway={() => {
                    reportGeneratorEventLogger.log(
                      'click_away_from_preview_report_modal'
                    );

                    this.setState({ isPreviewReportModalOpen: false });
                  }}
                  onDoneClick={() => {
                    reportGeneratorEventLogger.log(
                      'on_done_click_for_preview_report_modal'
                    );

                    this.setState({ isPreviewReportModalOpen: false });
                  }}
                  onGenerateReport={() => {
                    // eslint-disable-next-line no-shadow
                    const { isUserAllowedToUseThisFeature } = this.props;
                    if (isUserAllowedToUseThisFeature) {
                      this.setState({
                        isPreviewReportModalOpen: false,
                        // isConfirmGenerateReportDialogOpen: true,
                      });
                      this.onConfirmGenerateReport();
                    } else {
                      this.setState({ isFeatureNotAllowedDialogOpen: true });
                    }
                  }}
                />
              </div>
            </Modal>

            <ShareReportDialog
              isOpen={isShareReportDialogOpen}
              onClose={() => {
                database
                  .ref(
                    `reports/${this.props.patientId}/${this.state.reportId}/shareInAppWithClient`
                  )
                  .set(this.state.shareInAppWithClient);
                this.onShareReportDialogClose();
              }}
              patientName={patientFullName}
              reportLink={`${reportLink}&viewer=customer`}
              onCopyLinkToClipboard={inputElm => {
                inputElm.select();
                document.execCommand('copy');
                showNotification('success', 'Copied to clipboard');
                reportGeneratorEventLogger.log('on_copy_link_to_clipboard', {
                  link: `${reportLink}&viewer=customer`,
                });
              }}
              patientEmail={changeablePatientEmail}
              onPatientEmailChange={event => {
                this.setState({
                  changeablePatientEmail: event.target.value,
                });
              }}
              // onViewReport={this.onViewReport}
              onNotifyByEmail={async () => {
                // eslint-disable-next-line no-undef
                await fireFunctionPost('clinic_apis-sessionsReportEmail', {
                  link: `${reportLink}&viewer=customer`,
                  email: changeablePatientEmail,
                });
                showNotification('success', 'Email was just sent');
                reportGeneratorEventLogger.log('on_notify_by_email', {
                  link: `${reportLink}&viewer=customer`,
                  email: changeablePatientEmail,
                });
              }}
              onShareInAppWithClientChange={e => {
                this.setState({ shareInAppWithClient: e.target.checked });
              }}
              shareInAppWithClient={this.state.shareInAppWithClient}
            />

            <ConfirmGenerateReportDialog
              isOpen={isConfirmGenerateReportDialogOpen}
              onPrimaryBtnClick={this.onConfirmGenerateReport}
              onSecondaryBtnClick={this.onCancelGenerateReport}
            />

            <GeneratedReportDialog
              isOpen={isGeneratedReportDialogOpen}
              onDone={() => window.open(document.referrer, '_self')}
              onViewReport={this.onViewReport}
              onShareReport={() => {
                reportGeneratorEventLogger.log('on_share_report_click');
                if (this.props.isUserAllowedToUseThisFeature) {
                  this.setState({
                    isGeneratedReportDialogOpen: false,
                    isShareReportDialogOpen: true,
                  });
                } else {
                  this.setState({ isFeatureNotAllowedDialogOpen: true });
                }
              }}
            />

            <DiscardReportDialog
              isOpen={isDiscardReportDialogOpen}
              onCancel={this.onCloseDiscardReportDialog}
              onDiscard={this.onDiscardReportConfirm}
            />

            <FeatureNotAllowedDialog
              text="You need to upgrade your plan in order to view the saved the report and share it with your client"
              isOpen={
                isFeatureNotAllowedDialogOpen && !isUserAllowedToUseThisFeature
              }
              onDismiss={this.onFeatureNotAllowedDialogDismiss}
            />
          </div>
        </div>
      </div>
    );
  }
}

SessionToSessionReportGeneratorBase.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientEmail: PropTypes.string.isRequired,
  patientAge: PropTypes.number.isRequired,
  patientFullName: PropTypes.string.isRequired,
  isDataLoaded: PropTypes.bool.isRequired,
  frequenciesConfig: PropTypes.object.isRequired,
  isUserAllowedToUseThisFeature: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  symptomsTrackingAnswers: PropTypes.object.isRequired,
  symptomsTrackingAllQuestions: PropTypes.object.isRequired,
  sessionsAnalysis: PropTypes.array.isRequired,
  patientGender: PropTypes.string.isRequired,
  swingleChecks: PropTypes.object.isRequired,
  headsetChecks: PropTypes.object.isRequired,
  trainingSessions: PropTypes.object,
  sessionBlocks: PropTypes.object,
  assessmentResultsWithDeletedAssessments: PropTypes.object.isRequired,
  answerScales: PropTypes.object.isRequired,
  shouldShowBrainMaps: PropTypes.bool.isRequired,
  onDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  onDownloadOverallSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  shouldUseNewSymptomTracker: PropTypes.bool,
  allQuestionnaires: PropTypes.object,
  assessmentStagesInfo: PropTypes.object,
  insightItems: PropTypes.object.isRequired,
  insightsConfig: PropTypes.object.isRequired,
  nfFlag: PropTypes.bool.isRequired,
  swingleChecksOrder: PropTypes.array.isRequired,
  startedLoadingTime: PropTypes.number.isRequired,
  customerIdAsProp: PropTypes.string.isRequired,
  getOnDownloadSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
  getOnDownloadOverallSymptomsTrackingDataAsCSV: PropTypes.func.isRequired,
};

export const SessionToSessionReportGenerator = withEntryComponent(
  withTranslation(SessionToSessionReportGeneratorBase)
);
