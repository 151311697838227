import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import {
  Dialog,
  Button,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './SettingsSection.scss';

const RemoveMFADialogBase = ({
  isOpen,
  onClose,
  removeMFA,
  isRemoveMFAError,
  isLoadingRemoveMFA,
}) => {
  return (
    <Dialog open={isOpen}>
      <div className={styles.dialog_content}>
        <p className={styles.dialog_content_title}>
          Disable 2-Step authentication
        </p>
        <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ marginRight: 10 }} />
          Are you sure you want to disable 2FA? This will log you out, and you
          can log in again later. Disabling 2FA will lower your account
          security.
        </div>
        {isRemoveMFAError ? (
          <div style={{ marginTop: 10, color: 'red' }}>
            Something went wrong
          </div>
        ) : null}
      </div>

      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={isLoadingRemoveMFA}>
          <span className={styles.btn}>Cancel</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={removeMFA}
          disabled={isLoadingRemoveMFA}
        >
          <span className={styles.btn}>
            {isLoadingRemoveMFA ? <CircularProgress size={14} /> : 'OK'}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveMFADialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  removeMFA: PropTypes.func.isRequired,
  isRemoveMFAError: PropTypes.bool.isRequired,
  isLoadingRemoveMFA: PropTypes.bool.isRequired,
});

export const RemoveMFADialog = React.memo(RemoveMFADialogBase);
RemoveMFADialog.displayName = 'RemoveMFADialog';
