import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { getCurrentURLParams, scrollTo } from '../../utils/utils';
import { MFADialog, MFAAllSteps } from './MFADialog';
import { RemoveMFADialog } from './RemoveMFADialog';
import { LogoutMFADialog } from './LogoutMFADialog';
// import styles from './SettingsSection.scss';

const FIREBASE_PREFIX_ERROR_LENGTH = 25;

const MFASettingsBase = () => {
  // eslint-disable-next-line no-undef
  const authUser = firebase.auth().currentUser;
  const emailVerified = authUser && authUser.emailVerified;

  // const [authUser,setAuthUser] = useState(authUserGlobal)
  const [isMFADialogOpen, setIsMFADialogOpen] = useState(false);
  const [isLodingMFADialog, setIsLodingMFADialog] = useState(false);
  const [verificationId, setVerificationId] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [clinicPhoneNumber, setClinicPhoneNumber] = useState('');
  const [isPhoneError, setIsPhoneError] = useState('');
  const [isEnrolledSuccessfully, setIsEnrolledSuccessfully] = useState(false);
  const [isCodeError, setIsCodeError] = useState('');
  const [isRemoveMFADialogOpen, setIsRemoveMFADialogOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(
    emailVerified ? MFAAllSteps[2] : MFAAllSteps[0]
  );
  const [moreMenuAnchorElement, setMoreMenuAnchorElement] = useState(null);
  const [isEditPhoneNUmberFlow, setIsEditPhoneNUmberFlow] = useState(false);
  const [shouldShowLogoutBtn, setShouldShowLogoutBtn] = useState(false);
  const [isAuthUserNull, setIsAuthUserNull] = useState(false);
  const [isRemoveMFAError, setIsRemoveMFAError] = useState(false);
  const [isLoadingRemoveMFA, setIsLoadingRemoveMFA] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);

  const { authFlow, scroll } = getCurrentURLParams();

  const onOpenMoreMenu = event => setMoreMenuAnchorElement(event.currentTarget);

  const onVerifyEmail = () => {
    setIsLodingMFADialog(true);
    authUser
      .sendEmailVerification({
        url: `${window.location.href}?authFlow=true`,
      })
      .then(() => {
        setIsEmailSent(true);
        setIsLodingMFADialog(false);
        setActiveStep(MFAAllSteps[1]);
      })
      .catch(() => {
        setIsLodingMFADialog(false);
        setIsEmailError(true);
        // console.log(e);
      });
  };

  const onEnrollMFA = () => {
    setIsLodingMFADialog(true);

    // Step 1: Get all enrolled factors.
    // eslint-disable-next-line prefer-destructuring
    const enrolledFactors = authUser.multiFactor.enrolledFactors;

    // Step 2: Find the phone number factor (if it exists).
    const phoneFactor = enrolledFactors.find(
      factor => factor.factorId === 'phone' // Ensure it's a phone number factor.
    );

    const unenrollCurrentFactor = phoneFactor
      ? authUser.multiFactor.unenroll(phoneFactor.uid) // Unenroll the existing phone number.
      : Promise.resolve(); // If no phone number exists, proceed without unenrolling.

    unenrollCurrentFactor
      .then(() => {
        // Step 3: Get a multi-factor session for the new enrollment.
        return authUser.multiFactor.getSession();
      })
      .then(multiFactorSession => {
        // Specify the new phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: clinicPhoneNumber.startsWith('+')
            ? clinicPhoneNumber
            : `+${clinicPhoneNumber}`,
          session: multiFactorSession,
        };
        // eslint-disable-next-line no-undef
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        // Send SMS verification code to the new phone number.
        return phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          window.recaptchaVerifier
        );
      })
      .then(verificationIdVar => {
        setVerificationId(verificationIdVar);
        setIsLodingMFADialog(false);
        setActiveStep(MFAAllSteps[3]); // Proceed to the next step in the flow.
        const inputEl = document.getElementsByClassName('react-code-input');
        inputEl[0].children[0].focus();
      })
      .catch(e => {
        if (e.code === 'auth/requires-recent-login') {
          setShouldShowLogoutBtn(true);
        } else if (
          e.code === 'auth/second-factor-already-in-use' &&
          isEditPhoneNUmberFlow
        ) {
          setIsPhoneError(
            'The phone number is already enrolled as a second factor for this account. If you’re trying to update the phone number to one that was already in use before, please disable 2-step verification and enable it with the desired number.'
          );
        } else if (e.code === 'auth/too-many-requests') {
          setIsPhoneError(
            'We have blocked all requests from this device due to unusual activity. Try again later.'
          );
        } else if (e.code === 'auth/unsupported-first-factor') {
          setIsPhoneError('MFA is not available for the given first factor.');
        } else if (
          e.code === 'auth/invalid-phone-number' ||
          e.code === 'auth/internal-error-encountered'
        ) {
          setIsPhoneError('The phone number entered is invalid.');
        } else {
          setIsPhoneError(e.toString().slice(FIREBASE_PREFIX_ERROR_LENGTH));
        }
        setIsLodingMFADialog(false);
        new MyEvent('2fa_error').log({ error: e.code });
      });
  };

  const onVerificationCodeSent = () => {
    setIsCodeError('');
    setIsLodingMFADialog(true);
    // eslint-disable-next-line no-undef
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    // eslint-disable-next-line no-undef
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      cred
    );
    // Complete enrollment.
    authUser.multiFactor
      .enroll(multiFactorAssertion, 'My personal phone number')
      .then(async () => {
        await database
          .ref(`userInfo/${sessionStorage.userId}/privateDetails/phoneNumber`)
          .set(
            clinicPhoneNumber.startsWith('+')
              ? clinicPhoneNumber
              : `+${clinicPhoneNumber}`
          );
        setIsLodingMFADialog(false);
        setIsEnrolledSuccessfully(true);
        setActiveStep(MFAAllSteps[2]);
        if (authFlow) {
          const url = new URL(window.location.href);
          url.searchParams.delete('authFlow');
          window.history.pushState({}, '', url);
        }
        setMoreMenuAnchorElement(null);
        /* eslint-disable */
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set('2fa_enabled', true);
        amplitude.identify(identifyEvent);
        /* eslint-enable */
      })
      .catch(e => {
        if (e.code === 'auth/invalid-verification-code') {
          setIsCodeError(
            'The verification code entered is incorrect. Please double-check the code and try again.'
          );
        } else {
          setIsCodeError(e.toString().slice(FIREBASE_PREFIX_ERROR_LENGTH));
        }
        setIsLodingMFADialog(false);
        new MyEvent('2fa_error').log({ error: e.code });
      });
  };

  const onMFAClicked = () => {
    setIsMFADialogOpen(true);
  };

  const removeMFA = async () => {
    try {
      setIsLoadingRemoveMFA(true);
      // eslint-disable-next-line no-undef
      await fireFunctionPost('firebase_auth-remove2FAuth');
      setIsLoadingRemoveMFA(false);
      // eslint-disable-next-line no-undef, no-restricted-globals
      location.reload();
    } catch (e) {
      setIsRemoveMFAError(true);
      setIsLoadingRemoveMFA(false);
      new MyEvent('2fa_error').log({ error: e.code });
    }
  };

  const submitPhoneNumber = async () => {
    try {
      setIsPhoneError('');

      setIsLodingMFADialog(true);
      onEnrollMFA();
    } catch (e) {
      setIsPhoneError(e.toString().slice(FIREBASE_PREFIX_ERROR_LENGTH));
      new MyEvent('2fa_error').log({ error: e.code });
    }
  };

  const onSuccessClose = () => {
    setVerificationId(false);
    setVerificationCode(false);
    setIsMFADialogOpen(false);
    setIsEnrolledSuccessfully(false);
  };

  const onChangePhoneNumberClicked = () => {
    setActiveStep(MFAAllSteps[2]);
    setIsMFADialogOpen(true);
    setIsEditPhoneNUmberFlow(true);
  };

  const resendVerificationCode = () => {
    onEnrollMFA();
  };

  useEffect(() => {
    (async () => {
      const snapshot = await database
        .ref(`userInfo/${sessionStorage.userId}/privateDetails/phoneNumber`)
        .once('value', v => v);
      setClinicPhoneNumber(snapshot.val());
    })();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const authFlowLocalStorage = localStorage.getItem('authFlow');
    // eslint-disable-next-line no-undef
    if (
      authFlow ||
      (authFlowLocalStorage && !(authFlowLocalStorage === 'null'))
    ) {
      // eslint-disable-next-line no-undef
      if (authFlowLocalStorage) {
        // eslint-disable-next-line no-undef
        localStorage.setItem('authFlow', null);
      }
      setIsMFADialogOpen(true);
      setIsLodingMFADialog(true);
      setActiveStep(MFAAllSteps[2]);
      setTimeout(() => {
        setIsLodingMFADialog(false);
      }, 5000);
    }
  }, []);

  // BUG Hack
  useEffect(() => {
    if (!authUser) {
      setIsAuthUserNull(true);
    } else {
      if (isAuthUserNull && emailVerified) {
        setIsAuthUserNull(false);
        setActiveStep(MFAAllSteps[2]);
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        scrollTo('auth_scroll');
      }, 2000);
    }
  }, []);

  return (
    <>
      <div style={{ marginTop: 20 }}>
        {authUser && !authUser.multiFactor.enrolledFactors.length ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ErrorIcon style={{ color: 'red', width: 30, height: 30 }} />
            <div style={{ marginLeft: 10 }}>
              <div style={{ fontWeight: 600 }}>2-Step verification is off</div>
              <div>
                Add an extra layer of security with SMS verification, sending a
                unique code to your phone to login.
              </div>
            </div>
            <Button
              onClick={onMFAClicked}
              color="primary"
              variant="outlined"
              style={{ fontSize: 12, borderRadius: 20, marginLeft: 10 }}
            >
              Enable
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckIcon style={{ color: 'green', width: 30, height: 30 }} />
              <div style={{ marginLeft: 10 }}>
                <div style={{ fontWeight: 600 }}>2-Step verification is on</div>
                <div>
                  Add an extra layer of security with SMS verification, sending
                  a unique code to your phone to login.
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={onOpenMoreMenu}
                style={{
                  // backgroundColor: 'white',
                  marginLeft: 10,
                  height: '100%',
                  fontSize: 12,
                }}
                variant="text"
                color="primary"
              >
                <MoreVertIcon />
              </Button>
              <Menu
                anchorEl={moreMenuAnchorElement}
                open={!!moreMenuAnchorElement}
                onClose={() => setMoreMenuAnchorElement(null)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ fontSize: 13 }}
              >
                <MenuItem
                  onClick={() => {
                    setIsRemoveMFADialogOpen(true);
                  }}
                  style={{ fontSize: 13 }}
                >
                  Disable
                </MenuItem>
                <MenuItem
                  onClick={onChangePhoneNumberClicked}
                  style={{ fontSize: 13 }}
                >
                  Change phone number
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
      </div>
      <MFADialog
        isOpen={isMFADialogOpen}
        onClose={() => {
          setIsMFADialogOpen(false);
          setShouldShowLogoutBtn(false);
          setIsPhoneError('');
          setIsCodeError('');
        }}
        onVerifyEmail={onVerifyEmail}
        onEnrollMFA={onEnrollMFA}
        isEmailVerified={emailVerified}
        clinicPhoneNumber={clinicPhoneNumber}
        setClinicPhoneNumber={setClinicPhoneNumber}
        isLodingMFADialog={isLodingMFADialog}
        setVerificationCode={setVerificationCode}
        verificationCode={verificationCode}
        isEmailSent={isEmailSent}
        verificationId={verificationId}
        isPhoneError={isPhoneError}
        submitPhoneNumber={submitPhoneNumber}
        isEnrolledSuccessfully={isEnrolledSuccessfully}
        onVerificationCodeSent={onVerificationCodeSent}
        isCodeError={isCodeError}
        activeStep={activeStep}
        onSuccessClose={onSuccessClose}
        isEditPhoneNUmberFlow={isEditPhoneNUmberFlow}
        resendVerificationCode={resendVerificationCode}
        isEmailError={isEmailError}
      />
      <RemoveMFADialog
        isOpen={isRemoveMFADialogOpen}
        onClose={() => {
          setIsRemoveMFADialogOpen(false);
        }}
        removeMFA={removeMFA}
        isRemoveMFAError={isRemoveMFAError}
        isLoadingRemoveMFA={isLoadingRemoveMFA}
      />
      <LogoutMFADialog
        isOpen={shouldShowLogoutBtn}
        onClose={() => {
          setShouldShowLogoutBtn(false);
          setIsLodingMFADialog(false);
        }}
      />
      <div id="auth_scroll" />
    </>
  );
};

MFASettingsBase.propTypes = exact({});

export const MFASettings = React.memo(MFASettingsBase);
MFASettings.displayName = 'MFASettings';
