import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactCodeInput from 'react-code-input';
import styles from './PhoneInput.scss';

const PhoneInputBase = ({ fields, verificationCode, onInputChange }) => {
  const inputEl = useRef(null);

  return (
    <div>
      <ReactCodeInput
        type="number"
        fields={fields}
        inputStyle={{
          width: window.innerWidth < 1200 ? 45 : 65,
          height: 80,
          margin: 0,
          borderBottom: '0.1px solid #5e5e5e',
          borderTop: '0 solid #5e5e5e',
          borderRight: '0 solid #5e5e5e',
          borderLeft: '0 solid #5e5e5e',
          cursor: 'pointer',
          textAlign: 'center',
          fontSize: window.innerWidth < 1200 ? 18 : 36,
          textTransform: 'capitalize',
          marginRight: 10,
        }}
        inputStyleInvalid={{
          color: 'red',
          borderBottom: '1px solid red',
          borderTop: '0 solid #5e5e5e',
          borderRight: '0 solid #5e5e5e',
          borderLeft: '0 solid #5e5e5e',
          width: window.innerWidth < 1200 ? 45 : 65,
          height: 80,
          cursor: 'pointer',
          textAlign: 'center',
          fontSize: window.innerWidth < 1200 ? 18 : 36,
          textTransform: 'capitalize',
          marginRight: 10,
        }}
        value={verificationCode}
        onChange={async value => {
          onInputChange(value);
        }}
        ref={inputEl}
        autoFocus={false}
        className={styles.reactCodeInput}
      />
    </div>
  );
};

PhoneInputBase.propTypes = exact({
  fields: PropTypes.number.isRequired,
  verificationCode: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
});

export const PhoneInput = React.memo(PhoneInputBase);
PhoneInput.displayName = 'PhoneInput';
