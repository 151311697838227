import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
// import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import styles from './DeployAssessment.scss';
import { CheckboxesSelect } from '../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { CustomAlert } from '../../../Core/Components/CustomAlert/CustomAlert';
import { WrapConditionally } from '../../../Core/Components/WrapConditionally.jsx/WrapConditionally';
import { TooltipWrapper } from '../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../Core/Components/TooltipTitle/TooltipTitle';
import { useTranslation } from '../../../Core/hooks/useTranslation';
import { eegMinutesDuration } from '../../../models/assessments/assessments';
import { ScheduleAssessmentsCard } from '../../UserPage/AssessmentsTab/ScheduleAssessmentsCard/ScheduleAssessmentsCard';
import symtpomQuestionnairesIcon from '../../../../assets/symtpom_questionnaires_icon.png';
import eegSensingIcon from '../../../../assets/eeg_sensing_icon.png';
import cptIcon from '../../../../assets/cpt_icon.png';
import symtpomQuestionnairesDisabledIcon from '../../../../assets/symtpom_questionnaires_disabled_icon.png';
import eegSensingDisabledIcon from '../../../../assets/eeg_sensing_disabled_icon.png';
import cptDisabledIcon from '../../../../assets/cpt_disabled_icon.png';
import signalIcon from '../../../../assets/open-audio.png';

const DeployAssessmentBase = ({
  isSymptomQuestionnaireCheckboxDisabledReason,
  isSymptomQuestionnaireChecked,
  isEEGMeasuresChecked,
  isCPTChecked,
  selectedAssessmentOptions,
  onSelectedAssessmentOptionsChange,
  assessmentOptions,
  onIsSymptomQuestionnaireCheckedChange,
  onIsEEGMeasuresCheckedChange,
  onIsCPTCheckedChange,
  selectedQuestionnaires,
  shouldShowSetupBtn,
  onSetupQuestionnaires,
  nfFlag,
  setAddClientReachedLimitModal,
  isHeadsetOnly,
  setIsHeadsetOnly,
  usedAssessmentBeforeJune24,
}) => {
  const t = useTranslation();

  const CPT_DURATION = 8;

  let questionMinDuration = 0;
  selectedQuestionnaires.forEach(element => {
    questionMinDuration += element.questions.length;
  });

  let eegMinsDuration = 0;
  selectedAssessmentOptions.forEach(el => {
    if (eegMinutesDuration[el]) {
      eegMinsDuration += eegMinutesDuration[el] + 2;
    }
  });

  const calcEegCheckboxRenderedValue = () => {
    if (isHeadsetOnly) {
      return 'Headband-Only (Rapid)';
    }
    if (selectedAssessmentOptions.length === assessmentOptions.length) {
      return 'All regions selected';
    }
    if (!selectedAssessmentOptions.length) {
      return 'Recommended regions selected';
    }
    if (
      selectedAssessmentOptions.length === assessmentOptions.length - 1 &&
      !selectedAssessmentOptions.find(region => region === 'Back')
    ) {
      return 'Recommended regions selected';
    }
    return `${selectedAssessmentOptions.length} ${
      selectedAssessmentOptions.length === 1 ? 'region' : 'regions'
    }`;
  };

  const calcEegWarning = () => {
    if (!isEEGMeasuresChecked) {
      return null;
    }
    if (
      usedAssessmentBeforeJune24 &&
      !selectedAssessmentOptions.find(region => region === 'Back')
    ) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    if (selectedAssessmentOptions.find(region => region === 'Back')) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    return null;
  };

  const renderWarningIfSomeOptionsAreDisabled = () => {
    const checkedOptions = [
      isSymptomQuestionnaireChecked,
      isEEGMeasuresChecked,
      isCPTChecked,
    ];
    const enabledOptionsFormattedLabels = [
      'questionnaires',
      'EEG sensing',
      'continuous performance task',
    ];

    let warningMessage = null;
    if (checkedOptions.some(val => !val)) {
      if (checkedOptions.every(val => !val)) {
        warningMessage = 'This client won’t get assessments.';
      } else {
        const disabledOptionsLabels = checkedOptions
          .map((option, index) =>
            option ? '' : enabledOptionsFormattedLabels[index]
          )
          .filter(text => text !== '');
        warningMessage = `Assessments for this client won’t include ${disabledOptionsLabels.join(
          ', '
        )}.`;
      }
    }

    return (
      warningMessage && (
        <div
          className={styles.alert_wrapper}
          style={calcEegWarning() ? { marginTop: 100 } : null}
        >
          <CustomAlert severity="warning">{warningMessage}</CustomAlert>
        </div>
      )
    );
  };
  return (
    <div>
      <p>
        {t('how-to-track-this-client-progress')}{' '}
        <a
          href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn-more-about-assessment')}
        </a>
      </p>

      <div className={styles.cards_container}>
        <WrapConditionally
          condition={!!isSymptomQuestionnaireCheckboxDisabledReason}
          wrap={children => (
            <TooltipWrapper
              title={
                <TooltipTitle>
                  {isSymptomQuestionnaireCheckboxDisabledReason}
                </TooltipTitle>
              }
            >
              <div>{children}</div>
            </TooltipWrapper>
          )}
        >
          <ScheduleAssessmentsCard
            title={t('symptom-questionnaires')}
            cardText={() => (
              <div>
                Standardized questionnaires are brief, multiple choice scales
                for various conditions that have been validated in the
                scientific literature.
                <a
                  target="_blank"
                  href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
                  rel="noopener noreferrer"
                >
                  {' '}
                  See our complete list of questionnaires here.
                </a>
              </div>
            )}
            imgSrc={symtpomQuestionnairesIcon}
            checked={isSymptomQuestionnaireChecked}
            onChange={onIsSymptomQuestionnaireCheckedChange}
            mins={Math.ceil((questionMinDuration * 14) / 60)}
            disabled={isSymptomQuestionnaireCheckboxDisabledReason}
            disabledImgSrc={symtpomQuestionnairesDisabledIcon}
            customerId={null}
            buttomLeft={() => {
              if (shouldShowSetupBtn) {
                return (
                  <div>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={onSetupQuestionnaires}
                      className={styles.setup_questionnaire_btn}
                      style={{ marginBottom: '10px' }}
                    >
                      <span className={styles.setup_questionnaire_btn_text}>
                        Setup
                      </span>
                    </Button>
                  </div>
                );
              }
              return null;
            }}
          />
        </WrapConditionally>

        <ScheduleAssessmentsCard
          title="Continuous performance task"
          cardText={() => (
            <div>
              An 8-minute continuous performance task, during which the user is
              instructed to tap only when the target object is shown. It is used
              to measure sustained attention and inhibition.
              <a
                target="_blank"
                href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
                rel="noopener noreferrer"
              >
                {' '}
                Learn more here.
              </a>
            </div>
          )}
          imgSrc={cptIcon}
          checked={isCPTChecked}
          onChange={onIsCPTCheckedChange}
          mins={CPT_DURATION}
          disabledImgSrc={cptDisabledIcon}
          customerId={null}
          imgLabel={
            <TooltipWrapper
              title={
                <TooltipTitle>
                  This task includes sounds of explosions, aircraft, and car
                  honking, which may be triggering for some individuals.
                </TooltipTitle>
              }
            >
              <div>
                <img
                  src={signalIcon}
                  style={{ width: 15, height: 10, marginRight: 5 }}
                />{' '}
                Trigger warning
              </div>
            </TooltipWrapper>
          }
        />

        <ScheduleAssessmentsCard
          title="EEG sensing"
          cardText={() => (
            <div>
              A meaningful report will be generated based on the users EEG data.
              Unless the "Headband-only" option is selected, the user will be
              instructed to place the electrode on multiple sites.
              <a
                target="_blank"
                href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
                rel="noopener noreferrer"
              >
                {' '}
                Learn more here.
              </a>
            </div>
          )}
          imgSrc={eegSensingIcon}
          checked={isEEGMeasuresChecked}
          onChange={e => {
            if (!nfFlag) {
              setAddClientReachedLimitModal(true);
              return;
            }
            onIsEEGMeasuresCheckedChange(e);
          }}
          mins={isHeadsetOnly ? 5 : Math.ceil(eegMinsDuration)}
          buttomLeft={() => (
            <div className={styles.select_regions_container}>
              <CheckboxesSelect
                selectedOptions={selectedAssessmentOptions}
                allOptions={assessmentOptions}
                onChange={onSelectedAssessmentOptionsChange}
                fullWidth
                inputType="input"
                renderValue={calcEegCheckboxRenderedValue}
                disabled={!nfFlag || !isEEGMeasuresChecked}
                classes={{
                  selectRoot: styles.regions_select_input,
                }}
                selectTitle="Headband + Electrode"
                extraToggleText={
                  <div>
                    Headband-Only (Rapid)
                    <span
                      style={{
                        fontSize: '0.75em',
                        color: '#ffffff',
                        marginLeft: 5,
                        backgroundColor: '#4ce0d0',
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderRadius: 15,
                      }}
                    >
                      BETA
                    </span>
                  </div>
                }
                onExtraToggleChange={e => {
                  setIsHeadsetOnly(e.target.checked);
                }}
                extraToggleValue={isHeadsetOnly}
                isCheckboxesDisabled={isHeadsetOnly}
              />
            </div>
          )}
          disabledImgSrc={eegSensingDisabledIcon}
          footerStyleFlag="true"
          customerId={null}
          isLast="true"
          imgLabel="Hardware required"
          warning={calcEegWarning()}
        />
      </div>
      {renderWarningIfSomeOptionsAreDisabled()}
      <div className={styles.Schedule_assessments_cards_container_footnote}>
        Note: You won’t incur any extra charges when using assessments.
      </div>
    </div>
  );
};

DeployAssessmentBase.propTypes = exact({
  isSymptomQuestionnaireCheckboxDisabledReason: PropTypes.string,
  isSymptomQuestionnaireChecked: PropTypes.bool.isRequired,
  isEEGMeasuresChecked: PropTypes.bool.isRequired,
  isCPTChecked: PropTypes.bool.isRequired,
  selectedAssessmentOptions: PropTypes.array.isRequired,
  onSelectedAssessmentOptionsChange: PropTypes.func.isRequired,
  assessmentOptions: PropTypes.array.isRequired,
  onIsSymptomQuestionnaireCheckedChange: PropTypes.func.isRequired,
  onIsEEGMeasuresCheckedChange: PropTypes.func.isRequired,
  onIsCPTCheckedChange: PropTypes.func.isRequired,
  selectedQuestionnaires: PropTypes.array,
  shouldShowSetupBtn: PropTypes.bool,
  onSetupQuestionnaires: PropTypes.func,
  nfFlag: PropTypes.bool,
  setAddClientReachedLimitModal: PropTypes.func,
  isHeadsetOnly: PropTypes.bool,
  setIsHeadsetOnly: PropTypes.func,
  usedAssessmentBeforeJune24: PropTypes.bool,
});

export const DeployAssessment = React.memo(DeployAssessmentBase);
DeployAssessment.displayName = 'DeployAssessment';
