import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Editor } from 'react-draft-wysiwyg';
// import htmlToDraft from 'html-to-draftjs';
// import draftToHtml from 'draftjs-to-html';
// import { SelectionState, ContentState, EditorState, convertToRaw } from 'draft-js';
import styles from './TextEditorCard.scss';

const TextEditorCardBase = ({
  content,
  updateTextEditorCardContentState,
  isInPreview,
  isModalOpen,
}) => {
  return (
    <div className={styles.root}>
      {isInPreview ? (
        <p dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <>
          <div className={styles.editor_container}>
            <Editor
              readOnly={isModalOpen}
              toolbarHidden={isModalOpen}
              editorState={content}
              onEditorStateChange={editorState => {
                updateTextEditorCardContentState(editorState);
              }}
              toolbar={{
                options: ['inline', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                link: {
                  popupClassName: styles.popupInDraft,
                  defaultTargetOption: '_self',
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
              }}
              editorClassName={styles.editorClassName}
              wrapperClassName={styles.wrapperClassName}
              toolbarClassName={styles.toolbarClassName}
              handlePastedText={() => false}
            />
          </div>
        </>
      )}
    </div>
  );
};

TextEditorCardBase.defaultProps = {
  isInPreview: false,
  isModalOpen: false,
};

TextEditorCardBase.propTypes = exact({
  content: PropTypes.object.isRequired,
  updateTextEditorCardContentState: PropTypes.func,
  isInPreview: PropTypes.bool,
  isModalOpen: PropTypes.bool,
});

export const TextEditorCard = React.memo(TextEditorCardBase);
TextEditorCard.displayName = 'TextEditorCard';
