import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import xor from 'lodash/xor';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import GenderIcon from '@material-ui/icons/Wc';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  FormControl,
  Chip,
  NativeSelect,
  // FormControlLabel,
} from '@material-ui/core';
import styles from './ScheduleAssessmentDialog.scss';
import { useTranslation } from '../../../../Core/hooks/useTranslation';
import { paragraphFontSize } from '../../../../cssInJs/constants';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import { RightDownArrow } from '../../../../Core/Icons/RightDownArrow';
import { GENDERS } from '../../../../models/person/person';
import { eegMinutesDuration } from '../../../../models/assessments/assessments';
// import { WrapConditionally } from '../../../../Core/Components/WrapConditionally.jsx/WrapConditionally';
import { TooltipWrapper } from '../../../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../../../Core/Components/TooltipTitle/TooltipTitle';
import { CustomAlert } from '../../../../Core/Components/CustomAlert/CustomAlert';
import { CheckboxesSelect } from '../../../../Core/Components/CheckboxesSelect/CheckboxesSelect';
import { isAnEEGRegionStage } from '../../../../models/symptom-tracker/symptom-tracker';
import { ScheduleAssessmentsCard } from '../ScheduleAssessmentsCard/ScheduleAssessmentsCard';
import symtpomQuestionnairesIcon from '../../../../../assets/symtpom_questionnaires_icon.png';
import eegSensingIcon from '../../../../../assets/eeg_sensing_icon.png';
import cptIcon from '../../../../../assets/cpt_icon.png';
import symtpomQuestionnairesDisabledIcon from '../../../../../assets/symtpom_questionnaires_disabled_icon.png';
import eegSensingDisabledIcon from '../../../../../assets/eeg_sensing_disabled_icon.png';
import cptDisabledIcon from '../../../../../assets/cpt_disabled_icon.png';
import signalIcon from '../../../../../assets/open-audio.png';

export const SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS = {
  disorderTagsConfirm: 'disorderTagsConfirm',
  ageAndGenderConfirm: 'ageAndGenderConfirm',
  assessmentOptions: 'assessmentOptions',
};

const ScheduleAssessmentDialogBase = ({
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  isOpen,
  isPrimaryBtnDisabled,
  selectedStages,
  // afterTrainingDuration,
  // onAfterTrainingDurationChange,
  isRecurringAssessmentChecked,
  onRecurringAssessmentCheckboxChange,
  isBeforeNextSessionAssessmentChecked,
  onBeforeNextSessionCheckboxChange,
  onSelectedStagesChange,
  renderedStep,
  brainDisorders,
  selectedBrainDisorders,
  onDisorderClick,
  customerGender,
  customerDateOfBirth,
  onCustomerGenderChange,
  onCustomerDateOfBirthChange,
  scheduleAssessmentDialogErrorMsg,
  primaryBtnText,
  secondaryBtnText,
  onClose,
  questionnairesStageDisabledReason,
  areEEGRegionsEnabled,
  onEEGRegionsCheckboxChange,
  shouldUseNewSymptomTracker,
  onSetupQuestionnaires,
  shouldShowSetupQuestionnairesButton,
  assessmentStagesInfo,
  frequency,
  intervalType,
  onFrequencyChange,
  onIntervalTypeChange,
  deployedQuestionnaires,
  doneAssessments,
  customerId,
  nfFlag,
  isHeadsetOnly,
  setIsHeadsetOnly,
  usedAssessmentBeforeJune24,
}) => {
  const t = useTranslation();

  const CPT_DURATION = 8;

  const isSymptomQuestionnaireChecked = selectedStages.includes(
    assessmentStagesInfo.ids.QUESTIONNAIRES
  );
  const isCPTChecked = selectedStages.includes(assessmentStagesInfo.ids.CPT);
  const isEEGChecked =
    selectedStages.some(stage =>
      isAnEEGRegionStage(stage, assessmentStagesInfo.eegIds)
    ) || isHeadsetOnly;

  const eegRegions = Object.values(assessmentStagesInfo.eegIds);
  const selectedEEGRegions = Object.values(selectedStages).filter(stage =>
    eegRegions.includes(stage)
  );
  const allEEGRegionsOptions = Object.entries(
    assessmentStagesInfo.eegNames
  ).map(([value, label]) => ({ value, label }));

  let questionsMins = 0;
  deployedQuestionnaires.forEach(element => {
    if (element && element.questions) {
      questionsMins += element.questions.length;
    }
  });

  let eegMinutes = 0;
  selectedStages.forEach(el => {
    if (eegMinutesDuration[el]) {
      eegMinutes += eegMinutesDuration[el] + 2;
    }
  });

  const calcEegWarning = () => {
    if (!areEEGRegionsEnabled || isHeadsetOnly) {
      return null;
    }
    if (
      usedAssessmentBeforeJune24 &&
      !selectedEEGRegions.find(region => region === 'Back')
    ) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    if (selectedEEGRegions.find(region => region === 'Back')) {
      return 'Due to its challenging placement, O1 is no longer included by default in the EEG sensing. Client guidance is recommended when including O1.';
    }
    return null;
  };

  const renderDisorderTagsConfirmStep = () => (
    <React.Fragment>
      <h3 className={styles.dialog_header}>
        Please select the tags that describe this client’s needs for
        neurofeedback. This is a required step as it may impact the results of
        the assessment.
      </h3>

      <div className={styles.brain_disorders_container}>
        {brainDisorders.map(brainDisorder => (
          <div key={brainDisorder.name}>
            <Chip
              classes={{
                label: styles.chip_label,
                root: classNames({
                  [styles.chip_selected_root]: selectedBrainDisorders.includes(
                    brainDisorder.name
                  ),
                }),
              }}
              label={brainDisorder.name}
              onClick={() => onDisorderClick(brainDisorder.name)}
            />
          </div>
        ))}
        {scheduleAssessmentDialogErrorMsg && (
          <p className={styles.error_msg}>{scheduleAssessmentDialogErrorMsg}</p>
        )}
      </div>
    </React.Fragment>
  );

  const renderAgeAndGenderConfirmStep = () => {
    // eslint-disable-next-line react/prop-types
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
      <TextField
        placeholder={t('date-of-birth')}
        inputProps={{
          style: { fontSize: paragraphFontSize },
        }}
        value={value}
        onClick={onClick}
        innerRef={ref}
      />
    ));

    return (
      <React.Fragment>
        <h3 className={styles.dialog_header}>
          Please confirm age and gender. This is a required step as it may
          impact the results of the assessment.
        </h3>
        <div className={styles.age_and_gender_inputs}>
          <div className={styles.input_container}>
            <div className={styles.input_container_icon}>
              <GenderIcon />
            </div>
            <FormControl
              classes={{
                root: styles.mu_form_control_root,
              }}
            >
              <NativeSelect
                classes={{
                  icon: styles.mu_native_select_icon,
                  root: styles.mu_select_menu,
                }}
                IconComponent={RightDownArrow}
                value={customerGender}
                onChange={onCustomerGenderChange}
              >
                {Object.entries(GENDERS).map(([genderKey, genderValue]) => (
                  <option key={genderKey} value={genderValue}>
                    {capitalizeFirstLetter(t(genderValue.toLowerCase()))}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>

          <FormControl className={styles.date_picker_form_control}>
            <div className={styles.date_picker_container}>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={onCustomerDateOfBirthChange}
                selected={customerDateOfBirth}
                filterDate={date => date.getTime() < new Date().getTime()}
                yearDropdownItemNumber={7}
                customInput={<DatePickerCustomInput />}
                popperPlacement="left"
              />
            </div>
          </FormControl>
        </div>
        <div>
          {scheduleAssessmentDialogErrorMsg && (
            <p className={styles.error_msg}>
              {scheduleAssessmentDialogErrorMsg}
            </p>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderWarningIfSomeOptionsAreDisabled = () => {
    const enabledOptions = [
      isSymptomQuestionnaireChecked,
      isEEGChecked,
      isCPTChecked,
    ];
    const enabledOptionsFormattedLabels = [
      'questionnaires',
      'EEG sensing',
      'continuous performance task',
    ];

    let warningMessage = null;
    if (enabledOptions.some(val => !val)) {
      if (enabledOptions.every(val => !val)) {
        warningMessage = 'This client won’t get assessments.';
      } else {
        const disabledOptionsLabels = enabledOptions
          .map((option, index) =>
            option ? '' : enabledOptionsFormattedLabels[index]
          )
          .filter(text => text !== '');
        warningMessage = `Assessments for this client won't include ${disabledOptionsLabels.join(
          ', '
        )}.`;
      }
    }

    return (
      warningMessage && (
        <div
          className={styles.alert_wrapper}
          style={calcEegWarning() ? { marginTop: 80 } : null}
        >
          <CustomAlert severity="warning">{warningMessage}</CustomAlert>
        </div>
      )
    );
  };

  const calcEegCheckboxRenderedValue = () => {
    if (isHeadsetOnly) {
      return 'Headband-Only (Rapid)';
    }
    if (selectedEEGRegions.length === allEEGRegionsOptions.length) {
      return 'All regions selected';
    }
    if (!selectedEEGRegions.length) {
      return 'Recommended regions selected';
    }
    if (
      selectedEEGRegions.length === allEEGRegionsOptions.length - 1 &&
      !selectedEEGRegions.find(region => region === 'Back')
    ) {
      return 'Recommended regions selected';
    }
    return `${selectedEEGRegions.length} ${
      selectedEEGRegions.length === 1 ? 'region' : 'regions'
    }`;
  };

  const renderAssessmentOptionsStep = () => (
    <React.Fragment>
      <h3 className={styles.dialog_header}>
        {t('when_to_schedule_assessment')}
      </h3>
      <div className={styles.switches_container}>
        <div>
          {/* <Checkbox
            checked={isRecurringAssessmentChecked}
            onChange={onRecurringAssessmentCheckboxChange}
            color="primary"
          /> */}
          <div className={styles.recurring_container}>
            <NativeSelect
              classes={{
                icon: styles.mu_native_select_icon,
                root: styles.mu_select_menu,
              }}
              IconComponent={ArrowDropDownIcon}
              value={isRecurringAssessmentChecked}
              onChange={onRecurringAssessmentCheckboxChange}
            >
              <option value="false">Once</option>
              <option value="true">Recurring</option>
            </NativeSelect>
          </div>
          {isRecurringAssessmentChecked && (
            <div className={styles.input_container}>
              <p>
                <span>every</span>
              </p>
              <TextField
                id="standard-basic"
                margin="normal"
                onChange={onFrequencyChange}
                value={frequency}
                inputProps={{
                  min: 1,
                  type: 'number',
                  style: {
                    fontSize: paragraphFontSize,
                    width: 60,
                  },
                }}
              />

              <NativeSelect
                classes={{
                  icon: styles.mu_native_select_icon,
                  root: styles.mu_select_menu,
                }}
                IconComponent={ArrowDropDownIcon}
                value={intervalType}
                onChange={onIntervalTypeChange}
              >
                <option value="" disabled selected={!intervalType}>
                  Choose from below
                </option>
                <option value="days">days</option>
                <option value="weeks">weeks</option>
                <option value="months">months</option>
                {nfFlag ? (
                  <option value="minutes">minutes of neurofeedback</option>
                ) : null}
                {nfFlag ? (
                  <option value="sessions">sessions of neurofeedback</option>
                ) : null}
              </NativeSelect>
            </div>
          )}
          {(!isRecurringAssessmentChecked ||
            (!(intervalType === 'minutes' || intervalType === 'sessions') &&
              !doneAssessments)) && ( // if day/week/month and not done assasement
            <p className={styles.after_select_text}>
              starting on next app access
            </p>
          )}
          {!(intervalType === 'minutes' || intervalType === 'sessions') &&
            !!doneAssessments &&
            isRecurringAssessmentChecked && (
              <div className={styles.recurring_container}>
                <NativeSelect
                  classes={{
                    icon: styles.mu_native_select_icon,
                    root: styles.mu_select_menu,
                  }}
                  IconComponent={ArrowDropDownIcon}
                  value={isBeforeNextSessionAssessmentChecked}
                  onChange={e =>
                    onBeforeNextSessionCheckboxChange(
                      e,
                      JSON.parse(e.target.value)
                    )
                  }
                >
                  <option value="false">since last saved assessment</option>
                  <option value="true">starting on next app access</option>
                </NativeSelect>
              </div>
            )}
        </div>
        {isRecurringAssessmentChecked &&
          (intervalType === 'minutes' || intervalType === 'sessions') && (
            <div>
              <Checkbox
                checked={isBeforeNextSessionAssessmentChecked}
                onChange={onBeforeNextSessionCheckboxChange}
                color="primary"
                style={{ paddingLeft: 0, left: '-3px' }}
              />
              <div className={styles.input_container}>
                <p>
                  <span>Starting on next app access</span>
                </p>
              </div>
            </div>
          )}
        <div className={styles.select_stages_container}>
          <h3 className={styles.dialog_header}>
            {t('what-to-include-assessment')}
          </h3>
          <div className={styles.assessments_cards_container}>
            {/* <WrapConditionally
              condition={!!questionnairesStageDisabledReason}
              wrap={children => (
                <TooltipWrapper
                  title={
                    <TooltipTitle>
                      {questionnairesStageDisabledReason}
                    </TooltipTitle>
                  }
                >
                  <div>{children}</div>
                </TooltipWrapper>
              )}
            > */}
            <ScheduleAssessmentsCard
              title="Symptom questionnaries"
              cardText={() => (
                <div>
                  Standardized questionnaires are brief, multiple choice scales
                  for various conditions that have been validated in the
                  scientific literature.
                  <a
                    target="_blank"
                    href="https://intercom.help/dashboard-myndlift/en/articles/4488496-symptom-questionnaires-for-tracking-progress"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    See our complete list of questionnaires here.
                  </a>
                </div>
              )}
              imgSrc={symtpomQuestionnairesIcon}
              checked={
                !questionnairesStageDisabledReason &&
                isSymptomQuestionnaireChecked
              }
              onChange={() => {
                if (shouldShowSetupQuestionnairesButton) {
                  onSetupQuestionnaires();
                } else {
                  onSelectedStagesChange(
                    xor(selectedStages, [
                      assessmentStagesInfo.ids.QUESTIONNAIRES,
                    ])
                  );
                }
              }}
              buttomLeft={() =>
                shouldShowSetupQuestionnairesButton ? null : (
                  <div>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={onSetupQuestionnaires}
                      className={styles.setup_questionnaire_btn}
                    >
                      <span className={styles.setup_questionnaire_btn_text}>
                        Edit
                      </span>
                    </Button>
                  </div>
                )
              }
              mins={Math.ceil((questionsMins * 4) / 60)}
              // disabled={questionnairesStageDisabledReason}
              disabledImgSrc={symtpomQuestionnairesDisabledIcon}
              customerId={customerId}
              smallCard
            />
            {/* </WrapConditionally> */}
            <ScheduleAssessmentsCard
              title="Continuous performance task"
              cardText={() => (
                <div>
                  An 8-minute continuous performance task, during which the user
                  is instructed to tap only when the target object is shown. It
                  is used to measure sustained attention and inhibition.
                  <a
                    target="_blank"
                    href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Learn more here.
                  </a>
                </div>
              )}
              imgSrc={cptIcon}
              checked={isCPTChecked}
              onChange={() => {
                onSelectedStagesChange(
                  xor(selectedStages, [assessmentStagesInfo.ids.CPT])
                );
              }}
              mins={CPT_DURATION}
              disabledImgSrc={cptDisabledIcon}
              customerId={customerId}
              smallCard
              imgLabel={
                <TooltipWrapper
                  title={
                    <TooltipTitle>
                      This task includes sounds of explosions, aircraft, and car
                      honking, which may be triggering for some individuals.
                    </TooltipTitle>
                  }
                >
                  <div>
                    <img
                      src={signalIcon}
                      style={{ width: 15, height: 10, marginRight: 5 }}
                    />
                    Trigger warning
                  </div>
                </TooltipWrapper>
              }
            />

            <ScheduleAssessmentsCard
              title="EEG sensing"
              cardText={() => (
                <div>
                  A meaningful report will be generated based on the users EEG
                  data. Unless the "Headband-only" option is selected, the user
                  will be instructed to place the electrode on multiple sites.
                  <a
                    target="_blank"
                    href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Learn more here.
                  </a>
                </div>
              )}
              imgSrc={eegSensingIcon}
              checked={areEEGRegionsEnabled}
              onChange={onEEGRegionsCheckboxChange}
              buttomLeft={() => (
                <div
                  className={styles.select_regions_container}
                  style={{ marginBottom: '8px' }}
                >
                  <CheckboxesSelect
                    selectedOptions={selectedEEGRegions}
                    allOptions={allEEGRegionsOptions}
                    onChange={event => {
                      if (event.target.value.length > 0) {
                        onSelectedStagesChange([
                          ...selectedStages.filter(
                            stage =>
                              !isAnEEGRegionStage(
                                stage,
                                assessmentStagesInfo.eegIds
                              )
                          ),
                          ...event.target.value,
                        ]);
                      }
                    }}
                    fullWidth
                    inputType="input"
                    renderValue={calcEegCheckboxRenderedValue}
                    disabled={!areEEGRegionsEnabled}
                    classes={{
                      selectRoot: styles.regions_select_input,
                    }}
                    displayEmpty="true"
                    selectTitle="Headband + Electrode"
                    extraToggleText={
                      <div>
                        Headband-Only (Rapid)
                        <span
                          style={{
                            fontSize: '0.75em',
                            color: '#ffffff',
                            marginLeft: 5,
                            backgroundColor: '#4ce0d0',
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderRadius: 15,
                          }}
                        >
                          BETA
                        </span>
                      </div>
                    }
                    onExtraToggleChange={e => {
                      setIsHeadsetOnly(e.target.checked);
                    }}
                    extraToggleValue={isHeadsetOnly}
                    isCheckboxesDisabled={isHeadsetOnly}
                  />
                </div>
              )}
              mins={isHeadsetOnly ? 5 : Math.ceil(eegMinutes) || 14}
              disabledImgSrc={eegSensingDisabledIcon}
              customerId={customerId}
              imgLabel="Hardware required"
              isLast="true"
              smallCard
              warning={calcEegWarning()}
            />
          </div>
          <div />
          {shouldUseNewSymptomTracker &&
            renderWarningIfSomeOptionsAreDisabled()}
          <div style={{ marginTop: 20 }}>
            Note: You won’t incur any extra charges when using assessments.
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <DialogContent
        className={
          shouldUseNewSymptomTracker ? styles.wide_content : styles.content
        }
      >
        {renderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.assessmentOptions &&
          renderAssessmentOptionsStep()}
        {renderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.disorderTagsConfirm &&
          renderDisorderTagsConfirmStep()}
        {renderedStep ===
          SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS.ageAndGenderConfirm &&
          renderAgeAndGenderConfirmStep()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onSecondaryBtnClick} variant="text" color="default">
          <span className={styles.btn}>{secondaryBtnText}</span>
        </Button>
        <Button
          onClick={onPrimaryBtnClick}
          color="primary"
          variant="contained"
          disabled={isPrimaryBtnDisabled}
        >
          <span className={styles.btn}>{primaryBtnText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ScheduleAssessmentDialogBase.propTypes = exact({
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPrimaryBtnDisabled: PropTypes.bool.isRequired,
  // onAfterTrainingDurationChange: PropTypes.func.isRequired,
  // afterTrainingDuration: PropTypes.number,
  isRecurringAssessmentChecked: PropTypes.bool,
  onRecurringAssessmentCheckboxChange: PropTypes.func.isRequired,
  isBeforeNextSessionAssessmentChecked: PropTypes.bool,
  onBeforeNextSessionCheckboxChange: PropTypes.func.isRequired,
  onSelectedStagesChange: PropTypes.func.isRequired,
  selectedStages: PropTypes.array.isRequired,
  renderedStep: PropTypes.oneOf(
    Object.values(SCHEDULE_ASSESSMENT_DIALOG_RENDERED_STEPS)
  ).isRequired,
  brainDisorders: PropTypes.array.isRequired,
  selectedBrainDisorders: PropTypes.array.isRequired,
  onDisorderClick: PropTypes.func.isRequired,
  scheduleAssessmentDialogErrorMsg: PropTypes.string,
  customerGender: PropTypes.string,
  customerDateOfBirth: PropTypes.object,
  onCustomerGenderChange: PropTypes.func.isRequired,
  onCustomerDateOfBirthChange: PropTypes.func.isRequired,
  primaryBtnText: PropTypes.string.isRequired,
  secondaryBtnText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  questionnairesStageDisabledReason: PropTypes.string,
  areEEGRegionsEnabled: PropTypes.bool.isRequired,
  onEEGRegionsCheckboxChange: PropTypes.func.isRequired,
  shouldUseNewSymptomTracker: PropTypes.bool.isRequired,
  onSetupQuestionnaires: PropTypes.func.isRequired,
  shouldShowSetupQuestionnairesButton: PropTypes.bool.isRequired,
  assessmentStagesInfo: PropTypes.object.isRequired,
  frequency: PropTypes.number.isRequired,
  intervalType: PropTypes.string,
  onFrequencyChange: PropTypes.func.isRequired,
  onIntervalTypeChange: PropTypes.func.isRequired,
  deployedQuestionnaires: PropTypes.array,
  doneAssessments: PropTypes.bool,
  customerId: PropTypes.string,
  nfFlag: PropTypes.bool,
  isHeadsetOnly: PropTypes.bool,
  setIsHeadsetOnly: PropTypes.func,
  usedAssessmentBeforeJune24: PropTypes.bool,
});

export const ScheduleAssessmentDialog = React.memo(
  ScheduleAssessmentDialogBase
);
ScheduleAssessmentDialog.displayName = 'ScheduleAssessmentDialog';
