exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TextEditorCard_root{margin-top:35px}.TextEditorCard_editor_container{margin-left:10px;margin-right:10px;border-radius:10px;border:1px #a2a2a2 solid;padding:5px}.TextEditorCard_editorClassName{font-size:14px !important;color:black !important;order:1 !important;min-height:200px !important;padding-left:20px !important;padding-right:20px !important}.TextEditorCard_wrapperClassName{display:flex !important;flex-direction:column !important;justify-content:flex-end !important}.TextEditorCard_toolbarClassName{order:2 !important}.TextEditorCard_popupInDraft{top:-240px !important;height:235px !important}.TextEditorCard_popupInDraft label:nth-child(5){display:none !important}\n", ""]);

// Exports
exports.locals = {
	"root": "TextEditorCard_root",
	"editor_container": "TextEditorCard_editor_container",
	"editorClassName": "TextEditorCard_editorClassName",
	"wrapperClassName": "TextEditorCard_wrapperClassName",
	"toolbarClassName": "TextEditorCard_toolbarClassName",
	"popupInDraft": "TextEditorCard_popupInDraft"
};