import React, { useState, useEffect } from 'react';
import exact from 'prop-types-exact';
import {
  Paper,
  Popover,
  Checkbox,
  Switch,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import {
  getCurrentURLParams,
  postToFunctionsWithDefaultData,
} from '../../utils/utils';
import { EegGraph } from './EegGraph';
// import eegData from './assessment_eeg.json';
import styles from './EegAnalysis.scss';

// const EegAnalysisLogger = new MyEventWrapper('eeg_analysis');

// const initialValue = Symbol('iv');
const CHANNELS_MARGIN = 150;
const CHANNELS_MARGIN_FREQ = 1;
const EEG_POINTS_IN_SECOND = 128;
const EEG_POINTS_IN_SECOND_FREQ = 4;

const stagesSelector = {
  central: {
    channels: ['cz'],
    displayName: 'Central',
  },
  back: {
    channels: ['o1'],
    displayName: 'Back',
  },
  left: {
    channels: ['f3'],
    displayName: 'Left',
  },
  right: {
    channels: ['f4'],
    displayName: 'Right',
  },
  frontal: {
    channels: ['fz'],
    displayName: 'Frontal',
  },
  headset_frontal: {
    channels: ['af7', 'af8'],
    displayName: 'Headband frontal',
  },
  headset_temporal: {
    channels: ['tp9', 'tp10'],
    displayName: 'Headband temporal',
  },
};

const EegAnalysisBase = () => {
  const [eegData, setEegData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [channels, setChannels] = useState({});
  const [channelsToSelect, setChannelsToSelect] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModalState, setFilterModalState] = useState('');
  const [eyesClosedChecked, setEyesClosedChecked] = useState(true);
  const [eyesOpenChecked, setEyesOpenChecked] = useState(true);
  const [eyesClosedCheckedToSelect, setEyesClosedCheckedToSelect] = useState(
    true
  );
  const [eyesOpenCheckedToSelect, setEyesOpenCheckedToSelect] = useState(true);
  const [data, setData] = useState(null);
  const [labels, setLabels] = useState({});
  const [freqsData, setFreqsData] = useState({});
  const [maxLengthLabel, setMaxLengthLabel] = useState(12);

  const [perChannelData, setPerChannelData] = useState(null);
  const [perChannelLabels, setPerChannelLabels] = useState(null);

  const [selectedTab, setSelectedTab] = useState('full');
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [maxLengthLabelFreq, setMaxLengthLabelFreq] = useState(12);
  const [filteredStagesSelector, setFilteredStagesSelector] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);

  const showFullAssessmentToggle = selectedTab === 'full';

  const filterModalDisplayClicked = (modalName, e) => {
    setAnchorEl(e.currentTarget);
    if (filterModalState === modalName) {
      setFilterModalState('');
    } else {
      setFilterModalState(modalName);
    }
  };

  const clearChannelFilter = () => {
    const copyChannels = { ...channels };
    Object.keys(copyChannels).forEach(channel => {
      copyChannels[channel] = true;
    });
    setChannels(copyChannels);
    setChannelsToSelect(copyChannels);
  };

  const resetEyesStateFilter = () => {
    setEyesClosedChecked(true);
    setEyesClosedCheckedToSelect(true);
    setEyesOpenChecked(true);
    setEyesOpenCheckedToSelect(true);
  };

  useEffect(() => {
    if (!selectedChannel) {
      return;
    }
    const freqGraphData = [];
    let channelIndex = 0;
    const labelsObj = {};
    let maxLengthLabelVar = 0;
    Object.keys(freqsData[selectedChannel]).forEach(freq => {
      const newDataEntry = {
        type: 'line',
        name: freq,
        showInLegend: false,
        dataPoints: [],
        yValueFormatString: freq,
      };
      freqsData[selectedChannel][freq].forEach((eegVal, index) => {
        newDataEntry.dataPoints.push({
          x: index,
          y: eegVal + channelIndex * CHANNELS_MARGIN_FREQ,
        });
      });
      labelsObj[channelIndex * CHANNELS_MARGIN_FREQ] = freq;
      if (freq.length > maxLengthLabelVar) {
        maxLengthLabelVar = freq.length;
      }
      channelIndex += 1;
      freqGraphData.push(newDataEntry);
    });
    setPerChannelData(freqGraphData);
    setPerChannelLabels(labelsObj);
    setMaxLengthLabelFreq(maxLengthLabelVar);
  }, [selectedChannel]);

  useEffect(() => {
    (async () => {
      const { customerId, assessmentId } = getCurrentURLParams();
      try {
        const res = await postToFunctionsWithDefaultData({
          path: 'get_assessment_eeg',
          data: {
            userId: sessionStorage.userId,
            patientId: customerId,
            assessmentId,
          },
        });
        setEegData(res);
        setIsFetchingData(false);
      } catch (e) {
        setIsFetchingData(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    const channelsArray = [];
    eegData.forEach(eegEntry => {
      if (channelsArray.indexOf(eegEntry.channel) === -1) {
        channelsArray.push(eegEntry.channel);
      }
    });
    const channelsCopy = {};
    channelsArray.forEach(channel => {
      channelsCopy[channel] = true;
    });
    setChannels(channelsCopy);
    setChannelsToSelect(channelsCopy);

    const stagesToDisplay = Object.keys(stagesSelector).filter(stageName => {
      for (let i = 0; i < Object.keys(channelsCopy).length; i += 1) {
        if (
          stagesSelector[stageName].channels.find(
            channel =>
              channel.toLowerCase() ===
              Object.keys(channelsCopy)[i].toLowerCase()
          )
        ) {
          return true;
        }
      }
      return false;
    });

    setFilteredStagesSelector(stagesToDisplay);
    setSelectedStage(stagesToDisplay[0]);
  }, [eegData]);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    let channelIndex = 0;
    const labelsObj = {};
    const freqsObj = {};
    let maxLengthLabelVar = 0;
    eegData.forEach(eegObject => {
      if (
        channels[eegObject.channel] &&
        ((eegObject.state === 'Eyes Closed' && eyesClosedChecked) ||
          (eegObject.state === 'Eyes Open' && eyesOpenChecked))
      ) {
        labelsObj[
          channelIndex * CHANNELS_MARGIN
        ] = `${eegObject.channel} ${eegObject.state} ${channelIndex}`;
        freqsObj[`${eegObject.channel} ${eegObject.state} ${channelIndex}`] =
          eegObject.freqs;
        channelIndex += 1;
        if (
          `${eegObject.channel} ${eegObject.state} ${channelIndex}`.length - 2 >
          maxLengthLabelVar
        ) {
          maxLengthLabelVar =
            `${eegObject.channel} ${eegObject.state} ${channelIndex}`.length -
            2;
        }
      }
    });
    setLabels(labelsObj);
    setFreqsData(freqsObj);
    setSelectedChannel(Object.values(labelsObj)[0]);
    setMaxLengthLabel(maxLengthLabelVar);
  }, [channels, eyesClosedChecked, eyesOpenChecked]);

  useEffect(() => {
    if (!eegData) {
      return;
    }
    const dataVar = [];
    const labelsObj = {};
    let channelIndex = 0;

    eegData.forEach(eegObject => {
      if (
        channels[eegObject.channel] &&
        ((eegObject.state === 'Eyes Closed' && eyesClosedChecked) ||
          (eegObject.state === 'Eyes Open' && eyesOpenChecked))
      ) {
        const newDataEntry = {
          type: 'line',
          name: `${eegObject.channel} ${eegObject.state} ${channelIndex}`,
          showInLegend: false,
          dataPoints: [],
          yValueFormatString: `${eegObject.channel} ${eegObject.state} ${channelIndex}`,
        };
        eegObject.eeg.forEach((eegVal, index) => {
          newDataEntry.dataPoints.push({
            x: index,
            y: eegVal + channelIndex * CHANNELS_MARGIN,
          });
        });
        dataVar.push(newDataEntry);
        labelsObj[
          channelIndex * CHANNELS_MARGIN
        ] = `${eegObject.channel} ${eegObject.state} ${channelIndex}`;
        channelIndex += 1;
      }
    });
    setData(dataVar);
  }, [labels, channels, eyesClosedChecked, eyesOpenChecked, eegData]);

  const calcLeft = () => {
    return (
      105 +
      (showFullAssessmentToggle ? 10 : 11) *
        (showFullAssessmentToggle ? maxLengthLabel : maxLengthLabelFreq)
    );
  };

  const calcWidth = () => {
    return `calc(100% - ${(showFullAssessmentToggle ? 115 : 165) +
      (showFullAssessmentToggle ? 10 : 11) *
        (showFullAssessmentToggle ? maxLengthLabel : maxLengthLabelFreq)}px)`;
  };

  if (isFetchingData || !perChannelData) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
        <div style={{ marginTop: 10 }}>Fetching data</div>
      </div>
    );
  }

  if (!isFetchingData && (!eegData || !eegData.length)) {
    return <div style={{ textAlign: 'center' }}>No eeg records found</div>;
  }
  // eslint-disable-next-line consistent-return
  return (
    <div className={styles.page_container}>
      <Paper
        style={{
          // padding: 20,
          position: 'relative',
          left: calcLeft(),
          width: calcWidth(),
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(event, newCurrentTab) => {
            if (showFullAssessmentToggle) {
              const newFreqToSelect = Object.values(labels).find(freq =>
                stagesSelector[selectedStage].channels.find(channel =>
                  freq.toLowerCase().includes(channel)
                )
              );
              setSelectedChannel(newFreqToSelect);
            }
            setSelectedTab(newCurrentTab);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab value="full" label="Visible EEG" style={{ fontSize: 13 }} />
          <Tab
            value="channel"
            label="Frequency Bands"
            style={{ fontSize: 13 }}
          />
        </Tabs>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 20,
            // justifyContent: 'space-between'
          }}
        >
          {showFullAssessmentToggle ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
              }}
            >
              <div className={styles.filters_container}>
                <div
                  onClick={e => {
                    filterModalDisplayClicked('channel-filter', e);
                  }}
                  className={styles.filter_btn}
                  style={
                    Object.keys(channels).find(
                      channelName => !channels[channelName]
                    )
                      ? { backgroundColor: '#2196f3', color: 'white' }
                      : null
                  }
                >
                  Channel
                  {Object.keys(channels).find(
                    channelName => !channels[channelName]
                  ) ? (
                    <CloseIcon
                      onClick={e => {
                        e.stopPropagation();
                        clearChannelFilter();
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </div>
                <Popover
                  id="channel-filter"
                  open={filterModalState === 'channel-filter'}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setFilterModalState('');
                    setChannelsToSelect(channels);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{ paper: styles.popover_paper }}
                >
                  <div className={styles.popover_title}>Channel</div>
                  <div className={styles.popover_reset}>
                    Select all
                    <Switch
                      color="primary"
                      onClick={e => {
                        const copy = { ...channelsToSelect };
                        // eslint-disable-next-line array-callback-return
                        Object.keys(channelsToSelect).map(channel => {
                          copy[channel] = e.target.checked;
                        });
                        setChannelsToSelect(copy);
                      }}
                      // value={Object.keys(channelsToSelect).find(channel=> !channelsToSelect[channel])}
                    />
                  </div>
                  {Object.keys(channelsToSelect).map(channel => {
                    return (
                      <div>
                        <Checkbox
                          onClick={() => {
                            const copy = { ...channelsToSelect };
                            copy[channel] = !channelsToSelect[channel];
                            setChannelsToSelect(copy);
                          }}
                          checked={!!channelsToSelect[channel]}
                          color="primary"
                        />
                        {channel}
                      </div>
                    );
                  })}
                  <div className={styles.filter_btn_container}>
                    <Button
                      onClick={() => {
                        setChannels(channelsToSelect);
                        setFilterModalState('');
                      }}
                      color="primary"
                      variant="contained"
                      style={{ fontSize: 14 }}
                      disabled={
                        !Object.keys(channelsToSelect).find(
                          channel =>
                            channelsToSelect[channel] !== channels[channel]
                        )
                      }
                    >
                      Apply
                    </Button>
                  </div>
                </Popover>
                <div
                  onClick={e => {
                    filterModalDisplayClicked('eyes-state', e);
                  }}
                  className={styles.filter_btn}
                  style={
                    eyesClosedChecked && eyesOpenChecked
                      ? null
                      : { backgroundColor: '#2196f3', color: 'white' }
                  }
                >
                  Eyes state
                  {eyesClosedChecked && eyesOpenChecked ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <CloseIcon
                      onClick={e => {
                        e.stopPropagation();
                        resetEyesStateFilter();
                      }}
                    />
                  )}
                </div>
                <Popover
                  id="eyes-state"
                  open={filterModalState === 'eyes-state'}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setFilterModalState('');
                    setEyesClosedCheckedToSelect(eyesClosedChecked);
                    setEyesOpenCheckedToSelect(eyesOpenChecked);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{ paper: styles.popover_paper }}
                >
                  <div className={styles.popover_title}>Eyes state</div>
                  <div>
                    <Checkbox
                      onClick={() => {
                        setEyesClosedCheckedToSelect(
                          !eyesClosedCheckedToSelect
                        );
                      }}
                      checked={eyesClosedCheckedToSelect}
                      color="primary"
                    />
                    Eyes closed
                  </div>
                  <div>
                    <Checkbox
                      onClick={() => {
                        setEyesOpenCheckedToSelect(!eyesOpenCheckedToSelect);
                      }}
                      checked={eyesOpenCheckedToSelect}
                      color="primary"
                    />
                    Eyes open
                  </div>
                  <div className={styles.filter_btn_container}>
                    <Button
                      onClick={() => {
                        setEyesClosedChecked(eyesClosedCheckedToSelect);
                        setEyesOpenChecked(eyesOpenCheckedToSelect);
                        setFilterModalState('');
                      }}
                      color="primary"
                      variant="contained"
                      style={{ fontSize: 14 }}
                      disabled={
                        eyesClosedCheckedToSelect === eyesClosedChecked &&
                        eyesOpenCheckedToSelect === eyesOpenChecked
                      }
                    >
                      Apply
                    </Button>
                  </div>
                </Popover>
              </div>
              <div
                className={styles.reset_filter_container}
                onClick={() => {
                  clearChannelFilter();
                  resetEyesStateFilter();
                }}
              >
                Reset Filters
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Select
                onChange={e => {
                  if (e.target.value !== selectedStage) {
                    setSelectedStage(e.target.value);
                    const newFreqToSelect = Object.values(labels).find(freq =>
                      stagesSelector[e.target.value].channels.find(channel =>
                        freq.toLowerCase().includes(channel)
                      )
                    );
                    setSelectedChannel(newFreqToSelect);
                  }
                }}
                value={selectedStage}
                style={{
                  fontSize: 13,
                  marginRight: 10,
                  paddingLeft: 10,
                  paddingTop: 10,
                }}
              >
                {filteredStagesSelector.map(stage => {
                  return (
                    <MenuItem value={stage}>
                      {stagesSelector[stage].displayName}
                    </MenuItem>
                  );
                })}
              </Select>
              {Object.values(labels).map(freq => {
                if (
                  stagesSelector[selectedStage].channels.find(channel =>
                    freq.toLowerCase().includes(channel)
                  )
                ) {
                  return (
                    <div
                      onClick={() => {
                        setSelectedChannel(freq);
                      }}
                      className={styles.freq_button}
                      style={
                        selectedChannel === freq
                          ? { color: 'white', backgroundColor: '#2196f3' }
                          : null
                      }
                    >
                      {freq.slice(0, -2)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </Paper>

      <EegGraph
        data={showFullAssessmentToggle ? data : perChannelData}
        labels={showFullAssessmentToggle ? labels : perChannelLabels}
        channelsMargin={
          showFullAssessmentToggle ? CHANNELS_MARGIN : CHANNELS_MARGIN_FREQ
        }
        eegPointsInSeconds={
          showFullAssessmentToggle
            ? EEG_POINTS_IN_SECOND
            : EEG_POINTS_IN_SECOND_FREQ
        }
        isFullAssessment={showFullAssessmentToggle}
      />
    </div>
  );
};

EegAnalysisBase.propTypes = exact({});

export const EegAnalysis = React.memo(EegAnalysisBase);
EegAnalysis.displayName = 'EegAnalysis';
