import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ReactCodeInput from 'react-code-input';
import {
  Dialog,
  Button,
  DialogActions,
  CircularProgress,
  Stepper,
  StepLabel,
  Step,
  withStyles,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './SettingsSection.scss';
import CheckedPNG from '../../../assets/checked.png';

const StyledDialog = withStyles(() => ({
  root: {
    '& .MuiDialog-paper-5': {
      overflowY: 'unset',
    },
    '& .jss5': {
      overflowY: 'unset',
    },
  },
}))(Dialog);

const MFASteps = {
  startInformation: 'start_information',
  emailVerification: 'email_verification',
  enterPhone: 'enter_phone',
  enterPincode: 'enter_pincode',
};

export const MFAAllSteps = [
  MFASteps.startInformation,
  MFASteps.emailVerification,
  MFASteps.enterPhone,
  MFASteps.enterPincode,
];

export const formattedMFASteps = {
  start_information: 'Start Information',
  email_verification: 'Email Verification',
  enter_phone: 'Phone Setup',
  enter_pincode: 'Phone Verification',
};

const NUM_OF_FIELDS_VERFICATION_INPUT = 6;
const MIN_PHONE_NUMBER_LENGTH = 6;

const MFADialogBase = ({
  isOpen,
  onClose,
  onVerifyEmail,
  isEmailVerified,
  clinicPhoneNumber,
  setClinicPhoneNumber,
  isLodingMFADialog,
  setVerificationCode,
  verificationCode,
  isEmailSent,
  verificationId,
  submitPhoneNumber,
  isEnrolledSuccessfully,
  onVerificationCodeSent,
  isPhoneError,
  isCodeError,
  activeStep,
  onSuccessClose,
  isEditPhoneNUmberFlow,
  resendVerificationCode,
  isEmailError,
}) => {
  const inputEl = useRef(null);

  const calcContent = () => {
    if (isLodingMFADialog) {
      return <CircularProgress />;
    }
    if (!isEmailVerified) {
      return (
        <div>
          {isEmailSent
            ? 'Please check your email for verification link.'
            : 'To set up 2-factor authentication, your email needs to be verified first.'}
          {isEmailError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              Something went wrong
            </div>
          ) : null}
        </div>
      );
    }
    if (!verificationId) {
      return (
        <div>
          <div style={{ marginBottom: 20 }}>
            {isEditPhoneNUmberFlow
              ? 'Enter your new number to receive a verification code and complete the update.'
              : 'This phone will be used to receive SMS codes for verifying your identity during login.'}
          </div>
          <PhoneInput
            value={clinicPhoneNumber}
            onChange={phone => {
              setClinicPhoneNumber(phone);
            }}
            inputStyle={{ width: '100%' }}
          />
          {isPhoneError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              {isPhoneError}
            </div>
          ) : null}
        </div>
      );
    }
    if (!isEnrolledSuccessfully) {
      return (
        <div>
          <div style={{ marginBottom: 20 }}>
            Please enter the code that sent to your phone
          </div>
          <ReactCodeInput
            type="number"
            fields={NUM_OF_FIELDS_VERFICATION_INPUT}
            inputStyle={{
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              margin: 0,
              borderBottom: '0.1px solid #5e5e5e',
              borderTop: '0 solid #5e5e5e',
              borderRight: '0 solid #5e5e5e',
              borderLeft: '0 solid #5e5e5e',
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            inputStyleInvalid={{
              color: 'red',
              borderBottom: '1px solid red',
              borderTop: '0 solid #5e5e5e',
              borderRight: '0 solid #5e5e5e',
              borderLeft: '0 solid #5e5e5e',
              width: window.innerWidth < 1200 ? 45 : 65,
              height: 80,
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: window.innerWidth < 1200 ? 18 : 36,
              textTransform: 'capitalize',
              marginRight: 10,
            }}
            isValid={!isCodeError}
            value={verificationCode}
            onChange={async value => {
              setVerificationCode(value);
            }}
            ref={inputEl}
            autoFocus={false}
            className={styles.reactCodeInput}
          />
          {isCodeError ? (
            <div style={{ color: 'red', marginTop: 20, fontSize: 12 }}>
              {isCodeError}
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={CheckedPNG} style={{ width: 100, height: 100 }} />
        <div>Your account is now secured with 2-Step authentication!</div>
      </div>
    );
  };

  const calcButtonText = () => {
    if (!isEmailVerified) {
      return isEmailSent ? 'Send again' : 'Send me an Email';
    }
    if (!verificationId) {
      return 'Next';
    }
    if (!isEnrolledSuccessfully) {
      return 'Send';
    }
    return 'OK';
  };

  const calcAction = () => {
    if (!isEmailVerified) {
      return onVerifyEmail;
    }
    if (!verificationId) {
      return submitPhoneNumber;
    }
    if (!isEnrolledSuccessfully) {
      return onVerificationCodeSent;
    }
    return onSuccessClose;
  };

  return (
    <StyledDialog open={isOpen}>
      {isEnrolledSuccessfully || isEditPhoneNUmberFlow ? null : (
        <Stepper alternativeLabel>
          {MFAAllSteps.map(step => {
            return (
              <Step
                key={step}
                className={styles.step}
                active={activeStep === step}
                completed={
                  MFAAllSteps.indexOf(activeStep) > MFAAllSteps.indexOf(step)
                }
              >
                <StepLabel>
                  <span className={styles.step_label}>
                    {formattedMFASteps[step]}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <div className={styles.dialog_content}>
        <div>{calcContent()}</div>
      </div>

      <DialogActions>
        {verificationId && !isEnrolledSuccessfully ? (
          <Button
            color="primary"
            onClick={resendVerificationCode}
            disabled={isLodingMFADialog}
          >
            <span className={styles.btn}>Resend</span>
          </Button>
        ) : null}
        {isEnrolledSuccessfully ? null : (
          <Button color="primary" onClick={onClose}>
            <span className={styles.btn}>Cancel</span>
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={calcAction()}
          disabled={
            isLodingMFADialog ||
            (isEmailVerified &&
              verificationId &&
              !isEnrolledSuccessfully &&
              verificationCode.length !== NUM_OF_FIELDS_VERFICATION_INPUT) ||
            (isEmailVerified &&
              !verificationId &&
              clinicPhoneNumber.length < MIN_PHONE_NUMBER_LENGTH)
          }
          style={isEnrolledSuccessfully ? { alignSelf: 'center' } : null}
        >
          <span className={styles.btn}>{calcButtonText()}</span>
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

MFADialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerifyEmail: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  clinicPhoneNumber: PropTypes.string.isRequired,
  setClinicPhoneNumber: PropTypes.func.isRequired,
  isLodingMFADialog: PropTypes.bool.isRequired,
  setVerificationCode: PropTypes.func.isRequired,
  verificationCode: PropTypes.string.isRequired,
  isEmailSent: PropTypes.bool.isRequired,
  verificationId: PropTypes.string.isRequired,
  submitPhoneNumber: PropTypes.func.isRequired,
  isEnrolledSuccessfully: PropTypes.bool.isRequired,
  onVerificationCodeSent: PropTypes.func.isRequired,
  isPhoneError: PropTypes.string.isRequired,
  isCodeError: PropTypes.string.isRequired,
  activeStep: PropTypes.string.isRequired,
  onSuccessClose: PropTypes.func.isRequired,
  isEditPhoneNUmberFlow: PropTypes.bool.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  isEmailError: PropTypes.bool.isRequired,
});

export const MFADialog = React.memo(MFADialogBase);
MFADialog.displayName = 'MFADialog';
