import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Dialog, Button, DialogActions } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './SettingsSection.scss';

const LogoutMFADialogBase = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen}>
      <div className={styles.dialog_content}>
        <p className={styles.dialog_content_title}>Additional Login Required</p>
        <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ marginRight: 10 }} />
          For your security, you'll need to log in again to complete this
          action.
        </div>
      </div>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          <span className={styles.btn}>Cancel</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // eslint-disable-next-line no-undef
            localStorage.setItem('authFlow', true);
            // eslint-disable-next-line no-undef
            logout();
          }}
        >
          <span className={styles.btn}>Login</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LogoutMFADialogBase.propTypes = exact({
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
});

export const LogoutMFADialog = React.memo(LogoutMFADialogBase);
LogoutMFADialog.displayName = 'LogoutMFADialog';
