import React, { useEffect, useRef } from 'react';
import {
  lightningChart,
  AxisTickStrategies,
  SolidFill,
  ColorRGBA,
  Themes,
  // SolidLine,
  DashedLine,
} from '@lightningchart/lcjs';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const EegGraphBase = ({
  data,
  labels,
  channelsMargin,
  eegPointsInSeconds,
  isFullAssessment,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data || !labels) {
      return;
    }
    const lc = lightningChart({
      license:
        '0002-n71TDwSwesVZoCSPFS4k69kws9ZOKwC8UzYQqXvaTbklkM2W/O4wpJujJI9crhq9VBPSGuGAsrWc7iFSzGtwfWUq-MEQCICABNaoaQiy2VVDLMwf+Tw7Gqam0yUKoU6D6zB8UTPtzAiBEoD3ChGJkbdDYIsHiHSoxTnjQfBTrAwRFLy16CJdxdA==',
      licenseInformation: {
        appTitle: 'LightningChart JS Trial',
        company: 'LightningChart Ltd.',
      },
    });

    const chart = lc.ChartXY({
      theme: Themes.light,
      height: document.body.clientHeight - 250,
    });
    const zoomBandChart = lc.ZoomBandChart({
      useSharedValueAxis: true,
      height: 100,
    });

    chart.setPadding({ left: 100, right: 10, top: 10, bottom: 10 });
    chart.setTitle('');
    chart.setMouseInteractionWheelZoom(false);
    chart.setMouseInteractionPan(false);
    chart.setCursorMode('show-pointed');
    if (isFullAssessment) {
      // eslint-disable-next-line no-unused-vars
      chart.setCursorFormatting((_, hit, hits) => {
        return [
          [hit.series],
          [
            'Seconds:',
            '',
            (hit.axisX.formatValue(hit.x) / eegPointsInSeconds).toFixed(2),
          ],
          [
            'Amplitude:',
            '',
            // eslint-disable-next-line prefer-template
            (hit.y - hit.series.Ew * channelsMargin).toFixed(2) + 'µV',
          ],
        ];
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      chart.setCursorFormatting((_, hit, hits) => {
        return [
          [hit.series],
          [
            'Seconds:',
            '',
            (hit.axisX.formatValue(hit.x) / eegPointsInSeconds).toFixed(2),
          ],
          [
            'Relative amplitude:',
            '',
            (hit.y - hit.series.Ew * channelsMargin).toFixed(2),
          ],
        ];
      });
    }

    zoomBandChart.setPadding({ left: 10, right: 10, top: 10, bottom: 10 });
    zoomBandChart.setTitle('');
    zoomBandChart.setBackgroundFillStyle(
      new SolidFill({ color: ColorRGBA(245, 245, 245) })
    );
    zoomBandChart.setSeriesBackgroundFillStyle(
      new SolidFill({ color: ColorRGBA(255, 255, 255) })
    );

    const { axisX } = chart;
    axisX.setTitle('Seconds');
    axisX.setInterval({ start: 0, end: eegPointsInSeconds * 5 });
    axisX.setDefaultInterval({ start: 0, end: eegPointsInSeconds * 5 });
    axisX.setTickStrategy(AxisTickStrategies.Empty);
    axisX.setMouseInteractions(false);

    for (let i = 0; i <= 60; i += 1) {
      axisX
        .addCustomTick()
        .setValue(i * eegPointsInSeconds)
        .setTextFormatter(value => `${value / eegPointsInSeconds}`);
    }

    if (!isFullAssessment) {
      const axisY2 = chart.addAxisY({ opposite: true, type: 'linear' });
      axisY2.setMouseInteractions(false);
      axisY2.setTickStrategy(AxisTickStrategies.Empty);
      for (let i = 0; i < Object.keys(labels).length; i += 1) {
        axisY2
          .addCustomTick()
          .setValue((i + 1) * channelsMargin + channelsMargin / 4)
          .setTextFormatter(() => '0.25')
          .setGridStrokeStyle(
            new DashedLine({
              thickness: 0,
              fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0) }),
            })
          );
        axisY2
          .addCustomTick()
          .setValue((i + 1) * channelsMargin + (channelsMargin * 3) / 4)
          .setTextFormatter(() => '0.75')
          .setGridStrokeStyle(
            new DashedLine({
              thickness: 0,
              fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0) }),
            })
          );
      }
    }

    const { axisY } = chart;
    axisY.setTitle('Channels');
    axisY.setInterval({
      start: -channelsMargin,
      end: Object.keys(labels).length * channelsMargin,
    });
    axisY.setTickStrategy(AxisTickStrategies.Empty);
    axisY.setMouseInteractions(false);

    for (let i = 0; i < Object.keys(labels).length; i += 1) {
      axisY
        .addCustomTick()
        .setValue(i * channelsMargin)
        .setTextFormatter(value =>
          isFullAssessment
            ? `${labels[value].slice(0, -2)}`
            : `${labels[value]}`
        );
    }

    const axisYZoom = zoomBandChart.axisY;
    axisYZoom.setTickStrategy(AxisTickStrategies.Empty);

    data.forEach(item => {
      // Create a line series
      const lineSeries = chart.addLineSeries().setName(item.name);
      zoomBandChart.add(lineSeries);
      // Add some initial data
      lineSeries.add(item.dataPoints);
    });

    chartRef.current = chart;
    // eslint-disable-next-line consistent-return
    return () => {
      chart.dispose();
      zoomBandChart.dispose();
    };
  }, [data, labels]);

  return <div id="chart" ref={chartRef} />;
};

EegGraphBase.propTypes = exact({
  data: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  channelsMargin: PropTypes.number.isRequired,
  eegPointsInSeconds: PropTypes.number.isRequired,
  isFullAssessment: PropTypes.bool.isRequired,
});

export const EegGraph = React.memo(EegGraphBase);
EegGraph.displayName = 'EegGraph';
