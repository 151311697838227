import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button, Paper } from '@material-ui/core';
import { useTranslation } from '../../../../Core/hooks/useTranslation';
import noAssessmentsPNG from '../../../../../assets/client-page-no-assessment.png';
import styles from './EmptyAssessmentsTab.scss';

const EmptyAssessmentsTabBase = ({ onAddAssessment }) => {
  const t = useTranslation();

  return (
    <Paper>
      <div className={styles.root}>
        <div className={styles.no_assessments_image_container}>
          <img src={noAssessmentsPNG} alt="no-report" />
        </div>
        <div className={styles.content}>
          <h3>{t('add_assessments_for_client')}</h3>
          <p>{t('assessments_description')}</p>
          <a
            target="blank"
            href="https://intercom.help/dashboard-myndlift/en/articles/8717794-the-myndlift-assessment"
          >
            {t('learn_more_here')}
          </a>
        </div>

        <div className={styles.button_container}>
          <Button variant="contained" onClick={onAddAssessment} color="primary">
            <span className={styles.btn}>{t('add_assessment')}</span>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

EmptyAssessmentsTabBase.propTypes = exact({
  onAddAssessment: PropTypes.func.isRequired,
});

export const EmptyAssessmentsTab = React.memo(EmptyAssessmentsTabBase);
EmptyAssessmentsTab.displayName = 'EmptyAssessmentsTab';
